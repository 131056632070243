import { Component, ChangeDetectionStrategy, ViewEncapsulation, ElementRef, ViewChild, NgZone, OnInit, OnDestroy, Input } from '@angular/core'

interface Particle {
  x: number
  y: number
  radius: number
  angle: number
  color: number[]
  delay: number
  speed: number
}

@Component({
  selector: 'app-confetti',
  template: `<div class="whole-page">
  <canvas #canvas ></canvas>
  </div>
  `,
  styles: [`
  .whole-page {
  position: absolute;
  z-index: 5;
  pointer-events: none;
  left: 0;
  top: 0;
  }`],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})

export class ConfettiRain implements OnInit, OnDestroy {

  private colors = [
    [238, 96, 169],
    [68, 213, 217],
    [245, 187, 152],
    [144, 148, 188],
    [235, 234, 77]
  ]

  @ViewChild('canvas', { static: true }) canvas: ElementRef<HTMLCanvasElement>
  @Input() show: boolean

  private confettiAmount: number = 2000
  private canvasHeight: number
  private canvasWidth: number
  private ctx: CanvasRenderingContext2D
  private requestId: number
  private particles: Particle[] = []

  constructor(
    private _ngZone: NgZone
  ) { }

  ngOnInit() {
    this.canvas.nativeElement.width = document.documentElement.clientWidth
    this.canvas.nativeElement.height = document.documentElement.scrollHeight
    this.canvasWidth = this.canvas.nativeElement.offsetWidth
    this.canvasHeight = this.canvas.nativeElement.offsetHeight

    this.ctx = this.canvas.nativeElement.getContext('2d')

    if (this.show) {
      this.setParticles()
      this._ngZone.runOutsideAngular(() => this.animateConfetti())
    }

  }

  private setParticles() {
    for (let i = 0; i < this.confettiAmount; i++) {
      this.particles.push({
        x: Math.random() * this.canvasWidth, // x-coordinate
        y: 0, // y-coordinate
        radius: Math.random() * 4 + 1, // radius
        angle: Math.floor(Math.random() * 65 + -30), // line angle
        color: this.colors[Math.floor(Math.random() * this.colors.length)], // color
        delay: this.getRandomInt(0, 300), // Delay
        speed: this.getRandomInt(3, 9) // Speed
      } as Particle)
    }
  }
  private animateConfetti() {
    const remainingConfetti = this.particles.filter(prt => prt.y < this.canvasHeight).length
    if (!remainingConfetti) {
      return
    }

    this.ctx.clearRect(0, 0, this.canvasWidth, this.canvasHeight)

    for (const particle of this.particles) {
      if (particle.delay) {
        particle.delay--
      } else {
        const op = (particle.radius <= 3) ? 0.4 : 0.8

        this.ctx.strokeStyle = `rgba(${particle.color},${op})`
        this.ctx.beginPath()
        this.ctx.moveTo(particle.x, particle.y)
        particle.y = particle.y + particle.speed
        this.ctx.lineTo(particle.x + particle.angle, particle.y + (particle.radius * 5))
        this.ctx.lineWidth = 5
        this.ctx.stroke()
      }
    }
    this.requestId = requestAnimationFrame(() => this.animateConfetti())
  }
  private getRandomInt(min: number, max: number) {
    min = Math.ceil(min)
    max = Math.floor(max)
    return Math.floor(Math.random() * (max - min + 1)) + min
  }
  ngOnDestroy() {
    cancelAnimationFrame(this.requestId)
  }
}
