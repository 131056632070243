<div class="list-page kapea" style="max-width: 1200px;">
  <h2 class="header">Logit</h2>

  <app-secondary-button [actionId]="'Painettiin testinappia'">
    <div>Testinappi</div>
  </app-secondary-button>

  <form [formGroup]="hakuform" novalidate>

    <div class="ylaosan-kontrollit" style="margin: 0; justify-content: space-between; align-items: center;">
      <div class="hakukontrollit" style="align-items: baseline;">

        <!--Rajaa kirjanpitajan mukaan-->
        <mat-form-field style="width: 200px; margin-right: 20px;">
          <mat-label>Rajaa kirjanpitäjän mukaan</mat-label>
          <mat-select formControlName="kirjanpitajaUid">
            <mat-option value="all">Kaikki kirjanpitäjät</mat-option>
            <mat-option *ngFor="let kirjanpitaja of kirjanpitajienNimitiedotObservable | async" [value]="kirjanpitaja.avain">
              {{kirjanpitaja.etunimi}} {{ kirjanpitaja.sukunimi }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <!--Asiakas-->
        <mat-form-field style="width: 200px; margin-right: 20px;">
          <mat-label>Valitse asiakas</mat-label>
          <mat-select formControlName="asiakasAvain" [compareWith]="compareFn">
            <mat-option *ngIf="valittuAsiakas" [value]="valittuAsiakas.k" class="custom-option"> {{ valittuAsiakas.n }}</mat-option>
            <mat-option value="all" class="custom-option">Kaikki asiakkaat</mat-option>
            <mat-option value="search" class="custom-option">Etsi asiakasta</mat-option>
          </mat-select>
        </mat-form-field>

        <!--Tyyppi-->
        <mat-form-field style="width: 160px; margin-right: 20px;">
          <mat-label>Tyyppi</mat-label>
          <mat-select formControlName="eventType" (selectionChange)="onTypeChange($event)">
            <mat-option value="all">Kaikki tyypit</mat-option>
            <mat-option value="navigoi">Katsoi tietoja (navigoi)</mat-option>
            <mat-option value="klikkasi toimintoa">Klikkasi toimintoa</mat-option>
          </mat-select>
        </mat-form-field>

        <!--Navigointityyli-->
        <mat-form-field style="width: 160px; margin-right: 20px;" *ngIf="showNavigationStyle">
          <mat-label>Navigointityyli</mat-label>
          <mat-select formControlName="navigationMethod">
            <mat-option value="all">Kaikki tavat</mat-option>
            <mat-option value="link/url">URL/Linkki</mat-option>
            <mat-option value="back/forward">Back/forward</mat-option>
          </mat-select>
        </mat-form-field>

        <!--Tehty toiminto-->
        <mat-form-field style="width: 160px; margin-right: 20px;" *ngIf="showAction">
          <mat-label>Tehty toiminto</mat-label>
          <mat-select formControlName="eventName">
            <mat-option value="all">Kaikki toiminnot</mat-option>
          </mat-select>
        </mat-form-field>

        <!--Alkaen/Loppuen-->
        <div style="display: flex; flex-flow: row wrap;">
          <mat-form-field style="width: 150px; margin-right: 10px;">
            <mat-label>Näytä alkaen</mat-label>
            <input matInput [matDatepicker]="dp1" formControlName="start">
            <!-- <mat-hint>Alkaa</mat-hint> -->
            <mat-datepicker-toggle matSuffix [for]="dp1"></mat-datepicker-toggle>
            <mat-datepicker #dp1></mat-datepicker>
          </mat-form-field>
          <mat-form-field style="width: 150px;">
            <mat-label>... loppuen</mat-label>
            <input matInput [matDatepicker]="dp2" formControlName="end">
            <!-- <mat-hint>Loppuu</mat-hint> -->
            <mat-datepicker-toggle matSuffix [for]="dp2"></mat-datepicker-toggle>
            <mat-datepicker #dp2></mat-datepicker>
          </mat-form-field>
        </div>

        <button class="primarybutton" mat-button type="button" (click)="haeLogit()">Hae</button>

        <!--Asiakkaan haku-->
        <div class="search-container">
          <div style="max-width: 680px;" *ngIf="hakuform.get('asiakasAvain')?.value === 'search'">
            <div style="font-size: 18px; margin-bottom: 20px; line-height: 20px; margin-top: 45px; text-align: center; color: #0a2731;">Valitse asiakas</div>

            <div style="margin-left: 10px; margin-right: 10px;">
              <mat-form-field style="width: 125px; margin-right: 25px;">
                <mat-label>Etsi asiakas</mat-label>
                <input #hakuInput [name]="inputname" type="text" formControlName="haku" matInput data-lpignore="true" />
                <mat-error *ngIf="hakuControl.errors?.required && (hakuControl.dirty || hakuControl.touched)">
                  Haku puuttuu
                </mat-error>
                <mat-error *ngIf="hakuControl.errors?.minLenght && (hakuControl.dirty || hakuControl.touched)">
                  Haku on liian lyhyt
                </mat-error>
              </mat-form-field>
              <mat-form-field style="width: 125px; margin-right: 25px;">
                <mat-label>Yhtiömuoto</mat-label>
                <mat-select formControlName="yhtiomuoto">
                  <mat-option [value]="null">
                  </mat-option>
                  <mat-option *ngFor="let yhtiomuoto of yritysmuodot" [value]="yhtiomuoto.koodi">
                    {{ yhtiomuoto.nimi }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div style="display: flex; justify-content: space-evenly; margin-top: 1em; margin-bottom: 2em;">
              <button class="secondarybutton" mat-button (click)="search()" type="button">Hae asiakas</button>
            </div>
          </div>

          <!--Asiakashaun tulokset-->
          <div *ngIf="tulokset | async as asiakkaat">
            <div style="font-size: 18px; margin-bottom: 20px; line-height: 20px; margin-top: 45px; text-align: center; color: #0a2731;">Hakutulokset</div>
            <table *ngIf="asiakkaat.length else eiAsiakkaita">
              <tr>
                <th>Nimi</th>
                <th>Y-tunnus</th>
                <th>Linkki</th>
                <th>Valitse hakuun</th>
              </tr>
              <tr *ngFor="let asiakas of asiakkaat">
                <td>{{asiakas.n}}</td>
                <td>{{asiakas.y}}</td>
                <td><a [routerLink]="'/asiakkaat/' + asiakas.k + '/asiakastiedot'" [href]="'/asiakkaat/' + asiakas.k + '/asiakastiedot'" target="_blank">Asiakkaan tiedot</a></td>
                <td>
                  <button class="secondarybutton" (click)="chooseCustomerForSearch(asiakas)" [ngClass]="{ 'selected-button': hakuform.get('asiakasAvain')?.value === asiakas.k }" [value]="asiakas" mat-button type="button">Valitse asiakas</button>
                </td>
              </tr>
            </table>
            <ng-template #eiAsiakkaita>
              <div style="padding: 3em;">Antamillasi hakuehdoilla ei löytynyt yhtään asiakasta.</div>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </form>

  <div style="width:100%;">
    <div>
      <mat-table [dataSource]="loggingEntries" style="width:100%">

        <!-- Kirjanpitäjä -->
        <ng-container matColumnDef="kirjanpitajaUid">
          <mat-header-cell *matHeaderCellDef style="min-width: 200px;">Kirjanpitäjä</mat-header-cell>
          <mat-cell *matCellDef="let entry" style="min-width: 200px;"> {{ entry.kirjanpitajaNimi }}</mat-cell>
        </ng-container>

        <!-- Asiakas -->
        <ng-container matColumnDef="asiakasAvain">
          <mat-header-cell *matHeaderCellDef style="min-width: 200px;">Katsottu asiakas</mat-header-cell>
          <mat-cell *matCellDef="let entry" style="min-width: 200px;">
            <a (click)="openCustomerLink(entry.asiakasAvain, $event)">
              {{ entry.asiakasNimi }}
            </a>
          </mat-cell>
        </ng-container>

        <!-- Event Type -->
        <ng-container matColumnDef="eventType">
          <mat-header-cell *matHeaderCellDef style="min-width: 150px;">Tyyppi</mat-header-cell>
          <mat-cell *matCellDef="let entry" style="min-width: 150px;">{{ entry.eventType }}</mat-cell>
        </ng-container>

        <!-- Event Name -->
        <ng-container matColumnDef="eventName">
          <mat-header-cell *matHeaderCellDef style="min-width: 200px;">Tehty toiminto</mat-header-cell>
          <mat-cell *matCellDef="let entry" style="min-width: 200px;">{{ entry.eventName }}</mat-cell>
        </ng-container>

        <!-- Navigation Method -->
        <ng-container matColumnDef="navigationMethod">
          <mat-header-cell *matHeaderCellDef style="min-width: 120px;">Navigointityyli</mat-header-cell>
          <mat-cell *matCellDef="let entry" style="min-width: 120px;">{{ entry.navigationMethod }}</mat-cell>
        </ng-container>

        <!-- Seen -->
        <ng-container matColumnDef="seen">
          <mat-header-cell class="custom-table" *matHeaderCellDef style="min-width: 200px;">Katsottu sisältö</mat-header-cell>
          <mat-cell *matCellDef="let entry" style="min-width: 200px;">{{ entry.seen }}</mat-cell>
        </ng-container>

        <!-- URL -->
        <ng-container matColumnDef="url">
          <mat-header-cell *matHeaderCellDef style="min-width: 200px;">URL</mat-header-cell>
          <mat-cell *matCellDef="let entry" style="min-width: 200px;">
            <a (click)="openUrl(entry.url, $event)" class="blue-link">
              {{ entry.url }}
            </a>
          </mat-cell>
        </ng-container>

        <!-- Timestamp -->
        <ng-container matColumnDef="timestamp">
          <mat-header-cell *matHeaderCellDef style="min-width: 200px;">Aika</mat-header-cell>
          <mat-cell *matCellDef="let entry" style="min-width: 200px;">{{ (entry.timestamp | date:'medium') || '' }}</mat-cell>
        </ng-container>

        <!-- Log id generated by cloud logging -->
        <ng-container matColumnDef="insertId">
          <mat-header-cell *matHeaderCellDef style="min-width: 280px;">Log id</mat-header-cell>
          <mat-cell *matCellDef="let entry" style="min-width: 280px;">
            <a [href]="entry.logLink" target="_blank">{{ entry.insertId || '' }}</a>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row (click)="katsoTiedot(row)" [ngClass]="{'selected-row': selectedRow === row}" *matRowDef="let row; columns: displayedColumns;"></mat-row>
      </mat-table>
    </div>
  </div>
  <div class="red-text common-error" style="padding: 20px;" *ngIf="commonError">{{commonError}}</div>
</div>