<div class="list-page kapea" style="max-width: 1200px;">

  <h1>Myyntiraportti</h1>

  <form [formGroup]="form" novalidate class="ylaosan-kontrollit" style="margin: 16px 0;">

    <div class="hakukontrollit">
      <mat-form-field>
        <mat-label>Alku</mat-label>
        <input matInput monthPicker [matDatepicker]="dp" placeholder="Alku" formControlName="month">
        <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
        <mat-datepicker #dp startView="year" (monthSelected)="chosenMonthHandler($event, dp)" panelClass="kuukausi-valitsin"></mat-datepicker>
      </mat-form-field>
    </div>

  </form>

  <div class="lemon-error" style="margin: 0 0 15px 10px" *ngIf="commonError">{{ commonError }}</div>

  <button mat-button (click)="lataaMyyntiraportti()" type="button" class="primarybutton mat-elevation-z4">
    <mat-icon>cloud_download</mat-icon>
    Lataa myyntiraportti
  </button>

  <h1 style="margin-top: 2em;">Selvitettävät tiimeittäin raportti</h1>

  <div class="lemon-error" style="margin: 0 0 15px 10px" *ngIf="commonErrorSelvitettavat">{{ commonErrorSelvitettavat }}</div>

  <button mat-button (click)="lataaSelvitettavatRaportti()" type="button" class="primarybutton mat-elevation-z4">
    <mat-icon>cloud_download</mat-icon>
    Lataa selvitettävät raportti
  </button>

  <h1 style="margin-top: 2em;">Päättyneet- ja alkaneet sopimukset raportti</h1>

  <form [formGroup]="paattyneetSopimuksetForm" novalidate style="margin: 16px 0;">
    <div>
      <mat-form-field style="max-width: 100px; margin-right: 20px;">
        <input name="paattyneetSopimuksetAlkaa" type="text" monthPicker [matDatepicker]="paattyneetSopimuksetAlkaaPicker" formControlName="alkaa" placeholder="Alku" matInput data-lpignore="true" required />
        <mat-datepicker-toggle matSuffix [for]="paattyneetSopimuksetAlkaaPicker"></mat-datepicker-toggle>
        <mat-datepicker #paattyneetSopimuksetAlkaaPicker></mat-datepicker>
      </mat-form-field>
      <mat-form-field style="max-width: 100px;">
        <input name="paattyneetSopimuksetLoppuu" type="text" monthPicker [matDatepicker]="paattyneetSopimuksetLoppuuPicker" formControlName="loppuu" placeholder="Loppu" matInput data-lpignore="true" required />
        <mat-datepicker-toggle matSuffix [for]="paattyneetSopimuksetLoppuuPicker"></mat-datepicker-toggle>
        <mat-datepicker #paattyneetSopimuksetLoppuuPicker></mat-datepicker>
      </mat-form-field>
    </div>
  </form>

  <div class="lemon-error" style="margin: 0 0 15px 10px" *ngIf="commonErrorPaattyneetSopimukset">{{ commonErrorPaattyneetSopimukset }}</div>

  <button mat-button (click)="lataaPaattyneetSopimuksetCsv()" type="button" class="primarybutton mat-elevation-z4">
    <mat-icon>cloud_download</mat-icon>
    Lataa päättyneet sopimukset raportti
  </button>

  <button mat-button (click)="lataaAlkaneetSopimuksetCsv()" type="button" class="primarybutton mat-elevation-z4">
    <mat-icon>cloud_download</mat-icon>
    Lataa alkaneet sopimukset raportti
  </button>

  <h1 style="margin-top: 2em;">Hinnanmuutokset raportti</h1>

  <form [formGroup]="hinnanmuutoksetForm" novalidate style="margin: 16px 0;">
    <div>
      <mat-form-field style="max-width: 100px; margin-right: 20px;">
        <input name="hinnanmuutoksetAlkaa" type="text" monthPicker [matDatepicker]="hinnanmuutoksetAlkaaPicker" formControlName="alkaa" placeholder="Alku" matInput data-lpignore="true" required />
        <mat-datepicker-toggle matSuffix [for]="hinnanmuutoksetAlkaaPicker"></mat-datepicker-toggle>
        <mat-datepicker #hinnanmuutoksetAlkaaPicker></mat-datepicker>
      </mat-form-field>
      <mat-form-field style="max-width: 100px;">
        <input name="hinnanmuutoksetLoppuu" type="text" monthPicker [matDatepicker]="hinnanmuutoksetLoppuuPicker" formControlName="loppuu" placeholder="Loppu" matInput data-lpignore="true" required />
        <mat-datepicker-toggle matSuffix [for]="hinnanmuutoksetLoppuuPicker"></mat-datepicker-toggle>
        <mat-datepicker #hinnanmuutoksetLoppuuPicker></mat-datepicker>
      </mat-form-field>
    </div>
  </form>

  <div class="lemon-error" style="margin: 0 0 15px 10px" *ngIf="commonErrorHinnanmuutokset">{{ commonErrorHinnanmuutokset }}</div>

  <button mat-button (click)="lataaHinnanmuutoksetCsv()" type="button" class="primarybutton mat-elevation-z4">
    <mat-icon>cloud_download</mat-icon>
    Lataa hinnanmuutokset raportti
  </button>

  <h1 style="margin-top: 2em;">Sopimustyyppien muutokset raportti</h1>

  <form [formGroup]="sopimustyyppienMuutoksetForm" novalidate style="margin: 16px 0;">
    <div>
      <mat-form-field style="max-width: 100px; margin-right: 20px;">
        <input name="sopimustyyppienMuutoksetAlkaa" type="text" monthPicker [matDatepicker]="sopimustyyppienMuutoksetAlkaaPicker" formControlName="alkaa" placeholder="Alku" matInput data-lpignore="true" required />
        <mat-datepicker-toggle matSuffix [for]="sopimustyyppienMuutoksetAlkaaPicker"></mat-datepicker-toggle>
        <mat-datepicker #sopimustyyppienMuutoksetAlkaaPicker></mat-datepicker>
      </mat-form-field>
      <mat-form-field style="max-width: 100px;">
        <input name="sopimustyyppienMuutoksetLoppuu" type="text" monthPicker [matDatepicker]="sopimustyyppienMuutoksetLoppuuPicker" formControlName="loppuu" placeholder="Loppu" matInput data-lpignore="true" required />
        <mat-datepicker-toggle matSuffix [for]="sopimustyyppienMuutoksetLoppuuPicker"></mat-datepicker-toggle>
        <mat-datepicker #sopimustyyppienMuutoksetLoppuuPicker></mat-datepicker>
      </mat-form-field>
    </div>
  </form>

  <div class="lemon-error" style="margin: 0 0 15px 10px" *ngIf="commonErrorSopimustyyppienMuutokset">{{ commonErrorSopimustyyppienMuutokset }}</div>

  <button mat-button (click)="lataaSopimustyyppienMuutoksetCsv()" type="button" class="primarybutton mat-elevation-z4">
    <mat-icon>cloud_download</mat-icon>
    Lataa sopimustyyppien muutokset raportti
  </button>

  <h1 style="margin-top: 2em;">Pöytälaatikko- ja taukosopimusten kesto raportti</h1>

  <form [formGroup]="poytalaatikkoJaTaukosopimustenKestotForm" novalidate style="margin: 16px 0;">
    <div>
      <mat-form-field style="max-width: 150px; margin-right: 20px;">
        <input name="sopimustyyppienMuutoksetAlkaa" type="text" [matDatepicker]="poytalaatikkoJaTaukosopimustenPicker" formControlName="date" placeholder="Päivämäärä" matInput data-lpignore="true" required />
        <mat-datepicker-toggle matSuffix [for]="poytalaatikkoJaTaukosopimustenPicker"></mat-datepicker-toggle>
        <mat-datepicker #poytalaatikkoJaTaukosopimustenPicker></mat-datepicker>
      </mat-form-field>
    </div>
  </form>

  <div class="lemon-error" style="margin: 0 0 15px 10px" *ngIf="commonErrorPoytalaatikkoJaTaukosopimustenKestot">{{ commonErrorPoytalaatikkoJaTaukosopimustenKestot }}</div>

  <button mat-button (click)="lataaPoytalaatikkoJaTaukosopimustenKestotCsv()" type="button" class="primarybutton mat-elevation-z4">
    <mat-icon>cloud_download</mat-icon>
    Lataa pöytälaatikko- ja taukosopimusten kesto raportti
  </button>


  <h1 style="margin-top: 2em;">1755 Saamiset osakkailta ja omaisilta raportti</h1>

  <form [formGroup]="saamisetOsakkailtaJaOmaisiltaForm" novalidate style="margin: 16px 0;">
    <div>
      <mat-form-field style="max-width: 150px; margin-right: 20px;">
        <input name="saamisetOsakkailtaJaOmaisiltaAlkaa" type="text" [matDatepicker]="saamisetOsakkailtaJaOmaisiltaPicker" formControlName="date" placeholder="Päivämäärä" matInput data-lpignore="true" required />
        <mat-datepicker-toggle matSuffix [for]="saamisetOsakkailtaJaOmaisiltaPicker"></mat-datepicker-toggle>
        <mat-datepicker #saamisetOsakkailtaJaOmaisiltaPicker></mat-datepicker>
      </mat-form-field>
    </div>
  </form>

  <div class="lemon-error" style="margin: 0 0 15px 10px" *ngIf="commonErrorSaamisetOsakkailtaJaOmaisilta">{{ commonErrorSaamisetOsakkailtaJaOmaisilta }}</div>

  <button mat-button (click)="lataaSaamisetOsakkailtaJaOmaisiltaCsv()" type="button" class="primarybutton mat-elevation-z4">
    <mat-icon>cloud_download</mat-icon>
    Lataa 1755 Saamiset osakkailta ja omaisilta raportti
  </button>

</div>