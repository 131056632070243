import { Component, OnInit, OnDestroy, AfterContentChecked, ErrorHandler, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core'
import { Validators, AbstractControl, ValidatorFn, FormControl, FormGroup, FormArray, ValidationErrors } from '@angular/forms'

import { FirebaseLemonator } from 'app/_angular/service/firebase-lemonator.service'

import { MatInput } from '@angular/material/input'
import { MatSnackBar } from '@angular/material/snack-bar'
import { MatSelect } from '@angular/material/select'

import { TimestampProviderBase } from 'app/_shared-core/service/timestamp-provider.interface'

import { FormValidationService } from '../../_jaettu-angular/service/form-validation.service'
import { LadataanService } from '../../_jaettu-angular/service/ladataan.service'

import { DateService } from '../../_shared-core/service/date.service'

import { Asiakas, Tilikausi, AsiakkaanKirjanpitajanVoimassaolojakso, AlvIlmoitusjaksoAikajaksolla, AlvIlmoitusjakso, AsiakkaanKuukausiruutukausi, AsiakkaanKirjanpidonTyyppi, KirjanpidonTyyppi } from '../../_jaettu-lemonator/model/asiakas'
import { KirjanpitajanRooli } from '../../_jaettu/lemonator/model/kirjanpitaja'
import { Kirjanpitaja, EI_KIRJANPITAJAA_AVAIN } from '../../_jaettu-lemonator/model/kirjanpitaja'
import { AlvIlmoitus } from 'app/_jaettu-lemonator/model/kirjanpito'
import { KirjanpitoUriService } from 'app/_jaettu-lemonator/service/kirjanpito-uri.service'

import { AsiakasService, LokalisoituYritysmuoto, LokalisoituAlvIlmoitusjakso } from '../../_angular/service/asiakas/asiakas.service'
import { AsiakasKopioija } from '../../_angular/service/asiakas/asiakas.kopioija'
import { LocalDate } from 'app/_shared-core/model/common'
import { KirjautunutKayttajaService } from '../../_angular/service/kirjautunut-kayttaja.service'
import { KirjanpitajaService } from '../../_angular/service/kirjanpitaja/kirjanpitaja.service'

import { Subject, Observable, of, combineLatest, firstValueFrom, BehaviorSubject } from 'rxjs'
import { takeUntil, switchMap, map } from 'rxjs/operators'
import { AreYouSureDialog, AreYouSureDialogData } from 'app/_jaettu-angular/_components/are-you-sure.dialog'
import { AsiakkaidenTilikausienLuontityodata, KirjanpitoMerkitseTilikausiValmiiksiTyojonotiedot } from 'app/_jaettu-lemonator/model/tyojono'
import { AsiakkaanKirjanpidonPeruste, KirjanpidonPeruste, Yritysmuoto } from 'app/_jaettu/model/kayttaja'
import { OmaveroTmiPuppeteerSyncRequest, OmaveroTmiPuppeteerSyncResponse, SupportedSuomiFiAuthenticators, PuppeteerLogEntry } from 'app/_jaettu-lemonator/model/integraatiot'
import { MatDialog } from '@angular/material/dialog'
import { AsiakasJaettuService } from 'app/_jaettu-lemonator/service/asiakas-jaettu.service'
import { InfoDialog, InfoDialogData } from 'app/_jaettu-angular/_components/info.dialog'

interface OmaveroForm {
  omaveroTilikausi: FormControl<string>
}

interface OmaveroFormPuppeteer {
  omaveroTilikausi: FormControl<string>
  omaveroPankki: FormControl<SupportedSuomiFiAuthenticators>
  omaveroTunnus: FormControl<string>
}

interface VoimassaoloFormGroup {
  voimassaoloAlkaa: FormControl<Date>
  kirjanpitaja: FormControl<string>
}

interface AlvIlmoitusjaksoFormGroup {
  jakso: FormControl<AlvIlmoitusjakso>
  voimassaAlkaen: FormControl<Date>
}

interface TilikausiFormGroup {
  lukittu: FormControl<boolean>
  alkaa: FormControl<Date>
  loppuu: FormControl<Date>
}

interface KirjanpidonTyyppiFormGroup {
  tyyppi: FormControl<string>
  alkaa: FormControl<Date>
  loppuu: FormControl<Date>
}

interface KirjanpidonPerusteetFormGroup {
  peruste: FormControl<string>
  alkaa: FormControl<Date>
  loppuu: FormControl<Date>
}

interface MainForm {
  yritysmuoto: FormControl<Yritysmuoto>
  lista: FormControl<boolean>
  alvIlmoitusjaksot: FormArray<FormGroup<AlvIlmoitusjaksoFormGroup>>
  tilikaudet: FormArray<FormGroup<TilikausiFormGroup>>
  voimassaolojaksot: FormArray<FormGroup<VoimassaoloFormGroup>>
  voimassaolojaksotvara: FormArray<FormGroup<VoimassaoloFormGroup>>
  kkruutukaudet: FormArray<FormGroup<FormGroupKkruutujakso>>
  tasmaytysTilikaudet: FormArray<FormGroup<TasmaytysTilikaudetFormInterface>>
  vastaanottaja: FormControl<string>
  kirjanpidonTyypit: FormArray<FormGroup<KirjanpidonTyyppiFormGroup>>
  kirjanpidonPerusteet: FormArray<FormGroup<KirjanpidonPerusteetFormGroup>>
}

interface FormGroupKkruutujakso {
  alkaa: FormControl<Date>
  loppuu: FormControl<Date>
}

interface TasmaytysTilikaudetFormInterface {
  tasmaytysAktiivinen: FormControl<boolean>
  tilikaudenJakso: FormControl<string>
}


@Component({
  templateUrl: './kirjanpitotiedot.component.html',
  styleUrls: ['./kirjanpitotiedot.component.css'],
  // providers: [
  //   // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
  //   // application's root module. We provide it at the component level here, due to limitations of
  //   // our example generation script.
  //   {provide: DateAdapter, useClass: LemonKuukausiDateAdapter, deps: [ErrorHandler, DateService, Platform, LemonTranslationService]},
  // ]
})
export class AsiakasAsetuksetKirjanpitotiedotComponent implements OnInit, OnDestroy, AfterContentChecked {

  @ViewChildren('ibanInput', { read: MatInput }) kaikkiIbanInputit: QueryList<MatInput>
  @ViewChildren('tilikausiAlkaaInput', { read: MatInput }) tilikausiAlkaaInputit: QueryList<MatInput>
  @ViewChildren('kkruutukausiAlkaaInput', { read: MatInput }) kkruutukausiAlkaaInputit: QueryList<MatInput>
  @ViewChildren('kirjanpitajaSelect', { read: MatSelect }) kirjanpitajaSelectit: QueryList<MatSelect>
  @ViewChildren('varakirjanpitajaSelect', { read: MatSelect }) varakirjanpitajaSelectit: QueryList<MatSelect>
  @ViewChildren('alvIlmoitusjaksoSelect', { read: MatSelect }) alvIlmoitusjaksoSelectit: QueryList<MatSelect>

  private ngUnsubscribe: Subject<void> = new Subject<void>()
  viimeisinAlvIlmoitusObservable: Observable<AlvIlmoitus | null>

  form: FormGroup<MainForm>
  omaveroForm: FormGroup<OmaveroForm>
  omaveroFormPuppeteer: FormGroup<OmaveroFormPuppeteer>
  paivitaValidaatiot: any = null
  minMonth = new Date()
  asiakas: Asiakas = null
  private _originalAsiakas: Asiakas = null
  naytaSuper = false
  namename = 'asdf ' + Math.random()
  onkoDevaajaObservable: Observable<boolean>

  eiKirjanpitajaaAvain = EI_KIRJANPITAJAA_AVAIN

  yritysmuodot: LokalisoituYritysmuoto[] = null
  alvIlmoitusjaksot: LokalisoituAlvIlmoitusjakso[] = null

  omaverotasmaytysError: string = null
  omaverotasmaytysErrorPuppeteer: string = null
  kkruutuError: string = null

  naytaOmaveroTasmaytysObservable: Observable<boolean>
  naytaPuppeteerOmaveroTasmaytysObservable: Observable<boolean>
  kirjanpitajatObservable: Observable<Kirjanpitaja[]>
  omaveroTilikaudetObservable: Observable<Tilikausi[]>
  omaveroPankitObservable: Observable<{ nimi: string, tunnus: SupportedSuomiFiAuthenticators }[]> = new BehaviorSubject([
    { nimi: 'OP', tunnus: 'osuuspankki' },
    { nimi: 'S-Pankki', tunnus: 's-pankki' },
    { nimi: 'Säästöpankki', tunnus: 'saastopankki' }
  ])
  omaveroTasmaytysLogRivitObservable: Observable<string[]>


  kaikkiKirjanpidonTyypit: { nimi: string, tunnus: KirjanpidonTyyppi }[] = [
    { nimi: 'Yhdenkertainen', tunnus: KirjanpidonTyyppi.YHDENKERTAINEN },
    { nimi: 'Kahdenkertainen', tunnus: KirjanpidonTyyppi.KAHDENKERTAINEN }
  ]
  kaikkiKirjanpidonPerusteet: { nimi: string, tunnus: KirjanpidonPeruste }[] = [
    { nimi: 'Laskuperusteinen', tunnus: KirjanpidonPeruste.LASKU },
    { nimi: 'Maksuperusteinen', tunnus: KirjanpidonPeruste.MAKSU }
  ]

  // minimiMuutokseDate: Date = new Date(1979, 11, 31)
  minimumAllowedDate: Date

  punakynaCanBeUsedObservable: Observable<boolean>
  punakynaActiveSubject: BehaviorSubject<boolean> = new BehaviorSubject(false)
  toggleMuokkaaMitaVain() {
    if (this.punakynaActiveSubject.value) {
      this.punakynaActiveSubject.next(false)
    } else {
      this.punakynaActiveSubject.next(true)
    }
  }

  validateEmail: ValidatorFn = (ctrl: FormControl<string>): ValidationErrors | null => {
    if (ctrl.value == null || ctrl.value === undefined || ctrl.value.trim() === '') {
      return null
    }
    return Validators.email(ctrl)
  }
  vastaanottajaFormControl = new FormControl<string>('', [this.validateEmail])

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    // private route: ActivatedRoute,
    // private router: Router,
    private _dialog: MatDialog,
    private _asiakasService: AsiakasService,
    private _kirjautunutKayttajaService: KirjautunutKayttajaService,
    private _dateService: DateService,
    private _asiakasKopioija: AsiakasKopioija,
    private _validationService: FormValidationService,
    private _errorHandler: ErrorHandler,
    private _ladataanService: LadataanService,
    private _snackbar: MatSnackBar,
    private _kirjanpitajatService: KirjanpitajaService,
    private _timestampService: TimestampProviderBase,
    private _kirjanpitoUriService: KirjanpitoUriService,
    private _firebase: FirebaseLemonator,
    private _formValidationService: FormValidationService,
    private _asiakasJaettuService: AsiakasJaettuService
  ) {

    this.omaveroTasmaytysLogRivitObservable = this._firebase.databaseListenValueList<PuppeteerLogEntry>('integraatiot/logs/omavero_tmi_tasmaytys/viimeisin/logs').pipe(
      map(entries => {
        return entries?.map(a => { return this._dateService.muotoilePaivaJaAikaDate(new Date(a.time), 'fi') + '\t' + a.entry + '\n' }) ?? []
      })
    )

    this.punakynaCanBeUsedObservable = this._kirjautunutKayttajaService.kirjanpitajanAnnetaanMuokataMitaTahansaKirjauksiaObservable

    this.yritysmuodot = this._asiakasService.yritysmuodot
    this.alvIlmoitusjaksot = this._asiakasService.alvIlmoitusjaksot

    this.asiakas = this._asiakasKopioija.annaUusiAsiakas()
    this._originalAsiakas = this._asiakasKopioija.annaUusiAsiakas()

    this.kirjanpitajatObservable = this._kirjanpitajatService.kirjanpitajatObservable.pipe(
      map(kirjanpitajat => {
        if (kirjanpitajat?.length) {
          return kirjanpitajat.sort((a, b) => {
            if (a.etunimi !== b.etunimi) {
              return a.etunimi.localeCompare(b.etunimi)
            }
            return a.sukunimi.localeCompare(b.sukunimi)
          })
        }
        return []
      })
    )
  }

  get yritysmuoto() {
    return this.get('yritysmuoto')
  }

  get tasmaytysTilikaudetFormArray(): FormArray<FormGroup<TasmaytysTilikaudetFormInterface>> {
    return this.form.get('tasmaytysTilikaudet') as FormArray<FormGroup<TasmaytysTilikaudetFormInterface>>
  }

  get tasmaytysTilikaudetFormGroupit(): FormGroup<TasmaytysTilikaudetFormInterface>[] {
    return this.tasmaytysTilikaudetFormArray.controls as FormGroup<TasmaytysTilikaudetFormInterface>[]
  }

  private get(name: string): AbstractControl {
    if (this.form) {
      return this.form.get(name)
    }
    return null
  }

  ngAfterContentChecked() {
    // this.fieldErrors = this.validationService.updateValidationStatus('asiakas', this.form)
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next()
    this.ngUnsubscribe.complete()
  }

  ngOnInit() {

    const d = new Date()
    d.setDate(1)
    this.minMonth = this._dateService.lisaaKuukausia(d, 1)

    this.form = new FormGroup<MainForm>({
      yritysmuoto: new FormControl<Yritysmuoto>(null, [Validators.required]),
      lista: new FormControl<boolean>(null, []),
      alvIlmoitusjaksot: new FormArray<FormGroup<AlvIlmoitusjaksoFormGroup>>([], []),
      tilikaudet: new FormArray<FormGroup<TilikausiFormGroup>>([], []),
      voimassaolojaksot: new FormArray<FormGroup<VoimassaoloFormGroup>>([], []),
      voimassaolojaksotvara: new FormArray<FormGroup<VoimassaoloFormGroup>>([], []),
      kkruutukaudet: new FormArray<FormGroup<FormGroupKkruutujakso>>([], []),
      tasmaytysTilikaudet: new FormArray<FormGroup<TasmaytysTilikaudetFormInterface>>([]),
      vastaanottaja: this.vastaanottajaFormControl,
      kirjanpidonTyypit: new FormArray<FormGroup<KirjanpidonTyyppiFormGroup>>([], []),
      kirjanpidonPerusteet: new FormArray<FormGroup<KirjanpidonPerusteetFormGroup>>([], [this._katkeamatonAikajanaValidator]),
    })

    this.omaveroForm = new FormGroup<OmaveroForm>({
      omaveroTilikausi: new FormControl<string>(null, [Validators.required])
    })

    this.omaveroFormPuppeteer = new FormGroup<OmaveroFormPuppeteer>({
      omaveroTilikausi: new FormControl<string>(null, [Validators.required]),
      omaveroPankki: new FormControl<SupportedSuomiFiAuthenticators>(null, [Validators.required]),
      omaveroTunnus: new FormControl<string>(null, [Validators.required])
    })

    this.form.get('yritysmuoto').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(value => { this.asiakas.yritysmuoto = value })

    this.form.get('lista').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(value => {
      this.asiakas.uudessaListassa = !!value
    })

    this.viimeisinAlvIlmoitusObservable = this._asiakasService.nykyinenAsiakasAvainObservable.pipe(
      switchMap(asiakas => {
        if (asiakas) {
          const alvilmoituksetUri = this._kirjanpitoUriService.annaAlvIlmoituksenCollectionUri(asiakas.avain)
          return this._firebase.firestoreCollection<AlvIlmoitus>(alvilmoituksetUri).orderBy('tunniste', 'desc').limit(1).listen()
        }
        return of<AlvIlmoitus[]>([])
      }),
      map(edelliset => {
        if (edelliset && edelliset.length > 0) {
          return edelliset[0]
        }
        return null
      })
    )

    this.omaveroTilikaudetObservable = this._asiakasService.nykyinenAsiakasObservable.pipe(
      map(asiakas => asiakas?.tilikaudet?.filter(tilikausi => !tilikausi.lukittu && tilikausi.avain) ?? [])
    )

    this.naytaOmaveroTasmaytysObservable = this._asiakasService.nykyinenAsiakasObservable.pipe(
      map(asiakas => {
        return asiakas && (asiakas.yritysmuoto === Yritysmuoto.OSAKEYHTIO || asiakas.yritysmuoto === Yritysmuoto.OSUUSKUNTA || asiakas.yritysmuoto === Yritysmuoto.TOIMINIMI)
      })
    )

    this.naytaPuppeteerOmaveroTasmaytysObservable = combineLatest([this._asiakasService.nykyinenAsiakasObservable, this._kirjautunutKayttajaService.kirjanpitajanAnnetaanMuokataMitaTahansaKirjauksiaObservable]).pipe(
      map(([asiakas, voiMuokataMitaTahansa]) => {
        return asiakas && (asiakas.yritysmuoto === Yritysmuoto.TOIMINIMI) && voiMuokataMitaTahansa
      })
    )

    combineLatest([this._asiakasService.nykyinenAsiakasObservable, this.viimeisinAlvIlmoitusObservable, this.punakynaActiveSubject]).pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(([a, viimeisinAlvIlmoitus, punakynaActivated]) => {
      if (a) {
        this._originalAsiakas = a
        this.asiakas = this._asiakasKopioija.kopioiAsiakas(a)
        this._alustaLomakkeenTiedot(this.asiakas, viimeisinAlvIlmoitus, punakynaActivated)
      }
    })

    this._kirjautunutKayttajaService.kirjanpitajanTiedotObservable.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(kirjanpitajanTiedot => {
      if (kirjanpitajanTiedot) {
        this.naytaSuper = kirjanpitajanTiedot.rooli === KirjanpitajanRooli.SUPER
      } else {
        this.naytaSuper = false
      }
    })

    this.onkoDevaajaObservable = this._kirjautunutKayttajaService.kirjanpitajaOnLuottoObservable

  }

  private _getkirjanpitajanEdellinenKuukausiValidator(vara: 'varakirjanpitajalle' | 'kirjanpitajalle'): ValidatorFn {
    return (control: FormControl<Date>) => {
      // console.log('PVM', control.value)
      if (control.value) {
        const kaikki = vara === 'varakirjanpitajalle' ? this.annaKirjanpitajienVoimassaolojaksotvaraFormArray() : this.annaKirjanpitajienVoimassaolojaksotFormArray()
        const index = kaikki.controls.indexOf(control.parent as FormGroup<VoimassaoloFormGroup>)
        if (index > 0) {
          // console.log('GOT index', index)
          const edellinen = kaikki.controls[index - 1]
          if (edellinen) {
            const edellinenControl = edellinen.get('voimassaoloAlkaa')
            if (edellinenControl && edellinenControl.value) {
              const dateToCheck = this._dateService.dateToLocalDate(control.value)
              const previousDate = this._dateService.dateToLocalDate(edellinenControl.value)
              // console.log('Validoidaan nykyinen: ', dateToCheck, 'Validoidaan edellinen:', previousDate, this._dateService.compareLocalDates(dateToCheck, '<=', previousDate))
              if (this._dateService.compareLocalDates(dateToCheck, '<=', previousDate)) {
                return { edellinen: true }
              }
            }
          }
        }
      }
      return null
    }
  }

  annaKirjanpitajienVoimassaolojaksovaraControllit(): FormGroup<VoimassaoloFormGroup>[] {
    return this.annaKirjanpitajienVoimassaolojaksotvaraFormArray().controls
  }

  annaKirjanpitajienVoimassaolojaksotvaraFormArray(): FormArray<FormGroup<VoimassaoloFormGroup>> {
    return this.get('voimassaolojaksotvara') as FormArray<FormGroup<VoimassaoloFormGroup>>
  }

  annaKkruutujenKausiControllit(): FormGroup<FormGroupKkruutujakso>[] {
    return this.annaKkruutujenKaudetFormArray().controls
  }

  annaKkruutujenKaudetFormArray(): FormArray<FormGroup<FormGroupKkruutujakso>> {
    return this.get('kkruutukaudet') as FormArray<FormGroup<FormGroupKkruutujakso>>
  }

  annaKirjanpitajienVoimassaolojaksoControllit(): FormGroup<VoimassaoloFormGroup>[] {
    return this.annaKirjanpitajienVoimassaolojaksotFormArray().controls
  }

  annaKirjanpitajienVoimassaolojaksotFormArray(): FormArray<FormGroup<VoimassaoloFormGroup>> {
    return this.get('voimassaolojaksot') as FormArray<FormGroup<VoimassaoloFormGroup>>
  }

  annaTilikausiControllit(): FormGroup<TilikausiFormGroup>[] {
    return this.annaTilikaudetFormArray().controls as FormGroup<TilikausiFormGroup>[]
  }

  annaTilikaudetFormArray(): FormArray<FormGroup<TilikausiFormGroup>> {
    return this.get('tilikaudet') as FormArray<FormGroup<TilikausiFormGroup>>
  }

  annaAlvIlmoitusjaksoControllit(): FormGroup<AlvIlmoitusjaksoFormGroup>[] {
    return this.annaAlvIlmoitusjaksotFormArray().controls as FormGroup<AlvIlmoitusjaksoFormGroup>[]
  }

  annaAlvIlmoitusjaksotFormArray(): FormArray<FormGroup<AlvIlmoitusjaksoFormGroup>> {
    return this.get('alvIlmoitusjaksot') as FormArray<FormGroup<AlvIlmoitusjaksoFormGroup>>
  }

  annaKirjanpidonPerusteetControllit(): FormGroup<KirjanpidonPerusteetFormGroup>[] {
    return this.annaKirjanpidonPerusteetFormArray().controls as FormGroup<KirjanpidonPerusteetFormGroup>[]
  }

  annaKirjanpidonPerusteetFormArray(): FormArray<FormGroup<KirjanpidonPerusteetFormGroup>> {
    return this.get('kirjanpidonPerusteet') as FormArray<FormGroup<KirjanpidonPerusteetFormGroup>>
  }

  annaKirjanpidonTyypitControllit(): FormGroup<KirjanpidonTyyppiFormGroup>[] {
    return this.annaKirjanpidonTyypitFormArray().controls as FormGroup<KirjanpidonTyyppiFormGroup>[]
  }

  annaKirjanpidonTyypitFormArray(): FormArray<FormGroup<KirjanpidonTyyppiFormGroup>> {
    return this.get('kirjanpidonTyypit') as FormArray<FormGroup<KirjanpidonTyyppiFormGroup>>
  }


  private _annaAikaisinMahdollinenPaivamaara(viimeisinAlvIlmoitus: AlvIlmoitus): Date {
    if (viimeisinAlvIlmoitus) {
      const asDate = this._dateService.localDateToDate({ year: viimeisinAlvIlmoitus.endDate.year, month: viimeisinAlvIlmoitus.endDate.month, day: 1 })
      return this._dateService.lisaaKuukausia(asDate, 1)
    }
    return new Date(1979, 11, 31)
  }

  private _alustaLomakkeenTiedot(asiakas: Asiakas, viimeisinAlvIlmoitus: AlvIlmoitus, punakynaActivated: boolean) {

    this.form.get('yritysmuoto').setValue(asiakas ? asiakas.yritysmuoto : null)

    const localMonth = this._dateService.numberToLocalMonth(asiakas.uudessaKirjanpitoAppissaAlkaen)
    const asDate = localMonth ? this._dateService.localDateToDate({ year: localMonth.year, month: localMonth.month, day: 15 }) : null
    // this.form.get('uudessaListassaAlkaen').setValue(asDate)
    this.form.get('lista').setValue(asiakas.uudessaListassa)

    // this.form.get('alvIlmoitusjakso').setValue(asiakas ? asiakas.alvIlmoitusjakso : null)

    const alvIlmoitusjaksotArray = this.annaAlvIlmoitusjaksotFormArray()
    alvIlmoitusjaksotArray.clear()
    if (asiakas && asiakas.alvIlmoitusjaksot) {
      const aikaisinMahdollinenPaivamaara = this._annaAikaisinMahdollinenPaivamaara(viimeisinAlvIlmoitus)
      let index = 0
      for (const alvIlmoitusjakso of asiakas.alvIlmoitusjaksot) {
        const alvIlmoitutGroup = this.annaFormGroupAlvIlmoitusjaksolle(alvIlmoitusjakso, index, asiakas.alvIlmoitusjaksot, viimeisinAlvIlmoitus, false, true, aikaisinMahdollinenPaivamaara, punakynaActivated)
        alvIlmoitusjaksotArray.push(alvIlmoitutGroup)
        index++
      }
    }

    const tilikaudetArray = this.annaTilikaudetFormArray()
    tilikaudetArray.clear()
    if (asiakas && asiakas.tilikaudet) {
      for (const tilikausi of asiakas.tilikaudet) {
        const tilikausiGroup = this.annaFormGroupTilikaudelle(tilikausi, false, true)
        tilikaudetArray.push(tilikausiGroup)
      }
    }

    const kirjanpitajienVoimassaoloArray = this.annaKirjanpitajienVoimassaolojaksotFormArray()
    kirjanpitajienVoimassaoloArray.clear()
    if (asiakas && asiakas.kirjanpitajat) {
      for (const jakso of asiakas.kirjanpitajat) {
        const jaksoGroup = this.annaFormGroupKirjanpitajanVoimassaolojaksolle(jakso, false, true, 'kirjanpitajalle')
        kirjanpitajienVoimassaoloArray.push(jaksoGroup)
      }
    }

    const kirjanpitajienVoimassaolovaraArray = this.annaKirjanpitajienVoimassaolojaksotvaraFormArray()
    kirjanpitajienVoimassaolovaraArray.clear()
    if (asiakas && asiakas.kirjanpitajatvara) {
      for (const jakso of asiakas.kirjanpitajatvara) {
        const jaksoGroup = this.annaFormGroupKirjanpitajanVoimassaolojaksolle(jakso, false, true, 'varakirjanpitajalle')
        kirjanpitajienVoimassaolovaraArray.push(jaksoGroup)
      }
    }

    const kkruutujenKaudetFormArray = this.annaKkruutujenKaudetFormArray()
    kkruutujenKaudetFormArray.clear()
    if (asiakas && asiakas.kkruutujenKaudet) {
      for (const kausi of asiakas.kkruutujenKaudet) {
        const kausiGroup = this._annaFormGroupKkRuudunKaudelle(kausi, false, true)
        kkruutujenKaudetFormArray.push(kausiGroup)
      }
    }

    kkruutujenKaudetFormArray.clear()
    if (asiakas && asiakas.kkruutujenKaudet) {
      for (const kausi of asiakas.kkruutujenKaudet) {
        const kausiGroup = this._annaFormGroupKkRuudunKaudelle(kausi, false, true)
        kkruutujenKaudetFormArray.push(kausiGroup)
      }
    }

    this.tasmaytysTilikaudetFormArray.clear()
    for (const tilikausi of (asiakas?.tilikaudet ?? [])) {
      const tilikausiGroup = this._annaFormGroupTasmaytysTilikausille(tilikausi)
      if (tilikausi.lukittu || tilikausi.automationLocked) { tilikausiGroup.disable() }
      this.tasmaytysTilikaudetFormArray.push(tilikausiGroup)
    }

    const kirjanpidonTyypitArray = this.annaKirjanpidonTyypitFormArray()
    kirjanpidonTyypitArray.clear()
    if (asiakas?.kirjanpidonTyypit) {
      for (const tyyppi of asiakas.kirjanpidonTyypit) {
        const tyyppiGroup = this._annaFormGroupKirjanpidonTyypille(tyyppi)
        kirjanpidonTyypitArray.push(tyyppiGroup)
      }
    }

    const kirjanpidonPerusteetArray = this.annaKirjanpidonPerusteetFormArray()
    kirjanpidonPerusteetArray.clear()
    if (asiakas?.kirjanpidonPerusteet) {
      let index = 0
      for (const peruste of asiakas.kirjanpidonPerusteet) {
        const perusteGroup = this._annaFormGroupKirjanpidonPerusteelle(peruste, index < 1)
        kirjanpidonPerusteetArray.push(perusteGroup)
        index++
      }
    }
  }

  private _getMinimumMonthValidator(formGroup: FormGroup<AlvIlmoitusjaksoFormGroup>, aikaisinMahdollinenPaivamaara: Date): ValidatorFn {
    return (control: FormControl<Date>) => {
      if (control.value) {
        const minDate = this._annaMinimiMuutokseDate(formGroup, aikaisinMahdollinenPaivamaara)
        if (this._dateService.kuukausiaValissa(control.value, minDate) < 0) {
          this.minimumAllowedDate = minDate
          return { min: true }
        }
      }
      return null
    }
  }

  private annaUusiKirjanpitajanVoimassaolojakso(): AsiakkaanKirjanpitajanVoimassaolojakso {
    return {
      kirjanpitajanAvain: null,
      voimassaoloAlkaa: null
    }
  }

  poistaKirjanpitajanVoimassaolojakso(group: FormGroup<VoimassaoloFormGroup>) {
    const formArray = this.annaKirjanpitajienVoimassaolojaksotFormArray()
    const index = formArray.controls.indexOf(group)
    if (index > -1) {
      formArray.removeAt(index)
      this.asiakas.kirjanpitajat.splice(index, 1)
    }
  }


  viimeisinPuppeteerLogAvain: string = null
  private kaynnistaOmaverotasmaytysKaynnissaPuppeteer = false
  async kaynnistaOmaverotasmaytysPuppeteer() {

    try {

      if (this.asiakas.suomiFiValtuutusPuuttuu) {
        this.omaverotasmaytysErrorPuppeteer = 'Asiakkaan Suomi.fi -valtuutus puuttuu. Täsmäytystä ei voi suorittaa.'
        return
      }
      this.omaverotasmaytysErrorPuppeteer = null

      if (
        !this.omaveroFormPuppeteer.get('omaveroTilikausi').value ||
        !this.omaveroFormPuppeteer.get('omaveroTunnus').value ||
        !this.omaveroFormPuppeteer.get('omaveroPankki').value
      ) {
        this._validationService.merkitseKokoLomakeKosketuksi(this.omaveroFormPuppeteer)
        return
      }

      if (this.kaynnistaOmaverotasmaytysKaynnissaPuppeteer) {
        return
      }

      this.kaynnistaOmaverotasmaytysKaynnissaPuppeteer = false
      this.viimeisinPuppeteerLogAvain = this._firebase.databaseCreatePushId()

      this._ladataanService.aloitaLataaminen()

      const result = await this._firebase.functionsCall<OmaveroTmiPuppeteerSyncRequest, OmaveroTmiPuppeteerSyncResponse>('integraatiotOmaveroTasmaytysKaynnistaYksittainenTilikausiPup', {
        asiakasAvain: this.asiakas.avain,
        tilikausiAvain: this.omaveroFormPuppeteer.get('omaveroTilikausi').value,
        authenticator: this.omaveroFormPuppeteer.get('omaveroPankki').value,
        username: this.omaveroFormPuppeteer.get('omaveroTunnus').value,
        logAvain: this.viimeisinPuppeteerLogAvain
      })

      if (result.e) {
        this.omaverotasmaytysErrorPuppeteer = result.e
      } else {
        this._snackbar.open('Omaverotäsmäytys tilikaudelle aloitettiin. Tavallisesti se kestää n. 5 minuuttia.', null, { duration: 5000 })
      }

    } catch (err) {
      this._errorHandler.handleError(err)
    } finally {
      this.kaynnistaOmaverotasmaytysKaynnissaPuppeteer = false
      this._ladataanService.lopetaLataaminen()
    }

  }

  private kaynnistaOmaverotasmaytysKaynnissa = false
  async kaynnistaOmaverotasmaytys() {

    try {

      if (this.asiakas.suomiFiValtuutusPuuttuu) {
        this.omaverotasmaytysError = 'Asiakkaan Suomi.fi -valtuutus puuttuu. Täsmäytystä ei voi suorittaa.'
        return
      }
      this.omaverotasmaytysError = null

      if (!this.omaveroForm.get('omaveroTilikausi').value) {
        this._validationService.merkitseKokoLomakeKosketuksi(this.omaveroForm)
        return
      }

      if (this.kaynnistaOmaverotasmaytysKaynnissa) {
        return
      }

      this.kaynnistaOmaverotasmaytysKaynnissa = false

      this._ladataanService.aloitaLataaminen()

      const promise = this._firebase.functionsCall<{ asiakasAvain: string, tilikausiAvain: string }, void>('integraatiotOmaveroTasmaytysKaynnistaYksittainenTilikausi', {
        asiakasAvain: this.asiakas.avain,
        tilikausiAvain: this.omaveroForm.get('omaveroTilikausi').value
      })

      await promise

      this._snackbar.open('Omaverotäsmäytys tilikaudelle aloitettiin. Tavallisesti se kestää n. 5 minuuttia.', null, { duration: 5000 })

    } catch (err) {
      this._errorHandler.handleError(err)
    } finally {
      this.kaynnistaOmaverotasmaytysKaynnissa = false
      this._ladataanService.lopetaLataaminen()
    }

  }

  lisaaKirjanpitajanVoimassaolojakso() {
    const formArray = this.annaKirjanpitajienVoimassaolojaksotFormArray()

    const jakso = this.annaUusiKirjanpitajanVoimassaolojakso()
    const formGroup = this.annaFormGroupKirjanpitajanVoimassaolojaksolle(jakso, true, false, 'kirjanpitajalle')

    if (!this.asiakas.kirjanpitajat) {
      this.asiakas.kirjanpitajat = []
    }
    this.asiakas.kirjanpitajat.push(jakso)
    formArray.push(formGroup)
  }

  poistaKirjanpitajanVoimassaolojaksovara(group: FormGroup<VoimassaoloFormGroup>) {
    const formArray = this.annaKirjanpitajienVoimassaolojaksotvaraFormArray()
    const index = formArray.controls.indexOf(group)
    if (index > -1) {
      formArray.removeAt(index)
      this.asiakas.kirjanpitajatvara.splice(index, 1)
    }
  }

  lisaaKirjanpitajanVoimassaolojaksovara() {
    const formArray = this.annaKirjanpitajienVoimassaolojaksotvaraFormArray()

    const jakso = this.annaUusiKirjanpitajanVoimassaolojakso()
    const formGroup = this.annaFormGroupKirjanpitajanVoimassaolojaksolle(jakso, true, false, 'varakirjanpitajalle')

    if (!this.asiakas.kirjanpitajatvara) {
      this.asiakas.kirjanpitajatvara = []
    }
    this.asiakas.kirjanpitajatvara.push(jakso)
    formArray.push(formGroup)
  }

  private annaFormGroupKirjanpitajanVoimassaolojaksolle(jakso: AsiakkaanKirjanpitajanVoimassaolojakso, focus: boolean, lukitseVanhat: boolean, vara: 'varakirjanpitajalle' | 'kirjanpitajalle'): FormGroup<VoimassaoloFormGroup> {

    // const d = new Date()
    // d.setDate(2)
    // console.log('PÄIVÄ', d.toISOString(), this._dateService.lisaaKuukausia(d, 1).toISOString())

    const paiva = this._dateService.localDateToDate(jakso.voimassaoloAlkaa)
    // const lukossa = false
    const lukossa = lukitseVanhat && this._dateService.compareLocalDates(jakso.voimassaoloAlkaa, '<', this._dateService.currentLocalDate())
    const jaksoGroup = new FormGroup<VoimassaoloFormGroup>({
      'voimassaoloAlkaa': new FormControl<Date>({ value: paiva, disabled: lukossa }, [Validators.required, this._getkirjanpitajanEdellinenKuukausiValidator(vara)]),
      'kirjanpitaja': new FormControl<string>({ value: jakso.kirjanpitajanAvain, disabled: lukossa }, [Validators.required])
    })

    jaksoGroup.get('voimassaoloAlkaa').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe((value: Date) => {
      jakso.voimassaoloAlkaa = this._dateService.dateToLocalDate(value)
    })

    jaksoGroup.get('kirjanpitaja').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(value => {
      jakso.kirjanpitajanAvain = value
    })

    if (focus) {
      setTimeout(() => {
        if (vara === 'varakirjanpitajalle') {
          if (this.varakirjanpitajaSelectit.last) {
            this.varakirjanpitajaSelectit.last.focus()
            this._changeDetectorRef.markForCheck()
          }
        } else {
          if (this.kirjanpitajaSelectit.last) {
            this.kirjanpitajaSelectit.last.focus()
            this._changeDetectorRef.markForCheck()
          }
        }
      }, 10)
    }

    return jaksoGroup

  }


  private _annaKkruutujakso(previous?: AsiakkaanKuukausiruutukausi): AsiakkaanKuukausiruutukausi {
    return {
      avain: this._firebase.firestoreCreateId(),
      luoja: 'lemonator_system',
      luotu: this._timestampService.now(),
      paivittaja: 'lemonator_system',
      poistettu: false,
      paivitetty: this._timestampService.now(),
      alkaa: previous?.loppuu ? this._dateService.lisaaPaiviaPaikallinen(previous.loppuu, 1) : null,
      loppuu: null
    }
  }

  lisaaKkruutujakso() {

    if (this.asiakas.kkruutujenKaudet?.find(kkr => !kkr.loppuu)) {
      this.kkruutuError = 'Päätä edellinen jakso ennen uuden lisäämistä'
      return
    }

    this.kkruutuError = null

    const formArray = this.annaKkruutujenKaudetFormArray()

    const edellinenKausi = this.asiakas.kkruutujenKaudet?.sort((a, b) => this._dateService.localDateToNumber(b.alkaa) - this._dateService.localDateToNumber(a.alkaa))?.[0]
    const kausi = this._annaKkruutujakso(edellinenKausi)
    const formGroup = this._annaFormGroupKkRuudunKaudelle(kausi, true, false)

    if (!this.asiakas.kkruutujenKaudet) {
      this.asiakas.kkruutujenKaudet = []
    }
    this.asiakas.kkruutujenKaudet.push(kausi)
    formArray.push(formGroup)
  }

  private _kkRuutuAlkaaEnnenLoppuaValidator(kausiGroup: FormGroup<FormGroupKkruutujakso>): (control: FormControl<Date>) => ValidationErrors {
    return (control: FormControl<Date>): ValidationErrors | null => {
      const l = kausiGroup.get('loppuu')?.value
      const a = kausiGroup.get('alkaa')?.value
      if (l && a && a >= l) {
        return { 'loppuEnnenAlkua': true }
      }
      return null
    }
  }

  private _kkRuutuAlkaaEdellisenLoppumisenJalkeenValidator(kausiGroup: FormGroup<FormGroupKkruutujakso>): (control: FormControl<Date>) => ValidationErrors {
    return (control: FormControl<Date>): ValidationErrors | null => {
      const ctrls = this.annaKkruutujenKausiControllit()
      const alkaaFg = kausiGroup.get('alkaa')
      const nykyinenIndex = ctrls.findIndex(fg => fg.get('alkaa') === alkaaFg)
      if (nykyinenIndex > 0) {
        const edellinenGroup = ctrls[nykyinenIndex - 1]
        const l = edellinenGroup.get('loppuu').value
        const a = alkaaFg.value
        if (l && a && a <= l) {
          return { 'alkuEnnenEdellisenLoppua': true }
        }
      }
      return null
    }
  }

  private _kkRuutuEiLopuVaikkaSeuraavaValidator(kausiGroup: FormGroup<FormGroupKkruutujakso>): (control: FormControl<Date>) => ValidationErrors {
    return (control: FormControl<Date>): ValidationErrors | null => {
      const ctrls = this.annaKkruutujenKausiControllit()
      const loppuuFg = kausiGroup.get('loppuu')
      const nykyinenIndex = ctrls.findIndex(fg => fg.get('loppuu') === loppuuFg)
      if (nykyinenIndex + 1 < ctrls.length && !loppuuFg.value) {
        return { 'loppuuPuuttuu': true }
      }
      return null
    }
  }

  private _annaFormGroupKkRuudunKaudelle(kausi: AsiakkaanKuukausiruutukausi, focus: boolean, lukitseVanhat: boolean): FormGroup<FormGroupKkruutujakso> {

    const lukossa = false // lukitseVanhat // && this._dateService.compareLocalDates(kausi.voimassaoloAlkaa, '<', this._dateService.currentLocalDate())

    const alkaa = this._dateService.localDateToDate(kausi.alkaa)
    const alkaaFormControl = new FormControl<Date>({ value: alkaa, disabled: lukossa }, [])
    const loppuu = this._dateService.localDateToDate(kausi.loppuu)
    const loppuuFormControl = new FormControl<Date>({ value: loppuu, disabled: lukossa }, [])

    const kausiGroup = new FormGroup<FormGroupKkruutujakso>({
      'alkaa': alkaaFormControl,
      'loppuu': loppuuFormControl
    })

    alkaaFormControl.setValidators([Validators.required, this._kkRuutuAlkaaEnnenLoppuaValidator(kausiGroup), this._kkRuutuAlkaaEdellisenLoppumisenJalkeenValidator(kausiGroup)])
    loppuuFormControl.setValidators([this._kkRuutuAlkaaEnnenLoppuaValidator(kausiGroup), this._kkRuutuEiLopuVaikkaSeuraavaValidator(kausiGroup)])

    alkaaFormControl.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe((value: Date) => {
      kausi.alkaa = this._dateService.dateToLocalDate(value)
      setTimeout(() => {
        this.annaKkruutujenKausiControllit().forEach(fg => {
          if (fg !== kausiGroup) {
            fg.controls.alkaa.updateValueAndValidity({ emitEvent: false })
            fg.controls.loppuu.updateValueAndValidity({ emitEvent: false })
            fg.markAllAsTouched()
          }
        })
      }, 10)
    })

    loppuuFormControl.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(value => {
      kausi.loppuu = this._dateService.dateToLocalDate(value)
      setTimeout(() => {
        this.annaKkruutujenKausiControllit().forEach(fg => {
          if (fg !== kausiGroup) {
            fg.controls.alkaa.updateValueAndValidity({ emitEvent: false })
            fg.controls.loppuu.updateValueAndValidity({ emitEvent: false })
            fg.markAllAsTouched()
          }
        })
      }, 10)
    })

    if (focus) {
      setTimeout(() => {
        if (this.kkruutukausiAlkaaInputit.last) {
          this.kkruutukausiAlkaaInputit.last.focus()
          this._changeDetectorRef.markForCheck()
        }
      }, 50)
    }

    return kausiGroup

  }

  private _annaFormGroupTasmaytysTilikausille(tilikausi: Tilikausi): FormGroup<TasmaytysTilikaudetFormInterface> {

    const jakso = this._dateService.muotoilePaikallinen(tilikausi.alkaa, 'dd.MM.yyyy') + ' - ' + this._dateService.muotoilePaikallinen(tilikausi.loppuu, 'dd.MM.yyyy')

    const kausiGroup = new FormGroup<TasmaytysTilikaudetFormInterface>({
      'tasmaytysAktiivinen': new FormControl<boolean>(!tilikausi.omaveroTasmaytysEstetty),
      'tilikaudenJakso': new FormControl<string>(jakso)
    })

    kausiGroup.get('tasmaytysAktiivinen').valueChanges.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(aktiivinen => {
      if (aktiivinen) {
        delete tilikausi.omaveroTasmaytysEstetty
      } else {
        tilikausi.omaveroTasmaytysEstetty = true
      }
    })
    return kausiGroup
  }

  poistaKkruutujakso(group: FormGroup<FormGroupKkruutujakso>) {
    const formArray = this.annaKkruutujenKaudetFormArray()
    const index = formArray.controls.indexOf(group)
    if (index > -1) {
      formArray.removeAt(index)
      this.asiakas.kkruutujenKaudet.splice(index, 1)
      this._formValidationService.tarkistaVirheetLapsistaJaItsesta(this.annaKkruutujenKaudetFormArray())
    }
  }

  private annaFormGroupTilikaudelle(kausi: Tilikausi, focus: boolean, lukitseVanhat: boolean): FormGroup<TilikausiFormGroup> {
    const alkaa = this._dateService.localDateToDate(kausi.alkaa)
    const loppuu = this._dateService.localDateToDate(kausi.loppuu)
    const kausiGroup = new FormGroup<TilikausiFormGroup>({
      'lukittu': new FormControl<boolean>({ value: !!kausi.lukittu, disabled: false }, []),
      'alkaa': new FormControl<Date>({ value: alkaa, disabled: false }, [Validators.required]), // , this.tilikaudenEdellinenKuukausiValidator
      'loppuu': new FormControl<Date>({ value: loppuu, disabled: false }, [Validators.required])
    }, [this._annaAlkaaEnnenLoppuaValidatorTilikausi])

    // kausiGroup.get('kausi').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(value => {
    //   kausi.kausi = this.validationService.processValue(value)
    // })
    kausiGroup.get('alkaa').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe((value: Date) => {
      kausi.alkaa = this._dateService.dateToLocalDate(value)
    })
    kausiGroup.get('loppuu').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe((value: Date) => {
      kausi.loppuu = this._dateService.dateToLocalDate(value)
    })

    if (focus) {
      setTimeout(() => {
        if (this.tilikausiAlkaaInputit.last) {
          this.tilikausiAlkaaInputit.last.focus()
          this._changeDetectorRef.markForCheck()
        }
      }, 10)
    }

    return kausiGroup

  }

  private _annaFormGroupKirjanpidonTyypille(tyyppi: AsiakkaanKirjanpidonTyyppi): FormGroup<KirjanpidonTyyppiFormGroup> {
    const alkaa = this._dateService.localDateToDate(tyyppi.alkaa)
    const loppuu = this._dateService.localDateToDate(tyyppi.loppuu)
    const tyyppiGroup = new FormGroup<KirjanpidonTyyppiFormGroup>({
      'tyyppi': new FormControl<string>(tyyppi.tyyppi),
      'alkaa': new FormControl<Date>(alkaa),
      'loppuu': new FormControl<Date>(loppuu),
    })

    tyyppiGroup.get('tyyppi').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(value => {
      tyyppi.tyyppi = this._validationService.processValue(value)
    })
    tyyppiGroup.get('alkaa').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe((value: Date) => {
      tyyppi.alkaa = this._dateService.dateToLocalDate(value)
    })
    tyyppiGroup.get('loppuu').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe((value: Date) => {
      tyyppi.loppuu = this._dateService.dateToLocalDate(value)
    })

    return tyyppiGroup

  }

  private _annaFormGroupKirjanpidonPerusteelle(peruste: AsiakkaanKirjanpidonPeruste, locked: boolean): FormGroup<KirjanpidonPerusteetFormGroup> {
    const alkaa = this._dateService.localDateToDate(peruste.alkaa)
    const loppuu = this._dateService.localDateToDate(peruste.loppuu)

    const alkaaFormControl = new FormControl<Date>({ value: alkaa, disabled: locked })
    const loppuuFormControl = new FormControl<Date>({ value: loppuu, disabled: true })

    const perusteGroup = new FormGroup<KirjanpidonPerusteetFormGroup>({
      'peruste': new FormControl<string>({ value: peruste.peruste, disabled: locked }),
      'alkaa': alkaaFormControl,
      'loppuu': loppuuFormControl
    }, [this._annaAlkaaEnnenLoppuaValidatorPeruste])

    perusteGroup.get('peruste').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(value => {
      peruste.peruste = this._validationService.processValue(value)
    })
    alkaaFormControl.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe((value: Date) => {
      peruste.alkaa = (value && this._dateService.isValidDate(value)) ? this._dateService.dateToLocalDate(this._dateService.kuukaudenEnsimmainen(value)) : null
      if (peruste.alkaa) {
        const controls = this.annaKirjanpidonPerusteetControllit()
        const index = controls.findIndex(a => a === perusteGroup)
        if (index > 0) {
          const edellinen = controls[index - 1]
          if (edellinen) {
            edellinen.get('loppuu').setValue(this._dateService.lisaaPaivia(value, -1))
          }
          // const edellinen = controls[index - 1]
          // const edellinenPeruste = this.asiakas.kirjanpidonPerusteet[index - 1]
          // if (edellinen && edellinenPeruste) {
          //   const edellinenPaiva = this._dateService.lisaaPaivia(value, -1)
          //   edellinenPeruste.loppuu = this._dateService.dateToLocalDate(edellinenPaiva)
          //   edellinen.get('loppuu').setValue(edellinenPaiva)
          // }
        }
      }
    })
    loppuuFormControl.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe((value: Date) => {
      peruste.loppuu = this._dateService.dateToLocalDate(value)
    })

    return perusteGroup

  }

  private _annaAlkaaEnnenLoppuaValidatorTilikausi = (control: FormGroup<TilikausiFormGroup>): ValidationErrors | null => {
    const a = control.get('alkaa')?.value
    const l = control.get('loppuu')?.value
    // console.log('asdf', a, l)
    if (l && a && a > l) {
      return { 'loppuEnnenAlkua': true }
    }
    return null
  }

  private _annaAlkaaEnnenLoppuaValidatorPeruste = (control: FormGroup<KirjanpidonPerusteetFormGroup>): ValidationErrors | null => {
    const a = control.get('alkaa')?.value
    const l = control.get('loppuu')?.value
    // console.log('asdf', a, l)
    if (l && a && a > l) {
      return { 'loppuEnnenAlkua': true }
    }
    return null
  }

  private _katkeamatonAikajanaValidator = (array: FormArray<FormGroup<KirjanpidonPerusteetFormGroup>>): ValidationErrors | null => {
    if (array?.controls?.length > 1) {
      let edellinenGroup: FormGroup<KirjanpidonPerusteetFormGroup> = null
      for (const control of array.controls) {
        if (edellinenGroup) {

          const edellinenPeruste = edellinenGroup.get('peruste').value
          const peruste = control.get('peruste').value
          if (edellinenPeruste && peruste && peruste === edellinenPeruste) {
            return { samaPeruste: true }
          }

          const edellinenLoppuu = edellinenGroup.get('loppuu').value
          if (!edellinenLoppuu) {
            return { edellisenLoppu: true }
          }

          const nykyinenAlkaa = control.get('alkaa').value
          if (!nykyinenAlkaa || this._dateService.paiviaValissa(nykyinenAlkaa, edellinenLoppuu) !== 1) {
            return { edellisenLoppu: true }
          }

        }
        edellinenGroup = control
      }
    }
    return null
  }

  //   if(control.get('alkaa').value) {
  //   const controls = this.annaKirjanpidonPerusteetControllit()
  //   const index = controls.indexOf(control)
  //   if (index > 0) {
  //     const edellinen = controls[index - 1]
  //     if (edellinen) {
  //       const edellinenControl = edellinen.get('loppuu')
  //       if (!edellinenControl.value) {
  //         return { edellinen: true }
  //       }
  //       console.log(this._dateService.paiviaValissa(control.get('alkaa').value, edellinenControl.value))
  //       if (this._dateService.paiviaValissa(control.get('alkaa').value, edellinenControl.value) !== 1) {
  //         return { edellinen: true }
  //       }
  //     }
  //   }
  // }
  // return null
  //   }

  private annaFormGroupAlvIlmoitusjaksolle(
    jakso: AlvIlmoitusjaksoAikajaksolla,
    index: number,
    jaksot: AlvIlmoitusjaksoAikajaksolla[],
    viimeisinAlvIlmoitus: AlvIlmoitus,
    focus: boolean,
    lukitseVanhat: boolean,
    aikaisinMahdollinenPaivamaara: Date,
    punakynaActivated: boolean
  ): FormGroup<AlvIlmoitusjaksoFormGroup> {

    const paiva = this._dateService.localDateToDate(jakso.voimassaAlkaen)
    const viimeisimmanAlvIlmonLoppuPvmAsDate = this._dateService.localDateToDate(viimeisinAlvIlmoitus ? viimeisinAlvIlmoitus.endDate : { year: 1980, month: 1, day: 1 })
    // console.log('PÄIVÄ', jakso.voimassaAlkaen, paiva, this._dateService.kuukausiaValissa(paiva, viimeisimmanAlvIlmonLoppuPvmAsDate))
    const pvmLukossa = !punakynaActivated && lukitseVanhat && this._dateService.kuukausiaValissa(paiva, viimeisimmanAlvIlmonLoppuPvmAsDate) < 1
    const tyyppiSaaOllaAuki = index === 0 && jaksot.length === 1 && !viimeisinAlvIlmoitus
    const tyyppiLukossa = !punakynaActivated && !tyyppiSaaOllaAuki && pvmLukossa
    const voimassaAlkaenField = new FormControl<Date>({ value: paiva, disabled: pvmLukossa })
    const jaksoGroup = new FormGroup<AlvIlmoitusjaksoFormGroup>({
      'jakso': new FormControl<AlvIlmoitusjakso>({ value: jakso.alvIlmoitusjakso, disabled: tyyppiLukossa }, punakynaActivated ? [Validators.required] : [this.edellinenAlvJaksoValidator, Validators.required]),
      'voimassaAlkaen': voimassaAlkaenField
    })
    voimassaAlkaenField.setValidators(punakynaActivated ? [Validators.required] : [Validators.required, this.edellisenAlvjaksonKuukausiValidator, this._getMinimumMonthValidator(jaksoGroup, aikaisinMahdollinenPaivamaara)])

    voimassaAlkaenField.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe((value: Date) => {
      jakso.voimassaAlkaen = this._dateService.dateToLocalDate(value)
      this._formValidationService.tarkistaVirheetLapsistaJaItsesta(this.annaAlvIlmoitusjaksotFormArray())
    })
    jaksoGroup.get('jakso').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(value => {
      jakso.alvIlmoitusjakso = value
      this._formValidationService.tarkistaVirheetLapsistaJaItsesta(this.annaAlvIlmoitusjaksotFormArray())
    })

    if (focus) {
      setTimeout(() => {
        if (this.alvIlmoitusjaksoSelectit.last) {
          this.alvIlmoitusjaksoSelectit.last.focus()
          this._changeDetectorRef.markForCheck()
        }
      }, 10)
    }

    return jaksoGroup
  }

  annaUusiAlvIlmoitusjaksoAikajaksolla(): AlvIlmoitusjaksoAikajaksolla {
    return {
      alvIlmoitusjakso: null,
      muutosPvm: this._timestampService.now(),
      voimassaAlkaen: null
    }
  }

  poistaAlvIlmoitusjaksorivi(group: FormGroup<AlvIlmoitusjaksoFormGroup>) {
    const formArray = this.annaAlvIlmoitusjaksotFormArray()
    const index = formArray.controls.indexOf(group)
    if (index > -1) {
      formArray.removeAt(index)
      this.asiakas.alvIlmoitusjaksot.splice(index, 1)
      this._formValidationService.tarkistaVirheetLapsistaJaItsesta(this.annaAlvIlmoitusjaksotFormArray())
    }
  }

  async lisaaAlvIlmoitusjaksorivi() {

    const viimeisinAlvIlmoitus = await firstValueFrom(this.viimeisinAlvIlmoitusObservable)
    const punakynaActivated = await firstValueFrom(this.punakynaActiveSubject)
    const aikaisinMahdollinenPaivamaara = this._annaAikaisinMahdollinenPaivamaara(viimeisinAlvIlmoitus)
    const formArray = this.annaAlvIlmoitusjaksotFormArray()

    if (!this.asiakas.alvIlmoitusjaksot) {
      this.asiakas.alvIlmoitusjaksot = []
    }

    const jakso = this.annaUusiAlvIlmoitusjaksoAikajaksolla()
    const formGroup = this.annaFormGroupAlvIlmoitusjaksolle(jakso, this.asiakas.alvIlmoitusjaksot.length, this.asiakas.alvIlmoitusjaksot, null, true, false, aikaisinMahdollinenPaivamaara, punakynaActivated)

    this.asiakas.alvIlmoitusjaksot.push(jakso)
    formArray.push(formGroup)
  }

  private edellisenAlvjaksonKuukausiValidator = (control: FormControl<Date>) => {
    if (control.value) {
      const formArray = this.annaAlvIlmoitusjaksotFormArray()
      const index = formArray.controls.indexOf(control.parent as FormGroup<AlvIlmoitusjaksoFormGroup>)
      if (index > 0) {
        const edellinen = formArray.controls[index - 1]

        if (edellinen) {
          const edellinenVoimassaControl = edellinen.get('voimassaAlkaen')
          if (edellinenVoimassaControl && edellinenVoimassaControl.value) {

            const currentDateAsLocal = this._dateService.dateToLocalDate(control.value)

            // Validoi, ettei tämä ala ennen edellistä
            // console.log('Validoidaan nykyinen: ', control.value)
            // console.log('Validoidaan edellinen:', edellinenVoimassaControl.value)
            // console.log('Kuukausia välissä:', this._dateService.kuukausiaValissa(control.value, edellinenVoimassaControl.value))
            if (this._dateService.kuukausiaValissa(control.value, edellinenVoimassaControl.value) < 1) {
              return { edellinen: true }
            }

            // Tässä käsitellään kaikki jaksojen vaihtojen väliset säännöt
            const edellinenJaksoControl = edellinen.get('jakso')
            if (edellinenJaksoControl && edellinenJaksoControl.value) {
              const nykyinenJaksoControl = control.parent.get('jakso')

              // Vaihdetaan 1kk tai 3kk -> 12kk
              if ((edellinenJaksoControl.value === AlvIlmoitusjakso.KK1 || edellinenJaksoControl.value === AlvIlmoitusjakso.KK3) && nykyinenJaksoControl.value === AlvIlmoitusjakso.KK12) {
                const seuraavanVuodenAlku: LocalDate = { year: edellinenVoimassaControl.value.getFullYear() + 1, month: 1, day: 1 }
                if (this._dateService.kuukausiaValissa(control.value, this._dateService.localDateToDate(seuraavanVuodenAlku)) < 0) {
                  // eslint-disable-next-line @typescript-eslint/naming-convention
                  return { 'a1_or_3_to12': true }
                }
                if (currentDateAsLocal.month !== 1) {
                  // eslint-disable-next-line @typescript-eslint/naming-convention
                  return { 'b1_or_3_to12': true }
                }
              }

              // Vaihdetaan 1kk -> 3kk
              if (edellinenJaksoControl.value === AlvIlmoitusjakso.KK1 && nykyinenJaksoControl.value === AlvIlmoitusjakso.KK3) {
                const asLocal: LocalDate = this._dateService.dateToLocalDate(edellinenVoimassaControl.value)
                asLocal.day = 1
                if (asLocal.month === 1 || asLocal.month === 2 || asLocal.month === 3) {
                  asLocal.month = 4
                } else if (asLocal.month === 4 || asLocal.month === 5 || asLocal.month === 6) {
                  asLocal.month = 7
                } else if (asLocal.month === 7 || asLocal.month === 8 || asLocal.month === 9) {
                  asLocal.month = 10
                } else if (asLocal.month === 10 || asLocal.month === 11 || asLocal.month === 12) {
                  asLocal.month = 1
                  asLocal.year = asLocal.year + 1
                }
                if (this._dateService.kuukausiaValissa(control.value, this._dateService.localDateToDate(asLocal)) < 0) {
                  // eslint-disable-next-line @typescript-eslint/naming-convention
                  return { 'a1_to_3': true }
                }
                if (currentDateAsLocal.month !== 1 && currentDateAsLocal.month !== 4 && currentDateAsLocal.month !== 7 && currentDateAsLocal.month !== 10) {
                  // eslint-disable-next-line @typescript-eslint/naming-convention
                  return { 'b1_to_3': true }
                }
              }

              // Vaihdetaan 12kk tai 3kk -> 1kk
              if ((edellinenJaksoControl.value === AlvIlmoitusjakso.KK12 || edellinenJaksoControl.value === AlvIlmoitusjakso.KK3) && nykyinenJaksoControl.value === AlvIlmoitusjakso.KK1) {
                // Aikaisintaan seuraavana kuuna, joka on sama kuin perusvalidaattori
              }

              // Vaihdetaan 12kk -> 3kk
              if (edellinenJaksoControl.value === AlvIlmoitusjakso.KK12 && nykyinenJaksoControl.value === AlvIlmoitusjakso.KK3) {
                const asLocal: LocalDate = this._dateService.dateToLocalDate(edellinenVoimassaControl.value)
                asLocal.day = 1
                if (asLocal.month === 1 || asLocal.month === 2 || asLocal.month === 3) {
                  asLocal.month = 4
                } else if (asLocal.month === 4 || asLocal.month === 5 || asLocal.month === 6) {
                  asLocal.month = 7
                } else if (asLocal.month === 7 || asLocal.month === 8 || asLocal.month === 9) {
                  asLocal.month = 10
                } else if (asLocal.month === 10 || asLocal.month === 11 || asLocal.month === 12) {
                  asLocal.month = 1
                  asLocal.year = asLocal.year + 1
                }
                if (this._dateService.kuukausiaValissa(control.value, this._dateService.localDateToDate(asLocal)) < 0) {
                  // eslint-disable-next-line @typescript-eslint/naming-convention
                  return { 'a12_to_3': true }
                }
                if (currentDateAsLocal.month !== 1 && currentDateAsLocal.month !== 4 && currentDateAsLocal.month !== 7 && currentDateAsLocal.month !== 10) {
                  // eslint-disable-next-line @typescript-eslint/naming-convention
                  return { 'b12_to_3': true }
                }
              }

            }

          }
        }
      }
    }
    return null
  }

  // 12kk -> 1kk, 3kk -> 1kk: seuraavan kuukauden 1.pv HUOM!!! TÄMÄN PITÄÄ KATKAISTA PITEMPI JAKSO MYÖS ALV-ILMOITUKSELLA!!!!!
  // 12kk -> 3kk: seuraava näistä: 1.1, 1.4, 1.7, 1.10
  // 1kk -> 3kk tai 12kk, 3kk -> 12kk: seuraavan vuoden 1.1

  private edellinenAlvJaksoValidator = (control: FormControl<AlvIlmoitusjakso>) => {
    if (control.value) {
      const formArray = this.annaAlvIlmoitusjaksotFormArray()
      const index = formArray.controls.indexOf(control.parent as FormGroup<AlvIlmoitusjaksoFormGroup>)
      if (index > 0) {
        const edellinen = formArray.controls[index - 1]
        if (edellinen) {
          const edellinenControl = edellinen.get('jakso')
          if (edellinenControl && edellinenControl.value) {
            if (control.value === edellinenControl.value) {
              return { samajakso: true }
            }
          }
        }
      }
    }
    return null
  }

  private annaUusiKausi(previous?: Tilikausi): Tilikausi {
    if (previous) {
      const previousLengthIsOneYear = this._dateService.kuukausiaValissaPaikallinen(this._dateService.lisaaPaiviaPaikallinen(previous.loppuu, 1), previous.alkaa) === 12
      const alkaa = this._dateService.lisaaPaiviaPaikallinen(previous.loppuu, 1)
      return {
        avain: this._firebase.firestoreCreateId(),
        alkaa: alkaa,
        loppuu: previousLengthIsOneYear ? this._dateService.lisaaPaiviaPaikallinen(this._dateService.lisaaKuukausiaPaikallinen(alkaa, 12), -1) : null
      }
    }
    return {
      avain: this._firebase.firestoreCreateId(),
      alkaa: null,
      loppuu: null
    }
  }

  poistaTilikausi(group: FormGroup<TilikausiFormGroup>) {
    const formArray = this.annaTilikaudetFormArray()
    const index = formArray.controls.indexOf(group)
    if (index > -1) {
      formArray.removeAt(index)
      this.asiakas.tilikaudet.splice(index, 1)
    }
  }

  lisaaTilikausi() {
    const formArray = this.annaTilikaudetFormArray()

    const edellinenTilikausi = this.asiakas.tilikaudet?.sort((a, b) => this._dateService.localDateToNumber(b.alkaa) - this._dateService.localDateToNumber(a.alkaa))?.[0]
    const kausi = this.annaUusiKausi(edellinenTilikausi)
    const formGroup = this.annaFormGroupTilikaudelle(kausi, true, false)

    if (!this.asiakas.tilikaudet) {
      this.asiakas.tilikaudet = []
    }
    this.asiakas.tilikaudet.push(kausi)
    formArray.push(formGroup)
  }

  lisaaKirjanpidonTyyppi() {
    const formArray = this.annaKirjanpidonTyypitFormArray()

    const tyyppi: AsiakkaanKirjanpidonTyyppi = {
      alkaa: null,
      loppuu: null,
      tyyppi: KirjanpidonTyyppi.KAHDENKERTAINEN
    }
    const formGroup = this._annaFormGroupKirjanpidonTyypille(tyyppi)

    if (!this.asiakas.kirjanpidonTyypit) {
      this.asiakas.kirjanpidonTyypit = []
    }
    this.asiakas.kirjanpidonTyypit.push(tyyppi)
    formArray.push(formGroup)
  }


  lisaaKirjanpidonPeruste() {
    const formArray = this.annaKirjanpidonPerusteetFormArray()

    if (!this.asiakas.kirjanpidonPerusteet) {
      this.asiakas.kirjanpidonPerusteet = []
    }

    const peruste: AsiakkaanKirjanpidonPeruste = {
      alkaa: null,
      loppuu: null,
      peruste: KirjanpidonPeruste.MAKSU
    }

    const formGroup = this._annaFormGroupKirjanpidonPerusteelle(peruste, this.asiakas.kirjanpidonPerusteet.length < 1)

    this.asiakas.kirjanpidonPerusteet.push(peruste)
    formArray.push(formGroup)
  }


  private _annaMinimiMuutokseDate(group: FormGroup<AlvIlmoitusjaksoFormGroup>, aikaisinMahdollinenPaivamaara: Date): Date {
    const formArray = this.annaAlvIlmoitusjaksotFormArray()
    const index = formArray.controls.indexOf(group)
    if (index > 0) {
      const edellinen = formArray.controls[index - 1]
      if (edellinen) {
        const edellinenVoimassaControl = edellinen.get('voimassaAlkaen')
        if (edellinenVoimassaControl && edellinenVoimassaControl.value) {
          const edellisenPaivamaaranPerusteella = this._dateService.lisaaKuukausia(edellinenVoimassaControl.value, 1)

          let jaksonPerusteella: Date = null
          // Tässä käsitellään kaikki jaksojen vaihtojen väliset säännöt
          const edellinenJaksoControl = edellinen.get('jakso')
          if (edellinenJaksoControl && edellinenJaksoControl.value) {
            const nykyinenJaksoControl = group.get('jakso')

            // Vaihdetaan 1kk pidempään
            if (edellinenJaksoControl.value === AlvIlmoitusjakso.KK1 && (nykyinenJaksoControl.value === AlvIlmoitusjakso.KK12 || nykyinenJaksoControl.value === AlvIlmoitusjakso.KK3)) {
              const seuraavanVuodenAlku: LocalDate = { year: edellinenVoimassaControl.value.getFullYear() + 1, month: 1, day: 1 }
              jaksonPerusteella = this._dateService.localDateToDate(seuraavanVuodenAlku)
            }

            // Vaihdetaan 3kk pidempään
            if (edellinenJaksoControl.value === AlvIlmoitusjakso.KK3 && nykyinenJaksoControl.value === AlvIlmoitusjakso.KK12) {
              const seuraavanVuodenAlku: LocalDate = { year: edellinenVoimassaControl.value.getFullYear() + 1, month: 1, day: 1 }
              jaksonPerusteella = this._dateService.localDateToDate(seuraavanVuodenAlku)
            }

            // Vaihdetaan 12kk tai 3kk 1kk
            if ((edellinenJaksoControl.value === AlvIlmoitusjakso.KK12 || edellinenJaksoControl.value === AlvIlmoitusjakso.KK3) && nykyinenJaksoControl.value === AlvIlmoitusjakso.KK1) {
              // Aikaisintaan seuraavana kuuna, joka on sama kuin perusvalidaattori
            }

            // Vaihdetaan 12kk -> 3kk
            if (edellinenJaksoControl.value === AlvIlmoitusjakso.KK12 && nykyinenJaksoControl.value === AlvIlmoitusjakso.KK3) {
              const asLocal: LocalDate = this._dateService.dateToLocalDate(edellinenVoimassaControl.value)
              asLocal.day = 1
              if (asLocal.month === 1 || asLocal.month === 2 || asLocal.month === 3) {
                asLocal.month = 4
              } else if (asLocal.month === 4 || asLocal.month === 5 || asLocal.month === 6) {
                asLocal.month = 7
              } else if (asLocal.month === 7 || asLocal.month === 8 || asLocal.month === 9) {
                asLocal.month = 10
              } else if (asLocal.month === 10 || asLocal.month === 11 || asLocal.month === 12) {
                asLocal.month = 1
                asLocal.year = asLocal.year + 1
              }
              jaksonPerusteella = this._dateService.localDateToDate(asLocal)
            }
          }
          return this._dateService.max([aikaisinMahdollinenPaivamaara, jaksonPerusteella, edellisenPaivamaaranPerusteella].filter(Boolean))

        }
      }
    }

    return aikaisinMahdollinenPaivamaara
  }

  // chosenMonthHandler(normalizedMonth: Date, formGroup: FormGroup, datepicker: MatDatepicker<Date>) {
  //   const control = formGroup.get('voimassaAlkaen') as FormControl
  //   const minDate = this.annaMinimiPaivamaara(control)
  //   if (this._dateService.kuukausiaValissa(normalizedMonth, minDate) > -1) {
  //     control.setValue(normalizedMonth)
  //     control.markAsTouched()
  //   }
  //   datepicker.close()
  // }

  private saveInFlight = false
  async save() {

    this.form.updateValueAndValidity()

    if (this.form.invalid) {
      // console.log(this.form.errors)
      this._validationService.merkitseKokoLomakeKosketuksi(this.form)
      this.paivitaValidaatiot = {}
      return
    }

    if (this.saveInFlight) {
      return
    }
    this.saveInFlight = true

    this._ladataanService.aloitaLataaminen()

    const batch = this._firebase.firestoreBatch()
    if (this._ovatkoKuukausijaksotMuuttuneet(this.asiakas, this._originalAsiakas)) {
      const uri = 'tyojono/' + this.asiakas.avain + '/luo-tilikaudet-ja-kuukausiruudut/' + this._firebase.firestoreCreateId()
      const data: AsiakkaidenTilikausienLuontityodata = {
        tiettyAsiakas: this.asiakas.avain
      }
      batch.set(uri, data)
    }

    const kirjanpitajanTiedot = await this._kirjautunutKayttajaService.getKirjanpitajanTiedot()
    await this._asiakasService.lisaaAsiakasBatchiin(this.asiakas, kirjanpitajanTiedot, 'paivita-tilikausiin-perustuvat-tiedot', false, true, batch)

    batch.commit().then(() => {
      this.saveInFlight = false
      this._asiakasService.asetaNykyinenAsiakas(this.asiakas)
      this._snackbar.open('Kirjanpitotiedot tallennettiin onnistuneesti.', 'OK', { duration: 5000, verticalPosition: 'top' })
      this._ladataanService.lopetaLataaminen()
    }).catch(err => {
      this.saveInFlight = false
      this._ladataanService.lopetaLataaminen()
      this._errorHandler.handleError(err)
      this._snackbar.open('TALLENNUS EPÄONNISTUI. Kerro terveiset ylläpidolle.', 'Pilalla', { duration: 10000, verticalPosition: 'top' })
    })

  }

  private _ovatkoKuukausijaksotMuuttuneet(asiakas1: Asiakas, asiakas2: Asiakas): boolean {
    if (asiakas1.kkruutujenKaudet?.length !== asiakas2.kkruutujenKaudet?.length) {
      return true
    } else if (asiakas1.kkruutujenKaudet && asiakas2.kkruutujenKaudet) {
      let i = 0
      for (const eka of asiakas1.kkruutujenKaudet) {
        const toka = asiakas2.kkruutujenKaudet[i]
        if (
          (eka.alkaa && toka.alkaa && this._dateService.compareLocalDates(eka.alkaa, '!=', toka.alkaa)) ||
          (eka.alkaa && !toka.alkaa) ||
          (!eka.alkaa && toka.alkaa) ||
          (eka.loppuu && toka.loppuu && this._dateService.compareLocalDates(eka.loppuu, '!=', toka.loppuu)) ||
          (eka.loppuu && !toka.loppuu) ||
          (!eka.loppuu && toka.loppuu)
        ) {
          return true
        }
        i++
      }
    }
    return false
  }

  avaaTilikausi(index: number) {
    const tilikausi = this.asiakas.tilikaudet[index]

    if (!this._asiakasJaettuService.voikoTilikaudenLukituksenAvata(tilikausi, this.asiakas)) {
      // Lukitun tilikauden voi avata vain jos sitä myöhempää lukittua tilikautta ei ole. 
      console.error(`Asiakkaan ${this.asiakas.avain} lukittua tilikautta ${tilikausi.avain} ei voida avata, koska myöhempi tilikausi on lukittu.`)
      const dialogData: InfoDialogData = {
        header: 'Lukittua tilikautta ei voida avata',
        text: 'Lukitun tilikauden voi avata vain, jos sitä myöhempää lukittua tilikautta ei ole. '
      }
      this._dialog.open(InfoDialog, { data: dialogData })
      return
    }

    const data: AreYouSureDialogData = {
      header: 'Haluatko avata tilikauden uudelleen muokattavaksi?',
      rightAction: 'Avaa',
      text: 'Tilikausi voidaan myöhemmin lukita uudelleen.'
    }
    const dialogHandle = this._dialog.open(AreYouSureDialog, { data: data })
    dialogHandle.afterClosed().pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(result => {
      if (result) {
        this._ladataanService.aloitaLataaminen()
        const tyoavain = this._firebase.firestoreCreateId()
        const uri = this._kirjanpitoUriService.annaTyojonoKirjanpitoTilikausiValmiiksiUri(this.asiakas.avain, tyoavain)
        const tyojono: KirjanpitoMerkitseTilikausiValmiiksiTyojonotiedot = {
          asiakasAvain: this.asiakas.avain,
          tilikausiAvain: this.asiakas.tilikaudet[index].avain,
          toiminto: 'poista-valmismerkinta',
          estaAutomaatiolukitus: true
        }
        this._firebase.firestoreSetData(uri, tyojono)
        // console.log('Avataan tilikausi ', tilikausi.avain, tilikausi.alkaa, tilikausi.loppuu)
        setTimeout(() => {
          this._ladataanService.lopetaLataaminen()
        }, 4000)
      }
      //  else {
      // console.log('Cancel')
      // }
    })
  }

  lukitseTilikausi(index: number) {
    const tilikausi = this.asiakas.tilikaudet[index]
    const data: AreYouSureDialogData = {
      header: 'Haluatko varmasti lukita tilikauden?',
      rightAction: 'Lukitse',
      text: 'Tilikautta tai sen kirjauksia ja vientejä ei voi enää lukitsemisen jälkeen muokata ja aineistosta taltioidaan uusi versio. Tilikausi merkitään kokonaan käsitellyksi.'
    }
    const dialogHandle = this._dialog.open(AreYouSureDialog, { data: data })
    dialogHandle.afterClosed().pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(result => {
      if (result) {
        this._ladataanService.aloitaLataaminen()
        const tyoavain = this._firebase.firestoreCreateId()
        const uri = this._kirjanpitoUriService.annaTyojonoKirjanpitoTilikausiValmiiksiUri(this.asiakas.avain, tyoavain)
        const tyojono: KirjanpitoMerkitseTilikausiValmiiksiTyojonotiedot = {
          asiakasAvain: this.asiakas.avain,
          tilikausiAvain: this.asiakas.tilikaudet[index].avain,
          toiminto: 'merkitse-valmiiksi',
          estaAutomaatiolukitus: true
        }
        this._firebase.firestoreSetData(uri, tyojono)
        // console.log('Lukitaan tilikausi ', tilikausi.avain, tilikausi.alkaa, tilikausi.loppuu)
        setTimeout(() => {
          this._ladataanService.lopetaLataaminen()
        }, 1500)
      }
      //  else {
      //   console.log('Cancel')
      // }
    })
  }

  lisaaVastaanottaja() {
    this.vastaanottajaFormControl.markAsDirty()
    this.vastaanottajaFormControl.markAsTouched()
    this.vastaanottajaFormControl.updateValueAndValidity()
    this.lisaaEmail(this.vastaanottajaFormControl)
  }
  poistaVastaanottaja(vastaanottaja: string): void {
    if (!this.asiakas.kirjanpitoviestienLisavastaanottajat) { this.asiakas.kirjanpitoviestienLisavastaanottajat = [] }
    const index = this.asiakas.kirjanpitoviestienLisavastaanottajat.indexOf(vastaanottaja)
    if (index >= 0) {
      this.asiakas.kirjanpitoviestienLisavastaanottajat.splice(index, 1)
      if (this.asiakas.kirjanpitoviestienLisavastaanottajat.length < 1) {
        delete this.asiakas.kirjanpitoviestienLisavastaanottajat
      }
    }
  }
  private lisaaEmail(field: FormControl<string>) {
    if ((field.value || '').trim()) {
      let arvo = field.value.trim()
      arvo = arvo.replace(/,/g, '').trim()
      if (!new FormControl<string>(arvo, [Validators.email]).errors) {
        if (!this.asiakas.kirjanpitoviestienLisavastaanottajat) { this.asiakas.kirjanpitoviestienLisavastaanottajat = [] }
        this.asiakas.kirjanpitoviestienLisavastaanottajat.push(arvo)
        field.setValue('')
      }
    }
  }

}
