import { Component, OnInit, OnDestroy, ViewChild, ErrorHandler, Renderer2, AfterViewChecked, DoCheck } from '@angular/core'
import { Router, NavigationStart } from '@angular/router'
import { MatSidenav } from '@angular/material/sidenav'

import { FirebaseLemonator } from 'app/_angular/service/firebase-lemonator.service'

import { KirjautunutKayttajaService } from './_angular/service/kirjautunut-kayttaja.service'
import { VasenValikkoService } from './_jaettu-angular/service/vasen-valikko.service'
import { LadataanService } from './_jaettu-angular/service/ladataan.service'
import { DragAndDropService } from './_jaettu-angular/service/drag-and-drop.service'
import { LoadingConfig } from './_jaettu-angular/_components/loading.component'

import { AsiakasService } from './_angular/service/asiakas/asiakas.service'

import { Asiakas } from './_jaettu-lemonator/model/asiakas'
import { Kirjanpitaja, LopetaKayttajanaKirjautuminenPyynto } from './_jaettu-lemonator/model/kirjanpitaja'
import { KirjanpitajanRooli } from './_jaettu/lemonator/model/kirjanpitaja'

import { VersionTarkistusPalvelu } from './_angular/service/version-tarkistus.palvelu'
import { KirjanpitajaService } from './_angular/service/kirjanpitaja/kirjanpitaja.service'

import { Subject, Observable, BehaviorSubject, timer, of } from 'rxjs'
import { takeUntil, filter, map, distinctUntilChanged, tap, switchMap, catchError } from 'rxjs/operators'
import { backoff, LEMONATOR_HTTPS_IMAGES_API, LemonHttpService } from './_angular/service/lemon-http.service'
import { KirjanpitajanTiimiService } from './_jaettu-lemonator/service/kirjanpitajan-tiimi.service'
import { DateService } from './_shared-core/service/date.service'
import { TrackingService } from './_angular/service/logs/tracking.service'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
// OnChanges, DoCheck
export class AppComponent implements AfterViewChecked, OnInit, OnDestroy, DoCheck {

  @ViewChild('nav', { read: MatSidenav }) nav: MatSidenav

  private ngUnsubscribe: Subject<void> = new Subject<void>()

  // private vasemmanValikonVaihtuminen = 1024
  private initiatedInsideDocument = false

  ladataanOverlayConfig: LoadingConfig = {
    backdropBackgroundColour: 'rgba(0, 0, 0, 0.15)',
    backdropBorderRadius: '4px',
    fullScreenBackdrop: true,
    primaryColour: '#ffffff',
    secondaryColour: '#ffffff',
    tertiaryColour: '#ffffff'
  }

  vasenValikkoAukiObservable: BehaviorSubject<boolean> = new BehaviorSubject(false)
  nykyinenAsiakasObservable: Observable<Asiakas>
  kirjanpitajaObservable: Observable<Kirjanpitaja>
  ladataanObservable: Observable<boolean>
  naytaSuperObservable: Observable<boolean>
  naytaYberSuperObservable: Observable<boolean>
  naytaSalesObservable: Observable<boolean>
  naytaLisaaAsiakasObservable: Observable<boolean>
  urlObservable: Observable<string>

  constructor(
    private _errorHandler: ErrorHandler,
    private _kirjautunutKayttajaService: KirjautunutKayttajaService,
    private _router: Router,
    private _vasenValikkoService: VasenValikkoService,
    private _ladataanService: LadataanService,
    private _dndService: DragAndDropService,
    private _renderer: Renderer2,
    private _asiakasService: AsiakasService,
    private _versionTarkistusPalvelu: VersionTarkistusPalvelu,
    private _kirjanpitajaService: KirjanpitajaService,
    private _firebase: FirebaseLemonator,
    private _httpService: LemonHttpService,
    private _kirjanpitajanTiimiService: KirjanpitajanTiimiService,
    private _dateService: DateService,
    private _trackingService: TrackingService
  ) {

    window.caches.keys().then(keys => {
      const all: Promise<boolean>[] = keys.map(key => window.caches.delete(key).then((res) => {
        console.log('Deleted cache ' + key + ': ' + res)
        return res
      }))
      return Promise.all(all)
    }).catch(err => {
      // NOOP, we really don't care if this succeeded or not
    })

    navigator.serviceWorker.register('service-worker.js', { scope: '/' }).then((registration) => {
      registration.addEventListener('updatefound', event => {
        console.log('Service Worker update detected!', event)
      })
      console.log('ServiceWorker registration successful with scope: ', registration.scope)
    }).catch((err) => {
      this._errorHandler.handleError(err)
      console.error('ServiceWorker registration failed: ', err)
    })

    let refreshing = false
    navigator.serviceWorker.addEventListener('controllerchange', event => {
      console.log('Controller changed!', event)
      if (!refreshing) {
        window.location.reload()
        refreshing = true
      }
    })

    navigator.serviceWorker.addEventListener('statechange', event => {
      console.log('Service worker state changed: ', event)
    })

    if (!navigator.serviceWorker.controller) {
      navigator.serviceWorker.getRegistrations().then(registrations => {
        if (registrations) {
          for (const registration of registrations) {
            if (registration.active) {
              registration.active.postMessage('claim')
              console.log('Asked to claim in initial check!')
            }
            if (registration.installing) {
              registration.installing.postMessage('skipandclaim')
              console.log('Asked installing to skip & claim in initial check!')
            }
            if (registration.waiting) {
              registration.waiting.postMessage('skipandclaim')
              console.log('Asked waiting to skip & claim in initial check!')
            }
          }
        } else {
          console.log('No registrations')
        }
      })
    } else {
      console.log('We already have a controller in initial check!')
    }

    // let askedToClaim = false
    // setInterval(() => {

    //   if (!navigator.serviceWorker.controller) {
    //     if (askedToClaim) {
    //       if (!refreshing) {
    //         window.location.reload()
    //         refreshing = true
    //       }
    //     }
    //     navigator.serviceWorker.getRegistrations().then(registrations => {
    //       if (registrations) {
    //         for (const registration of registrations) {
    //           if (registration.active) {
    //             registration.active.postMessage('claim')
    //             askedToClaim = true
    //             // console.log('Asked to claim')
    //           }
    //           if (registration.installing) {
    //             registration.installing.postMessage('skipandclaim')
    //             askedToClaim = true
    //             // console.log('Asked installing to skip & claim')
    //           }
    //           if (registration.waiting) {
    //             registration.waiting.postMessage('skipandclaim')
    //             askedToClaim = true
    //             // console.log('Asked waiting to skip & claim')
    //           }
    //         }
    //       } else {
    //         // console.log('No registrations')
    //       }
    //     })
    //   } else {
    //     // console.log('We already have a controller')
    //   }

    // console.log('My controller:', navigator.serviceWorker.controller)
    // if (navigator.serviceWorker.controller) {
    //   navigator.serviceWorker.controller.onerror = (event) => { console.error('Error from controller', event) }
    //   navigator.serviceWorker.controller.onstatechange = (event) => { console.error('State change from controller', event) }
    // } else {
    //   window.location.reload
    // }
    // navigator.serviceWorker.getRegistrations().then(registrations => {
    //   if (registrations) {
    //     console.log('All registrations', registrations.length)
    //     for (const registration of registrations) {

    //       if (registration.active) {
    //         registration.active.onerror = (event) => { console.error('Error from active', event) }
    //         registration.active.onstatechange = (event) => { console.error('State change from active', event) }
    //       }
    //       if (registration.installing) {
    //         registration.installing.onerror = (event) => { console.error('Error from installing', event) }
    //         registration.installing.onstatechange = (event) => { console.error('State change from installing', event) }
    //       }
    //       if (registration.waiting) {
    //         registration.waiting.onerror = (event) => { console.error('Error from waiting', event) }
    //         registration.waiting.onstatechange = (event) => { console.error('State change from waiting', event) }
    //       }

    //       console.log('registration.active', registration.active)
    //       console.log('registration.installing', registration.installing)
    //       console.log('registration.waiting', registration.waiting)
    //     }
    //   } else {
    //     console.log('No registrations')
    //   }
    // })
    // }, 5000)

    // navigator.serviceWorker.addEventListener('messageerror', event => {
    //   console.error('serviceWorker Message error', event)
    // })

    // navigator.serviceWorker.addEventListener('message', event => {

    //   // console.log('got message')

    //   // we use 1st port provided by the message channel
    //   const port = event.ports[0]

    //   if (event.data === 'getAuthTokenHeader') {
    //     // console.log('Token request from sw')
    //     if (this._auth.currentUser) {
    //       this._auth.currentUser.then(user => {
    //         return user?.getIdToken() || null
    //       }).then(token => {
    //         if (token) {
    //           port.postMessage(token)
    //         } else {
    //           port.postMessage(null)
    //         }
    //       }).catch(err => {
    //         this._errorHandler.handleError(err)
    //         console.error('Unknown error: ', err)
    //         port.postMessage(null)
    //       })
    //     } else {
    //       console.error('No logged in user')
    //       port.postMessage(null)
    //     }
    //   } else {
    //     console.error('Unknown event', event)
    //     port.postMessage(null)
    //   }
    //
    // })

    // this.naytaAvaaVasenValikkoNappiObservable = combineLatest([this._vasenValikkoService.aukiObservable, this._vasenValikkoService.piilotaValikonAvausJaSulkemisnappiObservable]).pipe(
    //   map(([auki, piilota]) => {
    //     return !auki && !piilota
    //   })
    // )

    // this.vasenValikkoAukiObservable = this._vasenValikkoService.aukiObservable.pipe(
    //   tap(() => {

    //   })
    // )

    this._versionTarkistusPalvelu.sovelluksenVersioObservable.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(versio => {
      console.log('Sovelluksen viimeisin versio', versio)
    })

    this._renderer.listen('document', 'dragstart', () => {
      // console.log('RENDERER START DRAG')
      this.initiatedInsideDocument = true
      this._dndService.setDraggingImmediate(true)
    })

    this._renderer.listen('document', 'dragend', () => {
      // console.log('RENDERER END DRAG')
      this.initiatedInsideDocument = false
      this._dndService.setDraggingImmediate(false)
    })

    this._kirjautunutKayttajaService.kirjanpitajanTiedotObservable.pipe(
      switchMap(kirjautunutKayttaja => {
        if (kirjautunutKayttaja) {
          return timer(0, 1000 * 60 * 20).pipe(
            switchMap(() => {
              return this._httpService.getJsonWithCredentials<{ status: 'success' }>('/api/1/auth/setup-kuva-cookie', LEMONATOR_HTTPS_IMAGES_API, true).pipe(
                backoff(15, 1000)
              )
            })
          )
        }
        return of(null)
      }),
      catchError((err, o) => {
        this._errorHandler.handleError(err)
        return o
      }),
      takeUntil(this.ngUnsubscribe)
    ).subscribe({
      error: error => {
        // console.error('TERMINAL ERROR IN COOKIE REFRESH!')
        this._errorHandler.handleError(error)
      }
    })

    this.kirjanpitajaObservable = this._kirjanpitajaService.kirjautuneenKayttajanKirjanpitajaObservable
    this.nykyinenAsiakasObservable = this._asiakasService.nykyinenAsiakasObservable
    this.ladataanObservable = this._ladataanService.ladataanObservable
    this.naytaSuperObservable = this._kirjautunutKayttajaService.kirjanpitajanTiedotObservable.pipe(
      map(kirjanpitajanTiedot => {
        if (kirjanpitajanTiedot) {
          return kirjanpitajanTiedot.rooli === KirjanpitajanRooli.SUPER
        }
        return false
      })
    )
    this.naytaSalesObservable = this._kirjautunutKayttajaService.kirjanpitajanTiedotObservable.pipe(
      map(kirjanpitajanTiedot => {
        if (kirjanpitajanTiedot) {
          return (
            kirjanpitajanTiedot.uid === 'QL4CBlJTLdQLtbb4k8AURFYeg3I3' || // Sanna
            kirjanpitajanTiedot.uid === 'e0Ravrw1L3cBTKfYSG1D6U8sU2o1' // Laura
          )
        }
        return false
      })
    )
    this.naytaYberSuperObservable = this._kirjautunutKayttajaService.kirjanpitajanTiedotObservable.pipe(
      map(kirjanpitaja => {
        if (
          kirjanpitaja && (
            kirjanpitaja.uid === 'AHiu6xZimwXgxmd3d5vqzbC8ce42' || // Jon
            kirjanpitaja.uid === 'dqUiLNmLRaMNbyNgMhWG1AUmVGI2' || // Valtteri
            kirjanpitaja.uid === '4sNRp7LvWTeZ9WTomoKOA9jD42y1' || // Ville
            kirjanpitaja.uid === 'nwGu99bXkWfX40Hk5nLVMKDn3lP2' || // Veera
            kirjanpitaja.uid === 'IbKDXwWiLLNbV4e0ZxtqrcmGPik2' || // Lauri
            kirjanpitaja.uid === 'H6KU6K9S39QaqDnpe1apaUV3dsz1' || // Elina P.
            kirjanpitaja.uid === '3d4UG33dpERg5CqyF1TmafusXNa2' // Ville H.
          )
        ) {
          return true
        }
        return false
      })
    )
    this.naytaLisaaAsiakasObservable = this._kirjautunutKayttajaService.kirjanpitajanTiedotObservable.pipe(
      switchMap(kirjanpitajanTiedot => {
        if (kirjanpitajanTiedot) {
          if (
            kirjanpitajanTiedot.rooli === KirjanpitajanRooli.SUPER ||
            kirjanpitajanTiedot.uid === 'QL4CBlJTLdQLtbb4k8AURFYeg3I3' || // Sanna
            kirjanpitajanTiedot.uid === 'e0Ravrw1L3cBTKfYSG1D6U8sU2o1' // Laura
          ) {
            return of<boolean>(true)
          }
          return this._kirjanpitajanTiimiService.annaKirjanpitajanTiimi(kirjanpitajanTiedot.uid, this._dateService.currentNumberDate())
            .then(val => val?.avain === 'GENOVA')
        } else {
          return of<boolean>(false)
        }
      })
    )

    this.urlObservable = this._router.events.pipe(
      takeUntil(this.ngUnsubscribe),
      filter(event => { return event instanceof NavigationStart }),
      map((event: NavigationStart) => { return event.url }),
      distinctUntilChanged(),
      tap(url => {
        if (
          (
            url.indexOf('/kirjanpito/') > -1 ||
            url.endsWith('/kirjanpito')
          ) ||
          url.indexOf('/laskutus/luo') > -1 ||
          url.indexOf('/kirjaudu') > -1 ||
          url.indexOf('/kirjauduttupois') > -1 ||
          url.indexOf('/kirjanpito-raportit/') > -1 ||
          url.indexOf('/kirjanpidon-raportit/') > -1 ||
          url.indexOf('/kirjanpidon-tosite/') > -1
        ) {
          if (this.vasenValikkoAukiObservable.value) {
            this.vasenValikkoAukiObservable.next(false)
          }
        } else {
          if (!this.vasenValikkoAukiObservable.value) {
            this.vasenValikkoAukiObservable.next(true)
          }
        }
      })
    )

  }

  ngOnInit() {
  }

  private _renderLongStackTrace(): string {
    const frames = ((window as any)?.Zone?.currentTask?.data as any)?.__creationTrace__
    const NEWLINE = '\n'

    // edit this array if you want to ignore or unignore something
    const FILTER_REGEXP: RegExp[] = [
      /checkAndUpdateView/,
      /callViewAction/,
      /execEmbeddedViewsAction/,
      /execComponentViewsAction/,
      /callWithDebugContext/,
      /debugCheckDirectivesFn/,
      /Zone/,
      /checkAndUpdateNode/,
      /debugCheckAndUpdateNode/,
      /onScheduleTask/,
      /onInvoke/,
      /updateDirectives/,
      /@angular/,
      /Observable\._trySubscribe/,
      /Observable.subscribe/,
      /SafeSubscriber/,
      /Subscriber.js.Subscriber/,
      /checkAndUpdateDirectiveInline/,
      /drainMicroTaskQueue/,
      /getStacktraceWithUncaughtError/,
      /LongStackTrace/,
      /Observable._zoneSubscribe/,
    ]

    if (!frames) {
      return 'no frames'
    }

    const filterFrames = (stack: string) => {
      return stack
        .split(NEWLINE)
        .filter((frame) => !FILTER_REGEXP.some((reg) => reg.test(frame)))
        .join(NEWLINE)
    }

    return frames
      .filter(frame => frame.error.stack)
      .map((frame) => filterFrames(frame.error.stack))
      .join(NEWLINE)
  }

  // private test = 0
  ngDoCheck() {
    // this._zone.runOutsideAngular(() => {
    // if (this.test < 1500) {
    //   this.test++
    //   console.log('Change detection!', this.test)
    // } else if (this.test < 1700) {
    // console.log('Change detection!', (window as any)?.Zone?.currentTask?.source, this._renderLongStackTrace())
    // this.test++
    // } else {
    //   console.log('Change detection!', Date.now())
    // }
    // })
  }

  ngAfterViewChecked() {
    // console.count('ngAfterViewChecked')
  }

  // ngOnChanges() {
  //   console.log('OnChanges!', Date.now())
  // }

  ngOnDestroy() {
    this.ngUnsubscribe.next()
    this.ngUnsubscribe.complete()
  }

  dragging(event: DragEvent) {
    // console.log('DRAGGING!')
    if (!this.initiatedInsideDocument) {
      this._dndService.setDraggingRunInZone(true)
    }
    this.preventAndStop(event)
  }

  dragenter(event: DragEvent) {
    // console.log('DRAG ENTER!')
    if (!this.initiatedInsideDocument) {
      this._dndService.setDraggingRunInZone(true)
    }
    this.preventAndStop(event)
  }

  dragleave(event: DragEvent) {
    // console.log('DRAG LEAVE!')
    if (!this.initiatedInsideDocument) {
      this._dndService.setDraggingRunInZone(false)
    }
    this.preventAndStop(event)
  }

  dropped(event: DragEvent) {
    // console.log('DRAG DROPPED!')
    this.initiatedInsideDocument = false
    this._dndService.setDraggingImmediate(false)
  }

  avaaVasenValikko(event: MouseEvent) {
    this.preventAndStop(event)
    this._vasenValikkoService.paivitaAuki(true)
  }

  private preventAndStop(event: Event) {
    event.stopPropagation()
    event.preventDefault()
  }

  lopetaKayttajanaKirjautuminen() {
    this._ladataanService.aloitaLataaminen()
    const pyynto: LopetaKayttajanaKirjautuminenPyynto = {}
    this._firebase.functionsCall<LopetaKayttajanaKirjautuminenPyynto, void>('kirjautuminenKayttajanaLopeta', pyynto).then(() => {
      this._ladataanService.lopetaLataaminen()
    }).catch(error => {
      this._ladataanService.lopetaLataaminen()
      this._errorHandler.handleError(error)
    })
  }

}
