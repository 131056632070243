import { Component, Input } from '@angular/core'
import { TrackingService } from '../service/logs/tracking.service'
import { firstValueFrom } from 'rxjs'
import { AsiakasService } from '../service/asiakas/asiakas.service'
import { KirjautunutKayttajaService } from '../service/kirjautunut-kayttaja.service'

@Component({
  selector: 'app-secondary-button',
  templateUrl: './secondary-button.component.html'
})
export class SecondaryButtonComponent {

  @Input() actionId: string
  @Input() actionContext: any[]
  @Input() additionalStyles: string = ''
  @Input() matIcon: string = ''

  constructor(
    private _trackingService: TrackingService,
    private _asiakasService: AsiakasService,
    private _kirjautunutKayttajaService: KirjautunutKayttajaService) {
  }

  async trackButton() {
    let context = ''
    const asiakas = await firstValueFrom(this._asiakasService.nykyinenAsiakasObservable)
    const kirjanpitajanTiedot = await firstValueFrom(this._kirjautunutKayttajaService.kirjanpitajanTiedotObservable)
    if (this.actionContext && this.actionContext.length > 0) {
      for (const cntxt of this.actionContext) {
        context += JSON.stringify(cntxt)
      }
    }
    this._trackingService.logAction(`${this.actionId} ${context}`, asiakas?.avain, kirjanpitajanTiedot?.uid)
  }
}
