<div class="laskelmat-container">

  <form class="laskelmat-controls" [formGroup]="laskelmatDateAndSearchForm">

    <div class="filters-container" [style.justify-content]="valittuValilehti === 'paakirja' ? 'space-between' : 'flex-end'">

      <div *ngIf="kirjanpitajaOnLuottoObservable | async" class="form-boxes" style="width: 155px; margin-right: 10px;">
        <mat-select formControlName="source">
          <mat-option value="raportointikirjaukset">Raportointikirjaukset</mat-option>
          <mat-option value="nippudokumentit">Raportointiniput</mat-option>
        </mat-select>
      </div>

      <ng-container *ngIf="valittuValilehti === 'paakirja'">
        <div class="form-boxes">
          <div class="rounded-input">
            <span class="icon ion-ios-search"></span>
            <input style="border: 0; outline: none; width: 100%;" type="text" formControlName="searchString" placeholder="Etsi kirjausta..." data-lpignore="true" />
          </div>
        </div>

        <div class="form-boxes" style="width: 140px;">
          <div class="rounded-input">
            <input #tilistaInput style="border: 0; outline: none; width: 100%;" decimalNumberField [numberOfDecimals]="0" [name]="name" formControlName="tilista" placeholder="0001" data-lpignore="true" minlength="4" (keydown.enter)="enterPressedTilista($event)" />
          </div>
          <mat-error *ngIf="tilista?.errors?.minlength && (tilista?.dirty || tilista?.touched)">Kirjoita 4 numeroa.</mat-error>
          <mat-error *ngIf="tilista?.errors?.pattern && (tilista?.dirty || tilista?.touched)">Syötä vain numeroita.</mat-error>
          <div style="margin: 0 5px;">-</div>
          <div class="rounded-input">
            <input #tiliinInput style="border: 0; outline: none; width: 100%;" decimalNumberField [numberOfDecimals]="0" [name]="name" formControlName="tiliin" placeholder="9999" data-lpignore="true" minlength="4" (keydown.enter)="enterPressedTiliin($event)" />
          </div>
          <mat-error *ngIf="tiliin?.errors?.minlength && (tiliin?.dirty || tiliin?.touched)">Kirjoita 4 numeroa.</mat-error>
          <mat-error *ngIf="tiliin?.errors?.pattern && (tiliin?.dirty || tiliin?.touched)">Syötä vain numeroita.</mat-error>
        </div>
      </ng-container>

      <div class="form-boxes" style="width: 228px;">
        <div class="rounded-input" style="padding: 0 5px 0 8px;">
          <input #alkaaInput type="text" [name]="name" [matDatepicker]="alkaaPicker" formControlName="alkaa" matInput data-lpignore="true" required style="width: 70px;" />
          <button mat-icon-button (click)="alkaaPicker.open()" style="width: 20px; height: 20px; line-height: 20px;">
            <mat-icon style="width: 20px; height: 20px; line-height: 20px; font-size: 16px; top: 0; left: 0; right: 0; bottom: 0; position: absolute;">calendar_today</mat-icon>
          </button>
          <!-- <mat-datepicker-toggle matSuffix [for]="alkaaPicker"></mat-datepicker-toggle> -->
          <mat-datepicker #alkaaPicker startView="year"></mat-datepicker>
        </div>
        <div style="margin: 0 5px;">-</div>
        <div class="rounded-input" style="padding: 0 5px 0 8px;">
          <input type="text" [name]="name" [matDatepicker]="loppuuPicker" formControlName="loppuu" matInput data-lpignore="true" required style="width: 70px;" />
          <button mat-icon-button (click)="loppuuPicker.open()" style="width: 20px; height: 20px; line-height: 20px;">
            <mat-icon style="width: 20px; height: 20px; line-height: 20px; font-size: 16px; top: 0; left: 0; right: 0; bottom: 0; position: absolute;">calendar_today</mat-icon>
          </button>
          <!-- <mat-datepicker-toggle matSuffix [for]="loppuuPicker"></mat-datepicker-toggle> -->
          <mat-datepicker #loppuuPicker startView="year"></mat-datepicker>
        </div>
        <div *ngIf="loppuu.errors?.required" class="error-text">
          Päättymispäivämäärä on an&shy;net&shy;ta&shy;va.
        </div>
        <div *ngIf="alkaa.errors?.required" class="error-text">
          Alkamispäivämäärä on an&shy;net&shy;ta&shy;va.
        </div>
        <div *ngIf="loppuu.errors?.loppuEnnenAlkua" class="error-text">
          Loppumispäivämäärä on ennen al&shy;ka&shy;mis&shy;päi&shy;vä&shy;mää&shy;rää.
        </div>
        <div *ngIf="loppuu.errors?.minDate || alkaa.errors?.minDate" class="error-text">
          Päivämäärän on oltava vuoden 2010 jäl&shy;keen.
        </div>
      </div>


    </div>

    <div style="display: flex; flex-flow: row; justify-content: space-between; margin: 0 auto; max-width: 1000px;">

      <div class="date-chip-group">
        <button *ngFor="let tilikausi of naytettavatTilikaudetObservable | async" class="date-chip" [title]="tilikausi?.labelHover || null" [class.date-chip-selected]="naytettavaTilikausi?.labelHover === tilikausi.labelHover" type="button" mat-button (click)="valitseTilikausi(tilikausi)">
          {{ tilikausi?.label }}
        </button>
      </div>

      <ng-container *ngIf="naytettavaTilikausi">
        <div class="date-chip-group">
          <button class="date-chip" [title]="qt?.quarter || null" [class.date-chip-selected]="dateBtnsSelectedQuarter === qt.quarter" *ngFor="let qt of naytettavaTilikausi.quarters" type="button" mat-button (click)="valitseKvartaali(qt)">
            {{ qt?.label }}
          </button>
        </div>
        <div class="date-chip-group">
          <button class="date-chip" [title]="mm?.month || null" [class.date-chip-selected]="dateBtnsSelectedMonth === mm.month" *ngFor="let mm of naytettavaTilikausi.months" type="button" mat-button (click)="valitseKuukausi(mm)">
            {{ mm?.label }}
          </button>
        </div>
      </ng-container>

    </div>

    <div style="text-align: center; height: 35px;" *ngIf="naytaProjektitObservable | async">
      <app-kirjanpito-projektit-dropdown *ngIf="valittuValilehti !== 'viralliset'" [projektitFromParentObservable]="kaikkiKirjanpitoprojektitObservable" (projektiValittu)="filterByProject($event)"></app-kirjanpito-projektit-dropdown>
    </div>

    <!-- <button mat-button type="button" (click)="paivitaArvot()">Päivitä arvot</button> -->

  </form>

  <div style="display: flex; flex-flow: row wrap; justify-content: center;">

    <div class="report-btn-group">
      <button class="report-btn padded" mat-button [class.report-btn-selected]="valittuValilehti === 'tase-ja-tulos'" (click)="setValittuValilehti('tase-ja-tulos')">
        <div>Tase ja Tulos</div>
      </button>
      <!-- <button class="report-btn" mat-button [class.report-btn-selected]="valittuValilehti === 'tase'" (click)="setvalittuValilehti('tase')">
          <div>Tase</div>
        </button>
        <button class="report-btn" mat-button [class.report-btn-selected]="valittuValilehti === 'tulos'" (click)="setvalittuValilehti('tulos')">
          <div>Tulos</div>
        </button> -->
      <button class="report-btn padded" mat-button [class.report-btn-selected]="valittuValilehti === 'viralliset'" (click)="setValittuValilehti('viralliset')">
        <div>Viralliset</div>
      </button>
      <!-- <button class="report-btn" mat-button [class.report-btn-selected]="valittuValilehti === 'tulos-virallinen'" (click)="setvalittuValilehti('tulos-virallinen')">
          <div>Virallinen tulos</div>
        </button>
        <button class="report-btn" mat-button [class.report-btn-selected]="valittuValilehti === 'tase-virallinen'" (click)="setvalittuValilehti('tase-virallinen')">
          <div>Virallinen tase</div>
        </button> -->
      <button class="report-btn padded" mat-button [class.report-btn-selected]="valittuValilehti === 'paakirja'" (click)="setValittuValilehti('paakirja')">
        <div>Pääkirja</div>
      </button>
      <button class="report-btn" mat-button [class.report-btn-selected]="valittuValilehti === 'bruttolaskelma'" (click)="setValittuValilehti('bruttolaskelma')">
        <div>Bruttolaskelma</div>
      </button>
      <button class="report-btn padded" mat-button [class.report-btn-selected]="valittuValilehti === 'alv-laskelma'" (click)="setValittuValilehti('alv-laskelma')">
        <div>ALV-laskelma</div>
      </button>
      <button *ngIf="naytaOss" class="report-btn padded" mat-button [class.report-btn-selected]="valittuValilehti === 'oss-myynnit'" (click)="setValittuValilehti('oss-myynnit')">
        <div>Erityisjärjestelmämyynnit</div>
      </button>
    </div>

    <div class="pdf-btn-row">
      <button mat-button (click)="openPdfSelect()" *ngIf="valittuValilehti !== 'oss-myynnit'">
        <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512" style="height: 20px;  vertical-align: middle;">
          <path d="M320 336h76c55 0 100-21.21 100-75.6s-53-73.47-96-75.6C391.11 99.74 329 48 256 48c-69 0-113.44 45.79-128 91.2-60 5.7-112 35.88-112 98.4S70 336 136 336h56M192 400.1l64 63.9 64-63.9M256 224v224.03" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" />
        </svg> Lataa PDF
      </button>
      <div class="hidden-select">
        <mat-select #pdfSelect *ngIf="valittuValilehti !== 'oss-myynnit'" panelClass="no-scroll">
          <mat-option *ngFor="let pdf of pdfList" (click)="downloadPdf(pdf.type, pdf.kieli)">
            {{ pdf.tag }}
          </mat-option>
        </mat-select>
      </div>

      <button mat-button *ngIf="valittuValilehti === 'tase-ja-tulos' || valittuValilehti === 'paakirja'" (click)="openCsvSelect()">
        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#000000" style="height: 20px;  vertical-align: middle;">
          <path d="M200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h560q33 0 56.5 23.5T840-760v560q0 33-23.5 56.5T760-120H200Zm240-240H200v160h240v-160Zm80 0v160h240v-160H520Zm-80-80v-160H200v160h240Zm80 0h240v-160H520v160ZM200-680h560v-80H200v80Z" />
        </svg>
        Lataa Excel
      </button>
      <div class="hidden-select">
        <mat-select #csvSelect *ngIf="valittuValilehti === 'tase-ja-tulos' || valittuValilehti === 'paakirja'" panelClass="no-scroll">
          <mat-option *ngFor="let csv of csvList" (click)="downloadCsv(csv.type, csv.kieli)">
            {{ csv.tag }}
          </mat-option>
        </mat-select>
      </div>
    </div>

    <div [style.order]="taseOrderObservable | async" *ngIf="valittuValilehti === 'tase-ja-tulos'" app-kirjanpito-taselaskelma [hakuvaihtoehdotObservable]="hakuvaihtoehdotObservable" [tilitMapObservable]="tilitMapObservable" [paivitaArvotHiljaisestiSubject]="paivitaArvotHiljaisestiSubject" [taseEnsin]="(taseOrderObservable | async) === '1'" (tulosEnsinClicked)="setTulosTaseEnsin('tulos')"
      class="raportti-container" (kirjaustaKlikattiin)="emitKirjausData($event)"></div>
    <div [style.order]="tulosOrderObservable | async" *ngIf="valittuValilehti === 'tase-ja-tulos'" app-kirjanpito-tuloslaskelma [hakuvaihtoehdotObservable]="hakuvaihtoehdotObservable" [tilitMapObservable]="tilitMapObservable" [paivitaArvotHiljaisestiSubject]="paivitaArvotHiljaisestiSubject" class="raportti-container" (kirjaustaKlikattiin)="emitKirjausData($event)"
      [tulosEnsin]="(tulosOrderObservable | async) === '1'" (taseEnsinClicked)="setTulosTaseEnsin('tase')"></div>
    <div [style.order]=" taseOrderObservable | async" *ngIf="valittuValilehti === 'viralliset'" app-kirjanpito-taselaskelma-virallinen [hakuvaihtoehdotObservable]="hakuvaihtoehdotObservable" [tilitMapObservable]="tilitMapObservable" [paivitaArvotHiljaisestiSubject]="paivitaArvotHiljaisestiSubject" class="raportti-container viralliset" [taseEnsin]="(taseOrderObservable | async) === '1'"
      (tulosEnsinClicked)="setTulosTaseEnsin('tulos')"></div>
    <div [style.order]="tulosOrderObservable | async" *ngIf="valittuValilehti === 'viralliset'" app-kirjanpito-tuloslaskelma-virallinen [hakuvaihtoehdotObservable]="hakuvaihtoehdotObservable" [tilitMapObservable]="tilitMapObservable" [paivitaArvotHiljaisestiSubject]="paivitaArvotHiljaisestiSubject" class="raportti-container viralliset" [tulosEnsin]="(tulosOrderObservable | async) === '1'"
      (taseEnsinClicked)="setTulosTaseEnsin('tase')"></div>
    <div *ngIf="valittuValilehti === 'paakirja'" app-kirjanpito-paakirja [hakuvaihtoehdotObservable]="hakuvaihtoehdotObservable" [tilitMapObservable]="tilitMapObservable" [paivitaArvotHiljaisestiSubject]="paivitaArvotHiljaisestiSubject" (kirjaustaKlikattiin)="emitKirjausData($event)" class="raportti-container"></div>
    <div *ngIf="valittuValilehti === 'bruttolaskelma'" app-kirjanpito-tuloslaskelma-brutto [hakuvaihtoehdotObservable]="hakuvaihtoehdotObservable" [tilitMapObservable]="tilitMapObservable" [paivitaArvotHiljaisestiSubject]="paivitaArvotHiljaisestiSubject" (kirjaustaKlikattiin)="emitKirjausData($event)" class="raportti-container"></div>
    <div *ngIf="valittuValilehti === 'alv-laskelma'" app-kirjanpito-alv-laskelma [hakuvaihtoehdotObservable]="hakuvaihtoehdotObservable" [tilitMapObservable]="tilitMapObservable" [paivitaArvotHiljaisestiSubject]="paivitaArvotHiljaisestiSubject" (kirjaustaKlikattiin)="emitKirjausData($event)" class="raportti-container"></div>
    <div *ngIf="valittuValilehti === 'oss-myynnit'" app-kirjanpito-oss [hakuvaihtoehdotObservable]="hakuvaihtoehdotObservable" [tilitMapObservable]="tilitMapObservable" [paivitaArvotHiljaisestiSubject]="paivitaArvotHiljaisestiSubject" (kirjaustaKlikattiin)="emitKirjausData($event)" class="raportti-container"></div>
  </div>


</div>