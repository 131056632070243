import { Component, OnInit, OnDestroy, AfterContentChecked, ErrorHandler, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { Validators, AbstractControl, ValidationErrors, ValidatorFn, FormGroup, FormControl, FormArray } from '@angular/forms'

import { MatDatepicker } from '@angular/material/datepicker'
import { MatSnackBar } from '@angular/material/snack-bar'

import { FormValidationService } from '../../_jaettu-angular/service/form-validation.service'
import { LadataanService } from '../../_jaettu-angular/service/ladataan.service'
import { VasenValikkoService } from '../../_jaettu-angular/service/vasen-valikko.service'
import { TimestampService } from 'app/_jaettu-angular/service/timestamp-service'

import { Asiakas, AsiakkaanKuukausihinta } from '../../_jaettu-lemonator/model/asiakas'
import { DateService } from '../../_shared-core/service/date.service'
import { ViitenumeroService } from '../../_shared-core/service/viitenumero.service'

import { UUDEN_LASKUN_AVAIN } from '../../_jaettu/model/lasku'

import { AsiakasService } from '../../_angular/service/asiakas/asiakas.service'
import { AsiakasKopioija } from '../../_angular/service/asiakas/asiakas.kopioija'

import { Subject, Observable, of } from 'rxjs'
import { takeUntil, map, startWith, switchMap } from 'rxjs/operators'

import { KirjanpitajanRooli } from 'app/_jaettu/lemonator/model/kirjanpitaja'
import { KirjautunutKayttajaService } from 'app/_angular/service/kirjautunut-kayttaja.service'
import { LaskuSharedService } from 'app/_jaettu/service/lasku/lasku-shared.service'
import { CurrencyService } from 'app/_shared-core/service/currency.service'
import { SahkoisenLaskunValittajaService, Verkkolaskuoperaattori } from 'app/_jaettu/service/lasku/sahkoisen-laskun-valittaja.service'
import { StringService } from 'app/_shared-core/service/string.service'
import { FirebaseLemonaid, FirebaseLemonator } from 'app/_angular/service/firebase-lemonator.service'
import { LaskuLataaReskontratilannePyynto, LaskuLataaReskontratilanneVastaus } from '../../_jaettu-lemonator/model/lasku'
import { MatInput } from '@angular/material/input'
import { LocalDate } from 'app/_shared-core/model/common'
import { ApixReceivedInvoiceConfig } from 'app/_jaettu/model/apix'
import { FileSaverService } from 'app/_jaettu-angular/service/file-saver'
import { DebugService } from 'app/_angular/service/debug.service'

interface MainFormGroup {
  hinnanPerusteet: FormControl<string>
  hinnat: FormArray<FormGroup<HintaFormGroup>>
  lisaavastaanottaja: FormControl<string>
  viitenumero: FormControl<string>
  spostiVaiEmail: FormControl<Laskutustyyppi>
  sahkoinenLaskuValittaja: FormControl<string>
  sahkoinenLaskuOsoite: FormControl<string>
  // laskunKieli: FormControl<TuettuKieli>
  estaAlvLaskut: FormControl<boolean>
}

interface HintaFormGroup {
  voimassaAlkaen: FormControl<Date>
  hinta: FormControl<number>
  syy: FormControl<string>
}

type Laskutustyyppi = 'spostilasku' | 'verkkolasku'
@Component({
  templateUrl: './laskutus.component.html',
  styleUrls: ['./laskutus.component.css']
})
export class AsiakasAsetuksetLaskutusComponent implements OnInit, OnDestroy, AfterContentChecked {

  @ViewChildren('hintaInputti', { read: MatInput }) hintaKomponentit: QueryList<MatInput>

  private ngUnsubscribe: Subject<void> = new Subject<void>()

  naytaSuperObservable: Observable<boolean>
  verkkolaskuoperaattorit: Observable<Verkkolaskuoperaattori[]>
  apixForwardConfigObservable: Observable<ApixReceivedInvoiceConfig>

  form: FormGroup<MainFormGroup>
  reskontratilanneForm: FormGroup<{ reskontratilannePaiva: FormControl<Date> }>
  arvopaivaFormControl: FormControl<Date>
  minMonth = new Date()
  asiakas: Asiakas = null
  namename = 'asdf ' + Math.random()
  naytaSahkoinenLaskutus = false
  asiakkaanOsoitetiedotPuuttuvat = true
  isLastPriceZero = false

  apixConfigUriObservable: Observable<string>
  apixConfigEncodedUriObservable: Observable<string>
  kirjanpitajaOnDevaajaObservable: Observable<boolean>

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _route: ActivatedRoute,
    private _router: Router,
    private _asiakasService: AsiakasService,
    private _dateService: DateService,
    private _stringService: StringService,
    private _asiakasKopioija: AsiakasKopioija,
    private _validationService: FormValidationService,
    private _errorHandler: ErrorHandler,
    private _ladataanService: LadataanService,
    private _vasenValikkoService: VasenValikkoService,
    private _snackbar: MatSnackBar,
    private _viitenumeroService: ViitenumeroService,
    private _timestampService: TimestampService,
    private _firebase: FirebaseLemonator,
    private _lemonaidFirebase: FirebaseLemonaid,
    private _kirjautunutKayttajaService: KirjautunutKayttajaService,
    private _sahkoisenLaskunValittajaService: SahkoisenLaskunValittajaService,
    private _fileSaverService: FileSaverService,
    private _debugService: DebugService
  ) {
    this.asiakas = this._asiakasKopioija.annaUusiAsiakas()
    this.naytaSuperObservable = this._kirjautunutKayttajaService.kirjanpitajanTiedotObservable.pipe(
      map(kirjanpitajanTiedot => {
        if (kirjanpitajanTiedot) {
          return kirjanpitajanTiedot.rooli === KirjanpitajanRooli.SUPER
        }
        return false
      })
    )

    // Debug thingies
    this.kirjanpitajaOnDevaajaObservable = this._kirjautunutKayttajaService.kirjanpitajaOnDevaajaObservable
    this.apixConfigUriObservable = this._asiakasService.nykyinenAsiakasAvainObservable.pipe(
      map(avainTiedot => {
        if (avainTiedot?.avain) {
          return 'customers/' + avainTiedot.avain + '/apix-received-invoice-config/' + avainTiedot.avain
        }
        return ''
      })
    )
    this.apixConfigEncodedUriObservable = this.apixConfigUriObservable.pipe(
      map(uri => {
        return this._debugService.createFirestoreLinkLemonaid(uri)
      })
    )

  }

  ngAfterContentChecked() {
    // this.fieldErrors = this.validationService.updateValidationStatus('asiakas', this.form)
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next()
    this.ngUnsubscribe.complete()
  }

  private minimiKuukausValidator = (control: FormControl<Date>) => {
    if (control.value) {
      const minDate = new Date()
      if (this._dateService.kuukausiaValissa(control.value, minDate) <= 0) {
        return { min: true }
      }
    }
    return null
  }

  validateEmail: ValidatorFn = (ctrl: AbstractControl): ValidationErrors | null => {
    if (ctrl.value == null || ctrl.value === undefined || ctrl.value.trim() === '') {
      return null
    }
    return Validators.email(ctrl)
  }

  private validateViitenumero: ValidatorFn = (ctrl: AbstractControl): ValidationErrors | null => {
    if (ctrl.value == null || ctrl.value === undefined || ctrl.value.trim() === '') {
      return null
    }
    if (!this._viitenumeroService.onkoViitenumeroValidi(ctrl.value)) {
      return { viitenumero: true }
    }
    return null
  }

  ngOnInit() {

    const lastOfPrevMonth = this._dateService.kuukaudenViimeinen(this._dateService.lisaaKuukausia(new Date(), -1))
    const reskontratilannePaivaFormControl = new FormControl<Date>(lastOfPrevMonth)
    this.reskontratilanneForm = new FormGroup({
      reskontratilannePaiva: reskontratilannePaivaFormControl
    })

    const d = new Date()
    d.setDate(1)
    this.minMonth = this._dateService.lisaaKuukausia(d, 1)

    const hinnatFormControl = new FormArray<FormGroup<HintaFormGroup>>([], this.vahintaanYksihintaValidator)
    const lisaavastaanottajaFormControl = new FormControl<string>('', [this.validateEmail])
    const viitenumeroFormControl = new FormControl<string>(null, [this.validateViitenumero])
    const spostiVaiEmailFormControl = new FormControl<Laskutustyyppi>(null, [Validators.required])
    const sahkoinenLaskuValittajaFormControl = new FormControl<string>('', [])
    const sahkoinenLaskuOsoiteFormControl = new FormControl<string>('', [])
    const hinnanPerusteetFormControl = new FormControl<string>('', [])

    // const laskunKieliFormControl = new FormControl<TuettuKieli>(null, Validators.required)
    const estaAlvLaskutFormControl = new FormControl<boolean>(null, Validators.required)

    this.form = new FormGroup({
      hinnanPerusteet: hinnanPerusteetFormControl,
      hinnat: hinnatFormControl,
      lisaavastaanottaja: lisaavastaanottajaFormControl,
      viitenumero: viitenumeroFormControl,
      spostiVaiEmail: spostiVaiEmailFormControl,
      sahkoinenLaskuValittaja: sahkoinenLaskuValittajaFormControl,
      sahkoinenLaskuOsoite: sahkoinenLaskuOsoiteFormControl,
      // laskunKieli: laskunKieliFormControl,
      estaAlvLaskut: estaAlvLaskutFormControl
    })

    hinnatFormControl.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(value => {
      const formArray = this.annaHinnatFormArray()
      if (
        formArray?.length > 1 &&
        formArray.at(formArray.length - 1)?.value?.hinta === 0
      ) {
        this.isLastPriceZero = true
      } else {
        this.isLastPriceZero = false
      }
    })

    estaAlvLaskutFormControl.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(value => {
      if (value) {
        this.asiakas.estaAlvVerkkolaskujenLahetys = 1
      } else {
        delete this.asiakas.estaAlvVerkkolaskujenLahetys
      }
    })

    viitenumeroFormControl.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(value => { this.asiakas.viitenumero = this._validationService.processValue(value) })

    hinnanPerusteetFormControl.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(value => { this.asiakas.hinnanPerusteet = this._validationService.processValue(value) })

    spostiVaiEmailFormControl.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe((value: Laskutustyyppi) => {
      if (value === 'verkkolasku') {
        this.naytaSahkoinenLaskutus = true
        this.sahkoinenLaskuOsoite.setValidators([Validators.required, this._annaSahkoisenLaskunOsoitteenValidointiFunktio()])
        this.sahkoinenLaskuValittaja.setValidators([Validators.required])
        this.sahkoinenLaskuValittaja.setValue(null)
        this.sahkoinenLaskuOsoite.setValue(null)
      } else {
        this.naytaSahkoinenLaskutus = false
        this.sahkoinenLaskuOsoite.setValidators(null)
        this.sahkoinenLaskuValittaja.setValidators(null)
        this.sahkoinenLaskuValittaja.setValue(null)
        this.sahkoinenLaskuOsoite.setValue(null)
      }
    })

    this.sahkoinenLaskuValittaja.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(value => {
      if (!this.asiakas.sahkoinenLaskutusosoite) { this.asiakas.sahkoinenLaskutusosoite = { sahkoinenOsoite: null, sahkoinenValittaja: null } }
      if (value && value.tunnus) {
        this.asiakas.sahkoinenLaskutusosoite.sahkoinenValittaja = this._validationService.processValue(value.tunnus)
      } else {
        this.asiakas.sahkoinenLaskutusosoite.sahkoinenValittaja = this._validationService.processValue(value)
      }
    })
    this.sahkoinenLaskuOsoite.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(value => {
      if (!this.asiakas.sahkoinenLaskutusosoite) { this.asiakas.sahkoinenLaskutusosoite = { sahkoinenOsoite: null, sahkoinenValittaja: null } }
      this.asiakas.sahkoinenLaskutusosoite.sahkoinenOsoite = this._validationService.processValue(value)
    })

    // this.laskunKieli.valueChanges.pipe(
    //   takeUntil(this.ngUnsubscribe)
    // ).subscribe(value => {
    //   if (value === 'fi') {
    //     this.asiakas.laskunKieli = 'fi'
    //   } else if (value === 'en') {
    //     this.asiakas.laskunKieli = 'en'
    //   }
    // })

    this.verkkolaskuoperaattorit = this.sahkoinenLaskuValittaja.valueChanges.pipe(
      startWith(''),
      map(value => this._filterVerkkolaskuoperaattori(value))
    )

    this._asiakasService.nykyinenAsiakasObservable.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(asiakas => {
      if (asiakas) {
        this.asiakas = this._asiakasKopioija.kopioiAsiakas(asiakas)
        this.alustaLomakkeenTiedot(this.asiakas)
      }
    })

    this.apixForwardConfigObservable = this._asiakasService.nykyinenAsiakasAvainObservable.pipe(
      switchMap(asiakas => {
        if (!asiakas) {
          return of<ApixReceivedInvoiceConfig>(null)
        }
        const uri = 'customers/' + asiakas.avain + '/apix-received-invoice-config/' + asiakas.avain
        return this._lemonaidFirebase.firestoreDoc<ApixReceivedInvoiceConfig>(uri).listen()
      })
    )

  }

  // get laskunKieli() {
  //   return this.form.get('laskunKieli')
  // }

  get lisaavastaanottaja() {
    return this.get('lisaavastaanottaja')
  }

  get viitenumero() {
    return this.get('viitenumero')
  }

  get hinnanPerusteet() {
    return this.get('hinnanPerusteet')
  }

  private get(name: string): AbstractControl {
    if (this.form) {
      return this.form.get(name)
    }
    return null
  }

  private _filterVerkkolaskuoperaattori(value: string | Verkkolaskuoperaattori): Verkkolaskuoperaattori[] {
    if (value === '' || !value) {
      return []
    }
    let filterValue = ''
    if (value instanceof String || typeof value === 'string') {
      filterValue = value.toLowerCase()
    } else {
      filterValue = value && value.nimi ? value.nimi.toLowerCase() : ''
    }
    return this._sahkoisenLaskunValittajaService.annaKaikkiValittajat().filter(operaattori => operaattori.nimi.toLowerCase().includes(filterValue) || operaattori.tunnus.toLowerCase().includes(filterValue))
  }

  private _annaSahkoisenLaskunOsoitteenValidointiFunktio(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value) {
        const ctrlValue = this._stringService.removeAllWhiteSpaces(control.value)
        const valittajat = this._sahkoisenLaskunValittajaService.annaKaikkiValittajat()
        for (const valittaja of valittajat) {
          if (this._stringService.removeAllWhiteSpaces(valittaja.tunnus) === ctrlValue) {
            return { 'valittaja': true }
          }
        }
      }
      return null
    }
  }

  valittajaDisplayFn(operaattori?: Verkkolaskuoperaattori): string | undefined {
    if (operaattori && operaattori.nimi) {
      return operaattori.nimi + ' (' + operaattori.tunnus + ')'
    }
    if (operaattori instanceof String || typeof operaattori === 'string') {
      return operaattori as any as string
    }
    return undefined
  }

  lisaaLaskunVastaanottaja() {
    const field = this.form.get('lisaavastaanottaja')
    field.updateValueAndValidity()
    this.lisaaEmail(field)
  }

  private lisaaEmail(field: AbstractControl<string, string>) {
    // console.log('raaka', field.value)
    if ((field.value || '').trim()) {
      let arvo = field.value.trim()
      arvo = arvo.replace(/,/g, '').trim()
      // console.log('kasitelty', arvo)
      if (!new FormControl<string>(arvo, [Validators.email]).errors) {
        if (!this.asiakas.laskunVastaanottajat) {
          this.asiakas.laskunVastaanottajat = []
        }
        this.asiakas.laskunVastaanottajat.push(arvo)
        field.setValue('')
      }
    }
  }

  poistaLaskunVastaanottaja(vastaanottaja: string): void {
    const index = this.asiakas.laskunVastaanottajat.indexOf(vastaanottaja)
    if (index >= 0) {
      this.asiakas.laskunVastaanottajat.splice(index, 1)
    }
  }

  annaHinnatControllit(): FormGroup<HintaFormGroup>[] {
    return this.annaHinnatFormArray().controls as FormGroup<HintaFormGroup>[]
  }

  annaHinnatFormArray(): FormArray<FormGroup<HintaFormGroup>> {
    return this.form.get('hinnat') as FormArray<FormGroup<HintaFormGroup>>
  }

  get sahkoinenLaskuOsoite() {
    return this.get('sahkoinenLaskuOsoite')
  }
  get sahkoinenLaskuValittaja() {
    return this.get('sahkoinenLaskuValittaja')
  }

  private alustaLomakkeenTiedot(asiakas: Asiakas) {

    const hinnatArray = this.annaHinnatFormArray()
    hinnatArray.clear()

    const valittaja = asiakas?.sahkoinenLaskutusosoite?.sahkoinenValittaja || null
    const osoite = asiakas?.sahkoinenLaskutusosoite?.sahkoinenOsoite || null
    const valittajaObject = this._sahkoisenLaskunValittajaService.annaValittaja(valittaja)

    // This must be set first as it nullifies the valittaja && osoite
    const tyyppi: Laskutustyyppi = valittaja ? 'verkkolasku' : 'spostilasku'
    this.form.get('spostiVaiEmail').setValue(tyyppi)

    this.sahkoinenLaskuValittaja.setValue(valittajaObject || valittaja || null)
    this.sahkoinenLaskuOsoite.setValue(osoite || null)

    this.asiakkaanOsoitetiedotPuuttuvat = !asiakas?.katuosoite || !asiakas?.postitmp || !asiakas?.postinro

    if (asiakas) {
      this.form.get('hinnanPerusteet').setValue(asiakas.hinnanPerusteet)
      // this.form.get('laskunKieli').setValue(asiakas.laskunKieli)
      this.form.get('viitenumero').setValue(this._viitenumeroService.muotoileViitenumero(asiakas.viitenumero))
      if (asiakas.hinnat) {
        for (const hinta of asiakas.hinnat) {
          const hinnatGroup = this.annaFormGroupHinnalle(hinta, false, true)
          hinnatArray.push(hinnatGroup)
        }
      }
      this.form.get('estaAlvLaskut').setValue(!!asiakas.estaAlvVerkkolaskujenLahetys)
    } else {
      this.form.get('viitenumero').setValue('')
      this.form.get('hinnanPerusteet').setValue('')
      this.form.get('estaAlvLaskut').setValue(false)
    }

  }

  private edellinenKuukausiValidator = (control: FormControl<Date>) => {
    if (control.value) {
      const formArray = this.annaHinnatFormArray()
      const index = formArray.controls.indexOf(control.parent as FormGroup<HintaFormGroup>)
      if (index > 0) {
        const edellinen = formArray.controls[index - 1]
        if (edellinen) {
          const edellinenControl = edellinen.get('voimassaAlkaen')
          if (edellinenControl && edellinenControl.value) {
            // console.log('Validoidaan nykyinen: ', control.value.toISOString())
            // console.log('Validoidaan edellinen:', edellinenControl.value.toISOString())
            // console.log('Kuukausia välissä:', this.dateService.kuukausiaValissa(control.value, edellinenControl.value))
            if (this._dateService.kuukausiaValissa(control.value, edellinenControl.value) < 1) {
              return { edellinen: true }
            }
          }
        }
      }
    }
    return null
  }

  private vahintaanYksihintaValidator = (control: FormArray<any>) => {
    if (!control.controls || control.controls.length < 1) {
      return { min: true }
    }
    return null
  }

  private annaFormGroupHinnalle(hinta: AsiakkaanKuukausihinta, focus: boolean, lukitseVanhat: boolean): FormGroup {

    // const d = new Date()
    // d.setDate(2)
    // console.log('PÄIVÄ', d.toISOString(), this.dateService.lisaaKuukausia(d, 1).toISOString())

    const paiva = this._dateService.localDateToDate(hinta.voimassaAlkaen)
    // const lukossa = false
    const lukossa = this._dateService.kuukausiaValissa(paiva, new Date()) < 1 && lukitseVanhat
    const muutoksenSyy = lukossa ? hinta.muutoksenSyy || ' ' : hinta.muutoksenSyy || ''
    const voimassaAlkaenFormControl = new FormControl<Date>({ value: paiva, disabled: lukossa }, [Validators.required, this.edellinenKuukausiValidator, this.minimiKuukausValidator])
    const hintaFormControl = new FormControl<number>({ value: hinta.hinta, disabled: lukossa }, [Validators.required, Validators.min(0.00), Validators.max(2000)])
    const syyFormControl = new FormControl<string>({ value: muutoksenSyy || '', disabled: lukossa }, [Validators.required])
    const hintaGroup = new FormGroup<HintaFormGroup>({
      'voimassaAlkaen': voimassaAlkaenFormControl,
      'hinta': hintaFormControl,
      'syy': syyFormControl
    })

    voimassaAlkaenFormControl.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(value => {
      hinta.voimassaAlkaen = this._dateService.dateToLocalDate(value)
    })
    hintaFormControl.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(value => {
      hinta.hinta = value
    })
    syyFormControl.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(value => {
      hinta.muutoksenSyy = value
    })

    if (focus) {
      setTimeout(() => {
        this.hintaKomponentit.last.focus()
        this._changeDetectorRef.markForCheck()
      }, 10)
      this._changeDetectorRef.markForCheck()
    }

    return hintaGroup

  }

  annaMinimiPaivamaara(control: AbstractControl): Date {
    if (control.enabled) {
      const d = new Date()
      d.setDate(1)
      d.setHours(0)
      d.setMinutes(0)
      d.setSeconds(0)
      d.setMilliseconds(1)
      return this._dateService.lisaaKuukausia(d, 1)
    }
    return new Date(1980, 1, 1)
  }

  private annaUusiHinta(): AsiakkaanKuukausihinta {
    const d = new Date()
    d.setDate(2)
    return {
      hinta: null,
      voimassaAlkaen: this._dateService.dateToLocalDate(this._dateService.lisaaKuukausia(d, 1)),
      muutoksenSyy: null,
      muutosPvm: this._timestampService.now()
    }
  }

  poistaHintarivi(group: FormGroup<HintaFormGroup>) {
    const formArray = this.annaHinnatFormArray()
    const index = formArray.controls.indexOf(group)
    if (index > -1) {
      formArray.removeAt(index)
      this.asiakas.hinnat.splice(index, 1)
    }
  }

  lisaaHintarivi() {
    const formArray = this.annaHinnatFormArray()

    const hinta = this.annaUusiHinta()
    const formGroup = this.annaFormGroupHinnalle(hinta, true, false)

    if (!this.asiakas.hinnat) {
      this.asiakas.hinnat = []
    }
    this.asiakas.hinnat.push(hinta)
    formArray.push(formGroup)
  }

  chosenMonthHandler(normalizedMonth: Date, formGroup: FormGroup<HintaFormGroup>, datepicker: MatDatepicker<Date>) {
    const control = formGroup.get('voimassaAlkaen')
    const minDate = this.annaMinimiPaivamaara(control)
    if (this._dateService.kuukausiaValissa(normalizedMonth, minDate) > -1) {
      control.setValue(normalizedMonth)
      control.markAsTouched()
    }
    datepicker.close()
  }

  save() {

    if (
      !this.naytaSahkoinenLaskutus && (
        !this.asiakas ||
        !this.asiakas.laskunVastaanottajat ||
        this.asiakas.laskunVastaanottajat.length < 1
      )
    ) {
      this.form.get('lisaavastaanottaja').setErrors({ 'min': true })
    }

    if (this.naytaSahkoinenLaskutus && this.asiakkaanOsoitetiedotPuuttuvat) {
      return
    }

    if (!this.form.valid) {
      this._validationService.merkitseKokoLomakeKosketuksi(this.form)
      return
    }

    this._ladataanService.aloitaLataaminen()
    // console.log(this.asiakas)
    this._asiakasService.paivitaAsiakas(this.asiakas, 'tilikaudet-eivat-voineet-muuttua', true, false).then(() => {
      this._asiakasService.asetaNykyinenAsiakas(this.asiakas)
      this._snackbar.open('Laskutustiedot tallennettiin onnistuneesti.', 'OK', { duration: 5000, verticalPosition: 'top' })
      this._ladataanService.lopetaLataaminen()
    }).catch(err => {
      this._ladataanService.lopetaLataaminen()
      this._errorHandler.handleError(err)
      this._snackbar.open('TALLENNUS EPÄONNISTUI. Kerro terveiset ylläpidolle.', 'Pilalla', { duration: 10000, verticalPosition: 'top' })
    })

  }

  lisaa() {
    this._router.navigate(['asiakkaat', this.asiakas.avain, 'laskutus', 'luo', UUDEN_LASKUN_AVAIN, UUDEN_LASKUN_AVAIN]).then(result => {
      this._vasenValikkoService.paivitaAuki(false)
    })
  }


  async annaSaldoPaivalle() {

    try {

      this._ladataanService.aloitaLataaminen()

      const date: Date = this.reskontratilanneForm.get('reskontratilannePaiva').value
      const arvopaiva = this._dateService.dateToLocalDate(date)
      const alkaen: LocalDate = { year: this._dateService.lisaaKuukausiaPaikallinen(arvopaiva, -24).year, month: 1, day: 1 }
      const pyynto: LaskuLataaReskontratilannePyynto = {
        alkaen: alkaen,
        arvopaiva: arvopaiva,
        paattyen: arvopaiva,
        asiakasAvain: this.asiakas.avain
      }
      const res = await this._firebase.functionsCall<LaskuLataaReskontratilannePyynto, LaskuLataaReskontratilanneVastaus>('laskuLataaAsiakkaanReskontratilanne', pyynto, { timeout: 540 * 1000 })

      if (res.e) {
        throw new Error(res.e)
      }

      this._fileSaverService.saveBase64As(res.base64File, res.filename, 'xlsx')

      // const pvm: LocalDate = { day: 31, month: 12, year: 2018 }
      // const asDate: Date = this._dateService.localDateToDate(pvm)
      // const nextDay = this._dateService.lisaaPaiviaPaikallinen(pvm, 1)

      // this._ladataanService.aloitaLataaminen()

      // try {

      //   const data: any[][] = []

      //   data.push(['', '', ''])
      //   data.push(['', 'Lasku', 'Summa', 'Avoinna'])

      //   const fetchAtATime = 250

      //   let moreData = true
      //   let lastDocument = null
      //   while (moreData) {
      //     let query = this._firebase.firestoreCollection<Lasku>('laskut/' + this.asiakas.asiakasId + '/laskut')
      //       .where('pvm', '<', this._timestampService.localDateToTimestamp(nextDay))
      //       .orderBy('pvm', 'desc') // FIXME: ?
      //       .orderBy('avain', 'desc')
      //       .limit(fetchAtATime)

      //     if (lastDocument) {
      //       query = query.limitToLast(lastDocument)
      //     }

      //     const promise = query.get()

      //     const snapshots = await promise
      //     //console.log('Haettu', snapshots.size)
      //     moreData = snapshots.length === fetchAtATime
      //     for (const snapshot of snapshots) {
      //       const lasku: Lasku = snapshot as Lasku
      //       const laskunumero = this._laskuSharedService.annaMuotoiltuLaskunumero(lasku, lasku)
      //       // const hinta = this._currencyService.formatoiRahaIlmanValuuttaSymbolia(lasku.summa, 'fi')

      //       const viimeisinTavallinen = this._laskuSharedService.annaViimeisinTavallinenLaskuPaivamaaralle(lasku, asDate)
      //       // const muistutuksetJaHyvitykset = this._laskuSharedService.annaLaskuryppaanMuistutuksetJaHyvitykset(lasku)

      //       const laskunSummatJaTila = this._laskuSharedService.annaLaskunSummatJaTilaLaskulleTietyllaPaivamaaralla(lasku, asDate)
      //       const summaYhteensaBig = new Big(viimeisinTavallinen.summa).plus(laskunSummatJaTila.huomautuskorot).plus(laskunSummatJaTila.huomautuskulut)

      //       const avoinna = laskunSummatJaTila.avoinna.round(2, Big.roundHalfUp)
      //       const summaYhteensa = summaYhteensaBig.round(2, Big.roundHalfUp)
      //       const summaNumber = this._currencyService.muutaBigDecimalRahaksi(summaYhteensa)
      //       const avoinnaNumber = this._currencyService.muutaBigDecimalRahaksi(avoinna)

      //       data.push(['', laskunumero, summaNumber, avoinnaNumber])
      //       lastDocument = snapshot
      //     }

      //   }

      //   /* generate worksheet */
      //   const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(data)

      //   /* generate workbook and add the worksheet */
      //   const wb: XLSX.WorkBook = XLSX.utils.book_new()
      //   XLSX.utils.book_append_sheet(wb, ws, 'Laskut')

      //   /* save to file */
      //   XLSX.writeFile(wb, 'Laskut_' + this.asiakas.nimi + '_(' + this.asiakas.ytunnus + ').xlsx')

      this._ladataanService.lopetaLataaminen()

    } catch (err) {
      this._ladataanService.lopetaLataaminen()
      this._errorHandler.handleError(err)
    }

  }

}
