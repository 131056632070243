import { ErrorResponse, NumberDate } from '../../_shared-core/model/common'

export interface SelvitettavienTilanneraporttiPyynto {

}

export interface SelvitettavienTilanneraporttiVastaus {
  tiedostot: { [tiedostonimi: string]: string }
}

export enum YllapitoTilastonTyyppi {
  LOPETTAVAT = 'lopettavat',
  ALOITTAVAT = 'aloittavat',
  HINNANMUUTOKSET = 'hinnanmuutokset',
  SOPIMUSTYYPPIEN_MUUTOKSET = 'sopimustyyppien-muutokset',
  POYTALAATIKKO_JA_TAUKOSOPIMUSTEN_KESTOT = 'poytalaatikko-ja-taukosopimusten-kestot',
  SAAMISET_OSAKKAILTA_JA_OMAISILTA = 'saamiset-osakkailta-ja-omaisilta'
}

export interface YllapitoTilastotPyynto {
  tyyppi: YllapitoTilastonTyyppi
  alku: NumberDate
  loppu: NumberDate
}

export interface YllapitoTilastotVastaus extends ErrorResponse {
  csv: string
  filename: string
}
