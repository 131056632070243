import { Injectable } from '@angular/core'

import { FirebaseLemonator } from './firebase-lemonator.service'

import { Timestamp } from 'firebase/firestore'

import { Observable, of, combineLatest } from 'rxjs'
import { map, switchMap } from 'rxjs/operators'

import { Paatilikartta, Kirjanpitotili, AsiakkaanTilikartta, PaatilikartanProfiilinOsa, PaatilikartanProfiili, PaatilikartanHistoria, AsiakkaanTilikartanHistoria } from 'app/_jaettu-lemonator/model/kirjanpito'
import { TilikarttaJaettuService, HierarkiaKirjanpitotili } from 'app/_jaettu-lemonator/service/tilikartta-jaettu.service'

import { KirjautunutKayttajaService } from './kirjautunut-kayttaja.service'
import { AsiakasService, AsiakkaanAvainTiedot } from './asiakas/asiakas.service'

import { KirjanpitoUriService } from 'app/_jaettu-lemonator/service/kirjanpito-uri.service'

@Injectable()
export class TilikarttaService {

  nykyisenAsiakkaanTilikarttaObservable: Observable<AsiakkaanTilikartta>
  // nykyisenAsiakkaanTilikartanTilitObservable: Observable<AsiakkaanTilikarttaJaPuretutTilit>
  nykyisenAsiakkaanTilikartanJaPaatilikartanTilitObservable: Observable<Kirjanpitotili[]>
  nykyisenAsiakkaanTilikartanTilihierarkiaObservable: Observable<HierarkiaKirjanpitotili[]>

  paatilikarttaObservable: Observable<Paatilikartta>
  paatilikartanTilitObservable: Observable<Kirjanpitotili[]>
  paatilikartanTilihierarkiaObservable: Observable<HierarkiaKirjanpitotili[]>

  nykyisenAsiakkaanSelvitettavatOstotTilitObservable: Observable<Kirjanpitotili[]>
  nykyisenAsiakkaanSelvitettavatMyynnitTilitObservable: Observable<Kirjanpitotili[]>


  constructor(
    private _firebase: FirebaseLemonator,
    private kayttajaService: KirjautunutKayttajaService,
    private asiakasService: AsiakasService,
    private tilikarttaJaettuService: TilikarttaJaettuService,
    private _kirjanpitoUriService: KirjanpitoUriService
  ) {

    this.nykyisenAsiakkaanTilikarttaObservable = this.asiakasService.nykyinenAsiakasAvainObservable.pipe(
      switchMap(asiakas => {
        if (asiakas) {
          const uri = this._kirjanpitoUriService.annaAsiakkaanTilikartanUri(asiakas.avain)
          return this._firebase.firestoreDoc<AsiakkaanTilikartta>(uri).listen()
        }
        return of<AsiakkaanTilikartta>(null)
      })
    )
    // this.nykyisenAsiakkaanTilikartanTilitObservable = this.nykyisenAsiakkaanTilikarttaObservable.pipe(
    //   map(tilikartta => {
    //     return {
    //       tilikartta: tilikartta,
    //       tilit: tilikartta && tilikartta.tilit ? Object.keys(tilikartta.tilit).map(key => tilikartta.tilit[key]) : []
    //     }
    //   }),
    //   lemonShare()
    // )

    this.paatilikarttaObservable = this.kayttajaService.kirjanpitajanTiedotObservable.pipe(
      switchMap(kayttaja => {
        if (kayttaja) {
          return this._firebase.firestoreDoc<Paatilikartta>('admin/paatilikartta').listen()
        }
        return of<Paatilikartta>(null)
      })
    )
    this.paatilikartanTilitObservable = this.paatilikarttaObservable.pipe(
      map(tilikartta => {
        return tilikartta && tilikartta.tilit ? Object.keys(tilikartta.tilit).map(key => tilikartta.tilit[key]) : []
      })
    )

    this.nykyisenAsiakkaanTilikartanJaPaatilikartanTilitObservable = combineLatest([this.paatilikarttaObservable, this.nykyisenAsiakkaanTilikarttaObservable]).pipe(
      map(([paatilikartta, asiakkaanTilikartta]) => {
        return this.tilikarttaJaettuService.yhdistaAsiakkaantilikarttaPaatilikarttaan(paatilikartta, asiakkaanTilikartta)
      })
    )

    this.nykyisenAsiakkaanTilikartanTilihierarkiaObservable = this.nykyisenAsiakkaanTilikartanJaPaatilikartanTilitObservable.pipe(
      map(tilit => {
        return this.tilikarttaJaettuService.muutaTililistausHierarkiaksi(tilit)
      })
    )

    this.paatilikartanTilihierarkiaObservable = this.paatilikartanTilitObservable.pipe(
      map(tilit => {
        return this.tilikarttaJaettuService.muutaTililistausHierarkiaksi(tilit)
      })
    )

    this.nykyisenAsiakkaanSelvitettavatOstotTilitObservable = this.nykyisenAsiakkaanTilikartanTilihierarkiaObservable.pipe(
      map(hierarkiat => {
        return this.tilikarttaJaettuService.annaSelvitettavatOstotTilit(hierarkiat)
      })
    )
    this.nykyisenAsiakkaanSelvitettavatMyynnitTilitObservable = this.nykyisenAsiakkaanTilikartanTilihierarkiaObservable.pipe(
      map(hierarkiat => {
        return this.tilikarttaJaettuService.annaSelvitettavatMyynnitTilit(hierarkiat)
      })
    )

  }

  async tallennaPaatilikartanTili(tili: Kirjanpitotili, paivittaja: string): Promise<void> {

    // Urit
    const uri = 'admin/paatilikartta'
    const historyUri = uri + '/paatilikartta-historia/' + this._firebase.firestoreCreateId()

    // History copy
    const historyCopy = (await this._firebase.firestoreDoc<Paatilikartta>(uri).get()) as PaatilikartanHistoria
    historyCopy.tilit[tili.numero + ''] = tili
    historyCopy.paivitetty = Timestamp.now()
    historyCopy.paivittaja = paivittaja

    const propertyPath = this._firebase.firestoreFieldPath('tilit', tili.numero)

    const batch = this._firebase.firestoreBatch()
    batch.updateFieldPath(uri, propertyPath, tili)
    batch.set(historyUri, historyCopy)

    return batch.commit()
  }

  async tallennaPaatilikartanProfiilinOsa(profiili: PaatilikartanProfiili, tili: Kirjanpitotili, osa: PaatilikartanProfiilinOsa, paivittaja: string): Promise<void> {

    // Urit
    const uri = 'admin/paatilikartta'
    const historyUri = uri + '/paatilikartta-historia/' + this._firebase.firestoreCreateId()

    // History copy
    const historyCopy = (await this._firebase.firestoreDoc<Paatilikartta>(uri).get()) as PaatilikartanHistoria
    historyCopy.paivitetty = Timestamp.now()
    historyCopy.paivittaja = paivittaja

    if (!osa) {
      const propertyPath = this._firebase.firestoreFieldPath('profiilit', profiili.avain, 't', tili.numero + '')
      historyCopy.profiilit[profiili.avain].t[tili.numero + ''] = this._firebase.firestoreDeleteMarker()
      const batch = this._firebase.firestoreBatch()
      batch.updateFieldPath(uri, propertyPath, this._firebase.firestoreDeleteMarker())
      batch.set(historyUri, historyCopy, { merge: true })
      return batch.commit()
    }

    const nimiFiPropertyPath = this._firebase.firestoreFieldPath('profiilit', profiili.avain, 't', tili.numero + '', 'nimi', 'fi')
    const nimiEnPropertyPath = this._firebase.firestoreFieldPath('profiilit', profiili.avain, 't', tili.numero + '', 'nimi', 'en')
    const piilotaPropertyPath = this._firebase.firestoreFieldPath('profiilit', profiili.avain, 't', tili.numero + '', 'piilota')

    if (!historyCopy.profiilit[profiili.avain].t[tili.numero + '']) { historyCopy.profiilit[profiili.avain].t[tili.numero + ''] = {} }
    if (!historyCopy.profiilit[profiili.avain].t[tili.numero + ''].nimi) { historyCopy.profiilit[profiili.avain].t[tili.numero + ''].nimi = { fi: null, en: null } }
    historyCopy.profiilit[profiili.avain].t[tili.numero + ''].nimi.fi = osa.nimi?.fi ? osa.nimi.fi : this._firebase.firestoreDeleteMarker()
    historyCopy.profiilit[profiili.avain].t[tili.numero + ''].nimi.en = osa.nimi?.en ? osa.nimi.en : this._firebase.firestoreDeleteMarker()
    historyCopy.profiilit[profiili.avain].t[tili.numero + ''].piilota = osa.piilota ? osa.piilota : this._firebase.firestoreDeleteMarker()

    const batch = this._firebase.firestoreBatch()
    batch.updateFieldPath(uri, nimiFiPropertyPath, osa.nimi?.fi ? osa.nimi.fi : this._firebase.firestoreDeleteMarker())
    batch.updateFieldPath(uri, nimiEnPropertyPath, osa.nimi?.en ? osa.nimi.en : this._firebase.firestoreDeleteMarker())
    batch.updateFieldPath(uri, piilotaPropertyPath, osa.piilota ? osa.piilota : this._firebase.firestoreDeleteMarker())
    batch.set(historyUri, historyCopy, { merge: true })

    return batch.commit()

    // return this._firebase.firestoreUpdateFields(uri,
    //   { data: osa.nimi?.fi ? osa.nimi.fi : this._firebase.firestoreDeleteMarker(), fieldPath: nimiFiPropertyPath },
    //   { data: osa.nimi?.en ? osa.nimi.en : this._firebase.firestoreDeleteMarker(), fieldPath: nimiEnPropertyPath },
    //   { data: osa.piilota ? 1 : this._firebase.firestoreDeleteMarker(), fieldPath: piilotaPropertyPath }
    // )

  }

  etsiTiliHierarkiasta(numero: string, kaikki: HierarkiaKirjanpitotili[]): HierarkiaKirjanpitotili | null {
    return this.tilikarttaJaettuService.etsiTiliHierarkiasta(numero, kaikki)
  }

  async tallennaAsiakkaanTilikartanTili(asiakas: AsiakkaanAvainTiedot, tili: Kirjanpitotili, paivittaja: string): Promise<void> {

    // Urit
    const uri = this._kirjanpitoUriService.annaAsiakkaanTilikartanUri(asiakas.avain)
    const historyUri = this._kirjanpitoUriService.annaAsiakkaanTilikartanHistoriaUri(asiakas.avain, this._firebase.firestoreCreateId())

    // History copy
    const historyCopy = await this._annaAsiakkaanTilikartanHistoriaCopy(uri)
    if (!historyCopy.tilit) {
      historyCopy.tilit = {}
    }
    historyCopy.tilit[tili.numero + ''] = tili
    historyCopy.paivitetty = Timestamp.now()
    historyCopy.paivittaja = paivittaja

    const propertyPath = this._firebase.firestoreFieldPath('tilit', tili.numero + '')
    const partialTilikartta: Partial<AsiakkaanTilikartta> = { tilit: {} }
    partialTilikartta.tilit[tili.numero] = tili

    const batch = this._firebase.firestoreBatch()
    batch.set(uri, partialTilikartta, { mergeFields: [propertyPath] })
    batch.set(historyUri, historyCopy)

    return batch.commit()

  }

  async tallennaAsiakkaanPaatilikartanYliajoTili(asiakas: AsiakkaanAvainTiedot, tili: Kirjanpitotili, paivittaja: string): Promise<void> {

    // Urit
    const uri = this._kirjanpitoUriService.annaAsiakkaanTilikartanUri(asiakas.avain)
    const historyUri = this._kirjanpitoUriService.annaAsiakkaanTilikartanHistoriaUri(asiakas.avain, this._firebase.firestoreCreateId())

    // History copy
    const historyCopy = await this._annaAsiakkaanTilikartanHistoriaCopy(uri)
    if (!historyCopy.yliajotilit) {
      historyCopy.yliajotilit = {}
    }
    historyCopy.yliajotilit[tili.numero + ''] = tili
    historyCopy.paivitetty = Timestamp.now()
    historyCopy.paivittaja = paivittaja

    const propertyPath = this._firebase.firestoreFieldPath('yliajotilit', tili.numero + '')
    const partialTilikartta: Partial<AsiakkaanTilikartta> = { yliajotilit: {} }
    partialTilikartta.yliajotilit[tili.numero] = tili

    const batch = this._firebase.firestoreBatch()
    batch.set(uri, partialTilikartta, { mergeFields: [propertyPath] })
    batch.set(historyUri, historyCopy)

    return batch.commit()

  }

  async poistaAsiakkaanPaatilikartanYliajoTili(asiakas: AsiakkaanAvainTiedot, tili: Kirjanpitotili, paivittaja: string): Promise<void> {

    // Urit
    const uri = this._kirjanpitoUriService.annaAsiakkaanTilikartanUri(asiakas.avain)
    const historyUri = this._kirjanpitoUriService.annaAsiakkaanTilikartanHistoriaUri(asiakas.avain, this._firebase.firestoreCreateId())

    // History copy
    const historyCopy = await this._annaAsiakkaanTilikartanHistoriaCopy(uri)
    if (!historyCopy.yliajotilit) {
      historyCopy.yliajotilit = {}
    }
    historyCopy.yliajotilit[tili.numero + ''] = this._firebase.firestoreDeleteMarker()
    historyCopy.paivitetty = Timestamp.now()
    historyCopy.paivittaja = paivittaja

    const propertyPath = this._firebase.firestoreFieldPath('yliajotilit', tili.numero + '')
    const partialTilikartta: Partial<AsiakkaanTilikartta> = { yliajotilit: {} }
    partialTilikartta.yliajotilit[tili.numero] = this._firebase.firestoreDeleteMarker()

    const batch = this._firebase.firestoreBatch()
    batch.set(uri, partialTilikartta, { mergeFields: [propertyPath] })
    batch.set(historyUri, historyCopy, { merge: true }) // Needs to have merge for firestoreDeleteMarker to work properly.

    return batch.commit()
  }

  private async _annaAsiakkaanTilikartanHistoriaCopy(uri: string): Promise<AsiakkaanTilikartanHistoria> {
    const nykyinenVersio = await this._firebase.firestoreDoc<AsiakkaanTilikartta>(uri).get()
    if (nykyinenVersio) {
      return nykyinenVersio as AsiakkaanTilikartanHistoria
    }
    const versio: AsiakkaanTilikartanHistoria = {
      tilit: {},
      paivitetty: null,
      paivittaja: null,
      yliajotilit: {}
    }
    return versio
  }

}
