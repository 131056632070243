<div class="list-page">

  <form (ngSubmit)="search()" [formGroup]="form" novalidate>

    <div style="max-width: 680px">
      <div class="light-blue-text" style="font-size: 18px; margin-bottom: 20px; line-height: 20px; margin-top: 55px;">Hae asiakasta</div>

      <div style="display: flex; flex-flow: row wrap; align-items: center; margin-left: 10px; margin-right: 10px;">
        <mat-form-field style="width: 125px; margin-right: 25px;">
          <mat-label>Etsi asiakas</mat-label>
          <input #hakuInput [name]="inputname" type="text" formControlName="haku" matInput data-lpignore="true" />
          <mat-error *ngIf="hakuControl.errors?.required && (hakuControl.dirty || hakuControl.touched)">
            Haku puuttuu
          </mat-error>
          <mat-error *ngIf="hakuControl.errors?.minLenght && (hakuControl.dirty || hakuControl.touched)">
            Haku on liian lyhyt
          </mat-error>
        </mat-form-field>
        <mat-form-field style="width: 125px; margin-right: 25px;">
          <mat-label>Yhtiömuoto</mat-label>
          <mat-select formControlName="yhtiomuoto">
            <mat-option [value]="null">

            </mat-option>
            <mat-option *ngFor="let yhtiomuoto of yritysmuodot" [value]="yhtiomuoto.koodi">
              {{ yhtiomuoto.nimi }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field style="width: 125px; margin-right: 25px;">
          <mat-label>Alv-jakso</mat-label>
          <mat-select formControlName="alvjakso">
            <mat-option [value]="null">

            </mat-option>
            <mat-option *ngFor="let alvjakso of alvjaksot" [value]="alvjakso.koodi">
              {{ alvjakso.nimi }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field style="width: 200px;" *ngIf="isSuperUser">
          <mat-label>Kirjanpitäjä</mat-label>
          <mat-select formControlName="kirjanpitaja">
            <mat-option [value]="null">
            </mat-option>
            <mat-option *ngFor="let kirjanpitaja of kirjanpitajienNimitiedot" [value]="kirjanpitaja.avain">
              {{kirjanpitaja.etunimi}} {{ kirjanpitaja.sukunimi }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div style="display: flex; justify-content: space-evenly; margin-top: 1em; margin-bottom: 2em;">
        <button class="secondarybutton" mat-button (click)="search()" type="button">Hae</button>
        <!--<app-secondary-button [actionId]="'Hae asiakastiedot'" [actionContext]="[this.form.value]" (click)="search()">
          <div>Hae</div>
        </app-secondary-button>-->

      </div>
    </div>

  </form>

  <div *ngIf="tulokset | async as asiakkaat">
    <div class="light-blue-text" style="font-size: 18px; margin-bottom: 0px; line-height: 20px; margin-top: 55px;">Hakutulokset</div>
    <table *ngIf="asiakkaat.length else eiAsiakkaita">
      <tr>
        <th *ngIf="isDev">Id</th>
        <th>Nimi</th>
        <th>Y-tunnus</th>
        <th *ngIf="isSuperUser">Vastuukirjanpitäjä</th>
        <th>Linkki</th>
      </tr>
      <tr *ngFor="let asiakas of asiakkaat">
        <td *ngIf="isDev">{{asiakas.i}}</td>
        <td>{{asiakas.n}}</td>
        <td>{{asiakas.y}}</td>
        <td *ngIf="isSuperUser">{{asiakas.kirjanpitajanNimi}}</td>
        <td><a [routerLink]="'/asiakkaat/' + asiakas.k + '/asiakastiedot'" [href]="'/asiakkaat/' + asiakas.k + '/asiakastiedot'">asiakkaan tiedot</a></td>
      </tr>
    </table>
    <ng-template #eiAsiakkaita>
      <div style="padding: 3em;">Antamillasi hakuehdoilla ei löytynyt yhtään asiakasta.</div>
    </ng-template>
  </div>

</div>