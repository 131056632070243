import { OnInit, OnDestroy, ChangeDetectionStrategy, Component, ChangeDetectorRef, ViewChild, ErrorHandler, Input } from '@angular/core'
import { map, takeUntil } from 'rxjs/operators'
import { KirjanpitoVeroilmoitusRadioOption } from '../components/veroilmoitus-row-radio.component'
import { CurrencyService } from 'app/_shared-core/service/currency.service'
import { VeroilmoituksenValueConstants } from 'app/_jaettu-lemonator/model/kirjanpito'
import { KirjanpitoVeroilmoitusBaseSpecializedComponent } from '../components/base-veroilmoitus.component'
import { Router } from '@angular/router'
import { AVAA_VEROILMOITUKSEN_KENTTA_BROADCAST_CHANNEL, AvaaVeroilmoitusKenttaMessage, TallennaArvoEvent } from '../veroilmoitus.component'
import { VeroilmoitusTranslationService } from 'app/_jaettu-lemonator/service/veroilmoitus/veroilmoitus-translation.service'
import { KirjanpitoVeroilmoitusRowNumberComponent } from '../components/veroilmoitus-row-number.component'
import { KirjanpitoVeroilmoitusRowNumberDoubleComponent } from '../components/veroilmoitus-row-number-double.component'
import { RepeatingMetadata } from 'app/_jaettu-lemonator/service/veroilmoitus/base-specialized-calculation.service'
import { VeroilmoitusLaskenta2025OyService, RepeatingPartIdentifiers } from 'app/_jaettu-lemonator/service/veroilmoitus/oy/veroilmoitus-laskenta-2025-oy.service'
import { DateService } from 'app/_shared-core/service/date.service'
import { AsiakasJaettuService } from 'app/_jaettu-lemonator/service/asiakas-jaettu.service'
import { CodeCheckService } from 'app/_shared-core/service/code-check.service'
import { AsiakasService } from 'app/_angular/service/asiakas/asiakas.service'
import { Observable, firstValueFrom } from 'rxjs'
import { KirjanpitoVeroilmoitusOsakkaatComponent } from '../components/veroilmoitus-osakkaat.component'
import { MatDialog } from '@angular/material/dialog'
import { KirjanpitoVeroilmoitusOsakkaatDialog, KirjanpitoVeroilmoitusOsakkaatDialogData } from '../components/veroilmoitus-osakkaat.dialog'
import { LadataanService } from 'app/_jaettu-angular/service/ladataan.service'

@Component({
  selector: '[app-kirjanpito-veroilmoitus-oy-2025]',
  templateUrl: './veroilmoitus-oy-2025.component.html',
  styleUrls: ['./veroilmoitus-oy-2025.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class KirjanpitoVeroilmoitusOy2025Component extends KirjanpitoVeroilmoitusBaseSpecializedComponent implements OnInit, OnDestroy {

  private _avaaVeroilmoitusKenttaChannel: BroadcastChannel

  // eslint-disable-next-line @typescript-eslint/naming-convention
  @ViewChild('edit364_365', { static: false, read: KirjanpitoVeroilmoitusRowNumberDoubleComponent }) editComponent364_365: KirjanpitoVeroilmoitusRowNumberDoubleComponent
  @ViewChild('edit800', { static: false, read: KirjanpitoVeroilmoitusRowNumberComponent }) editComponent800: KirjanpitoVeroilmoitusRowNumberComponent
  @ViewChild('edit801', { static: false, read: KirjanpitoVeroilmoitusRowNumberComponent }) editComponent801: KirjanpitoVeroilmoitusRowNumberComponent
  @ViewChild('edit802', { static: false, read: KirjanpitoVeroilmoitusRowNumberComponent }) editComponent802: KirjanpitoVeroilmoitusRowNumberComponent
  @ViewChild('edit804', { static: false, read: KirjanpitoVeroilmoitusRowNumberComponent }) editComponent804: KirjanpitoVeroilmoitusRowNumberComponent
  @ViewChild('edit806', { static: false, read: KirjanpitoVeroilmoitusRowNumberComponent }) editComponent806: KirjanpitoVeroilmoitusRowNumberComponent
  @ViewChild('edit807', { static: false, read: KirjanpitoVeroilmoitusRowNumberComponent }) editComponent807: KirjanpitoVeroilmoitusRowNumberComponent

  @ViewChild('osakkaatComp') osakkaatComp: KirjanpitoVeroilmoitusOsakkaatComponent
  @Input() lukittu: boolean

  kyllaEiOptions: KirjanpitoVeroilmoitusRadioOption[] = [{ label: 'Kyllä', value: VeroilmoituksenValueConstants.TRUE }, { label: 'Ei', value: VeroilmoituksenValueConstants.FALSE }]
  kylla1Ei2Options: KirjanpitoVeroilmoitusRadioOption[] = [{ label: 'Kyllä', value: VeroilmoituksenValueConstants.TRUE }, { label: 'Ei', value: '2' }]
  tilintarkastusOptions: KirjanpitoVeroilmoitusRadioOption[] = [
    { label: 'Kyllä', value: VeroilmoituksenValueConstants.TRUE },
    { label: 'Ei, tehdään myöhemmin.', value: '2' },
    { label: 'Ei, tilintarkastaja on jätetty valitsematta.', value: '3' }
  ]

  // v342Korvausparametrit: Korvausparametrit = {}
  // v347Korvausparametrit: Korvausparametrit = {}
  // v916Options: KirjanpitoVeroilmoitusRadioOption[] = [{ label: 'Yhdenkertainen', value: VeroilmoituksenValueConstants.FALSE }, { label: 'Kahdenkertainen', value: VeroilmoituksenValueConstants.TRUE }]
  // ajojenSelvitystyyppiOptions: KirjanpitoVeroilmoitusRadioOption[] = [{ label: 'Ajopäiväkirjaan', value: '1' }, { label: 'Muuhun selvitykseen', value: '2' }]
  // v396Options: KirjanpitoVeroilmoitusRadioOption[] = [{ label: 'Yksityisajojen kulut on vähennetty kirjanpidossa', value: '1' }, { label: 'Yksityisajojen kuluja ei ole vähennetty kirjanpidossa', value: '2' }]

  // tyohuonevahennysOptions: KirjanpitoVeroilmoitusRadioOption[] = [{ label: 'Kaavamainen työhuonevähennys', value: VeroilmoituksenValueConstants.TRUE }, { label: 'Työhuonevähennys todellisten kulujen mukaan', value: VeroilmoituksenValueConstants.FALSE }]
  // ctrl395Options: KirjanpitoVeroilmoitusRadioOption[] = [
  //   { label: 'Pääomatulon enimmäismäärä on 20% nettovarallisuudesta', value: 'DEFAULT' },
  //   { label: 'Vaadin, että pääomatulon enimmäismäärä on 10% nettovarallisuudesta', value: '1' },
  //   { label: 'Vaadin, että jaettava yritystulo on kokonaan ansiotuloa', value: '2' }
  // ]

  osakasRepeatingMetadatas: RepeatingMetadata[] = []
  osakasHuoneistotRepeatingMetadatas: RepeatingMetadata[] = []
  osingotRepeatingMetadatas: RepeatingMetadata[] = []
  vapaastaOmastaPaaomastaJaettuRepeatingMetadatas: RepeatingMetadata[] = []

  veroilmoitusEiAloitettuObservable: Observable<boolean>

  private _oyService: VeroilmoitusLaskenta2025OyService = null

  constructor(
    private _router: Router,
    protected _asiakasService: AsiakasService,
    protected _currencyService: CurrencyService,
    protected _veroilmoitusTranslationService: VeroilmoitusTranslationService,
    private _dateService: DateService,
    private _changeDetector: ChangeDetectorRef,
    private _asiakasJaettuService: AsiakasJaettuService,
    private _codeCheckService: CodeCheckService,
    private _dialog: MatDialog,
    private _ladataanService: LadataanService,
    private _errorHandler: ErrorHandler
  ) {
    super(_currencyService, _veroilmoitusTranslationService, _changeDetector, _asiakasService)
    this._oyService = new VeroilmoitusLaskenta2025OyService(this._dateService, this._currencyService, this._codeCheckService, this._asiakasJaettuService)
  }

  nayta178Radio: boolean = false
  nayta260Teksti: boolean = false
  nayta332tai333Teksti: boolean = false
  nayta337tai338Teksti: boolean = false
  nayta439Teksti: boolean = false
  nayta261Teksti: boolean = false
  nayta372tai373Teksti: boolean = false
  nayta436Teksti: boolean = false
  nayta270tai271Teksti: boolean = false
  nayta402Teksti: boolean = false
  nayta405Teksti: boolean = false
  nayta424Teksti: boolean = false
  nayta430Teksti: boolean = false
  nayta431Teksti: boolean = false
  nayta695Teksti: boolean = false
  nayta696Teksti: boolean = false
  nayta697Teksti: boolean = false
  nayta698Teksti: boolean = false

  ngOnInit() {

    this.veroilmoitusEiAloitettuObservable = this.veroilmoitusObservable.pipe(
      map(veroilmoitus => {
        const tilikausi = veroilmoitus?.perustiedot?.tilikausi
        return !tilikausi?.veroilmoitusStarted
      })
    )

    this._avaaVeroilmoitusKenttaChannel = new BroadcastChannel(AVAA_VEROILMOITUKSEN_KENTTA_BROADCAST_CHANNEL)
    this._avaaVeroilmoitusKenttaChannel.addEventListener('message', (event: MessageEvent<AvaaVeroilmoitusKenttaMessage>) => {
      this.avaaVeroilmoitusKentta(event)
    })

    // Set editable values to form
    this.veroilmoitusObservable.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(veroilmo => {

      this.veroilmoitus = veroilmo
      if (this.veroilmoitus) {

        this.asiakkaanNimi = this.veroilmoitus.perustiedot.asiakas.nimi
        this.osakasRepeatingMetadatas = this._oyService.parsiOsakkaidenMetadata(this.veroilmoitus.arvot)
        this.osakasHuoneistotRepeatingMetadatas = this._oyService.parsiOsakasHuoneistojenMetadata(this.veroilmoitus.arvot)
        this.osingotRepeatingMetadatas = this._oyService.parsiOsinkojenMetadata(this.veroilmoitus.arvot)
        this.vapaastaOmastaPaaomastaJaettuRepeatingMetadatas = this._oyService.parsiVapaastaPaaomastaJaettujenMetadata(this.veroilmoitus.arvot)

        this.nayta178Radio = this._getRawValue('177') === '1'
        this.nayta260Teksti = this._hasValue('260')
        this.nayta332tai333Teksti = this._hasValue('332') || this._hasValue('333')
        this.nayta337tai338Teksti = this._hasValue('337') || this._hasValue('338')
        this.nayta439Teksti = this._hasValue('439')
        this.nayta261Teksti = this._hasValue('261')
        this.nayta372tai373Teksti = this._hasValue('372') || this._hasValue('373')
        this.nayta436Teksti = this._hasValue('436')
        this.nayta270tai271Teksti = this._hasValue('270') || this._hasValue('271')
        this.nayta402Teksti = this._hasValue('402')
        this.nayta405Teksti = this._hasValue('405')
        this.nayta424Teksti = this._hasValue('424')
        this.nayta430Teksti = this._hasValue('430')
        this.nayta431Teksti = this._hasValue('431')
        this.nayta695Teksti = this._hasValue('695')
        this.nayta696Teksti = this._hasValue('696')
        this.nayta697Teksti = this._hasValue('697')
        this.nayta698Teksti = this._hasValue('698')

        // Korvausparametrit are input for localization texts
        // const v342KorvausValue = this._getValue('v342Korvaus1', 'currency') || 0
        // this.v342Korvausparametrit['summa'] = this._currencyService.formatoiRahaIlmanValuuttaSymboliaTaiRyhmittelya(v342KorvausValue, 'fi')
        // const v347KorvausValue = this._getValue('v347Korvaus1', 'currency') || 0
        // this.v347Korvausparametrit['summa'] = this._currencyService.formatoiRahaIlmanValuuttaSymboliaTaiRyhmittelya(v347KorvausValue, 'fi')

      } else {
        this.asiakkaanNimi = ''
        this.nayta178Radio = false
        this.nayta260Teksti = false
        this.nayta332tai333Teksti = false
        this.nayta337tai338Teksti = false
        this.nayta439Teksti = false
        this.nayta261Teksti = false
        this.nayta372tai373Teksti = false
        this.nayta436Teksti = false
        this.nayta270tai271Teksti = false
        this.nayta402Teksti = false
        this.nayta405Teksti = false
        this.nayta424Teksti = false
        this.nayta430Teksti = false
        this.nayta431Teksti = false
        this.nayta695Teksti = false
        this.nayta696Teksti = false
        this.nayta697Teksti = false
        this.nayta698Teksti = false
      }

      this._changeDetector.markForCheck()
    })

  }

  poistaHuoneisto(repeatingMetadata: RepeatingMetadata) {
    const maxOrder = this.osakasHuoneistotRepeatingMetadatas.length
    this.poistaRepeatingItem(repeatingMetadata, maxOrder, (tallennettavatArvot) => {
      tallennettavatArvot.arvot.push({ numero: RepeatingPartIdentifiers.HUONEISTOT, arvo: maxOrder - 1 })
    }, '722', '724', '729', '730')
  }

  poistaOsinko(repeatingMetadata: RepeatingMetadata) {
    const maxOrder = this.osingotRepeatingMetadatas.length
    this.poistaRepeatingItem(repeatingMetadata, maxOrder, (tallennettavatArvot) => {
      tallennettavatArvot.arvot.push({ numero: RepeatingPartIdentifiers.OSINGOT, arvo: maxOrder - 1 })
    }, '537', '538', '522')
  }

  poistaVapaastaOmastaPaaomastaJaettu(repeatingMetadata: RepeatingMetadata) {
    const maxOrder = this.vapaastaOmastaPaaomastaJaettuRepeatingMetadatas.length
    this.poistaRepeatingItem(repeatingMetadata, maxOrder, (tallennettavatArvot) => {
      tallennettavatArvot.arvot.push({ numero: RepeatingPartIdentifiers.VAPAASTA_PAAOMASTA_JAETUT, arvo: maxOrder - 1 })
    }, '293', '294', '295')
  }

  lisaaHuoneisto() {
    const nyt = this.osakasHuoneistotRepeatingMetadatas.length
    const tallennettava: TallennaArvoEvent = {
      numero: RepeatingPartIdentifiers.HUONEISTOT,
      tyyppi: 'one',
      arvo: nyt + 1
    }
    this.tallennaArvo.next(tallennettava)
  }

  lisaaOsingot() {
    const nyt = this.osingotRepeatingMetadatas.length
    const tallennettava: TallennaArvoEvent = {
      numero: RepeatingPartIdentifiers.OSINGOT,
      tyyppi: 'one',
      arvo: nyt + 1
    }
    this.tallennaArvo.next(tallennettava)
  }

  lisaaVapaastaOmastaPaaomastaJaettu() {
    const nyt = this.vapaastaOmastaPaaomastaJaettuRepeatingMetadatas.length
    const tallennettava: TallennaArvoEvent = {
      numero: RepeatingPartIdentifiers.VAPAASTA_PAAOMASTA_JAETUT,
      tyyppi: 'one',
      arvo: nyt + 1
    }
    this.tallennaArvo.next(tallennettava)
  }

  avaaVeroilmoitusOsakkaat() {
    this._dialog.open<KirjanpitoVeroilmoitusOsakkaatDialog, KirjanpitoVeroilmoitusOsakkaatDialogData>(KirjanpitoVeroilmoitusOsakkaatDialog, { data: { veroilmoitusObservable: this.veroilmoitusObservable } })
  }

  async start() {
    try {
      this._ladataanService.aloitaLataaminen()

      const result = await this.osakkaatComp.tallenna()
      if (result === 'failed') { return }

      const asiakas = await firstValueFrom(this._asiakasService.nykyinenAsiakasObservable)
      await this.startVeroilmoitus(asiakas, this.veroilmoitus.perustiedot.tilikausi.avain)

    } catch (err: any) {
      console.log(err)
      this._errorHandler.handleError(new Error('Veroilmoituksen aloittaminen epäonnistui!', err))
    } finally {
      this._ladataanService.lopetaLataaminen()
    }
  }

  taytaPoistotEhdotus() {
    this.editComponent364_365.tallennaAnnetutArvot(true, true, this._annaEhdotus('364'), this._annaEhdotus('365'))
    this.editComponent800.tallennaAnnettuArvo(this._annaEhdotus('800'))
    this.editComponent801.tallennaAnnettuArvo(this._annaEhdotus('801'))
    this.editComponent802.tallennaAnnettuArvo(this._annaEhdotus('802'))
    this.editComponent804.tallennaAnnettuArvo(this._annaEhdotus('804'))
    this.editComponent806.tallennaAnnettuArvo(this._annaEhdotus('806'))
    this.editComponent807.tallennaAnnettuArvo(this._annaEhdotus('807'))
  }

  ngOnDestroy() {
    this._ngUnsubscribe.next()
    this._ngUnsubscribe.complete()
  }

}
