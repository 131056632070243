import { Component, OnInit, Output, EventEmitter, NgZone, ErrorHandler, Input, ChangeDetectorRef, OnDestroy, ChangeDetectionStrategy } from '@angular/core'

import { Observable, combineLatest, BehaviorSubject, of, firstValueFrom, Subject, timer } from 'rxjs'
import { filter, map, startWith, switchMap, takeUntil, tap } from 'rxjs/operators'

import { KirjanpitoUriService } from 'app/_jaettu-lemonator/service/kirjanpito-uri.service'
import { KlikattuKirjaus } from '../raportit.component'
import { Kirjanpitotili, RaporttiRequest, RaporttiTaselaskelmaAccountRow, RaporttiTaselaskelmaData, RaporttiTaselaskelmaDataResponse, RaporttiDetailRow, TilinKasittelyTaseErittelyssa, TilinpaatosTaseErittely, RaporttiPdfResponse, TaseErittelyLiite } from 'app/_jaettu-lemonator/model/kirjanpito'
import { RaporttiType } from 'app/_jaettu/model/reports-shared'

import { DateService } from 'app/_shared-core/service/date.service'
import { AsiakasService } from 'app/_angular/service/asiakas/asiakas.service'
import { AsiakkaanMaksutapa, Tilikausi } from 'app/_jaettu-lemonator/model/asiakas'
import { TilikarttaService } from 'app/_angular/service/tilikartta.service'
import { KopioijaPalvelu } from 'app/_jaettu/service/kopioija.service'
import { LadataanService } from 'app/_jaettu-angular/service/ladataan.service'
import { MatSnackBar } from '@angular/material/snack-bar'
import { DebugService } from 'app/_angular/service/debug.service'
import { KirjautunutKayttajaService } from 'app/_angular/service/kirjautunut-kayttaja.service'
import { CurrencyService } from 'app/_shared-core/service/currency.service'
import { TimestampService } from 'app/_jaettu-angular/service/timestamp-service'
import { FirebaseLemonator } from 'app/_angular/service/firebase-lemonator.service'

import { TilinpaatosStatus } from 'app/_jaettu-lemonator/model/tilinpaatos'
import { TiedostojenLataamisService } from 'app/_jaettu-angular/service/tiedostojen-lataamis.service'
import { NgxFileDropEntry } from 'ngx-file-drop'
import { TilikarttaJaettuService } from 'app/_jaettu-lemonator/service/tilikartta-jaettu.service'
import { FileSaverService } from 'app/_jaettu-angular/service/file-saver'
import { animate, state, style, transition, trigger } from '@angular/animations'
import { FormControl, FormGroup } from '@angular/forms'
import { LocalDate } from 'app/_shared-core/model/common'

interface TallentamatonTilinpaatosTaseErittely {
  tilienKasittelyt: { [tilinumero: string]: TilinKasittelyTaseErittelyssa }
  tilienTekstit: { [tilinumero: string]: string }
  valitutViennit: { [tilinumero_kirjausnumero_index: string]: boolean }
  valitutAlkusaldot: { [tilinumero: string]: boolean }
  loppuu?: LocalDate
}

interface RaporttiDetailRowErittely extends RaporttiDetailRow {
  /** Tilinumero + kirjauksen avain + viennin indeksi kirjauksessa */
  avain: string
  valittu?: 1
}
interface RaporttiTaselaskelmaAccountRowErittely extends RaporttiTaselaskelmaAccountRow {
  kasittely?: TilinKasittelyTaseErittelyssa
  teksti?: string
  alkusaldoValittu: boolean
  valittuSumma?: number
  d?: RaporttiDetailRowErittely[]
  grandParent: '1' | '2'
}

interface RaporttiTaselaskelmaDataErittely extends RaporttiTaselaskelmaData {
  r: RaporttiTaselaskelmaAccountRowErittely[]
  lukittu: boolean
}

interface MainForm {
  loppuu: FormControl<Date>
}

@Component({
  selector: '[app-kirjanpito-tilinpaatos-tase-erittely]',
  templateUrl: './tase-erittely.component.html',
  styleUrls: ['./tase-erittely.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    // the fade-in/fade-out animation.
    trigger('slowInQuickOut', [

      // the "in" style determines the "resting" state of the element when it is visible.
      state('in', style({ opacity: 1 })),

      // fade in when created. this could also be written as transition('void => *')
      transition(':enter', [
        style({ opacity: 0 }),
        animate('600ms 3s')
      ]),

      // fade out when destroyed. this could also be written as transition('* => void')
      transition(':leave', animate(100, style({ opacity: 0 })))
    ]),
    // the fade-in/fade-out animation.
    trigger('quickInSlowOut', [

      // the "in" style determines the "resting" state of the element when it is visible.
      state('in', style({ opacity: 1 })),

      // fade in when created. this could also be written as transition('void => *')
      transition(':enter', [
        style({ opacity: 0 }),
        animate(100)
      ]),

      // fade out when destroyed. this could also be written as transition('* => void')
      transition(':leave', animate(600, style({ opacity: 0 })))
    ])
  ]
})
export class KirjanpitoTilinpaatosTaseErittelyComponent implements OnInit, OnDestroy {

  @Input() selectedTilikausiObservable: Observable<Tilikausi>
  @Input() paivitaArvotHiljaisestiObservable: Observable<number>
  @Input() tilinpaatosStatusObservable: Observable<TilinpaatosStatus>

  @Output() kirjaustaKlikattiin: EventEmitter<KlikattuKirjaus> = new EventEmitter()

  private _ngUnsubscribe: Subject<void> = new Subject<void>()

  loadingSubject: BehaviorSubject<boolean> = new BehaviorSubject(true)
  taseDataObservable: Observable<RaporttiTaselaskelmaDataErittely>

  private _erittelyObservable: Observable<TilinpaatosTaseErittely>
  private _lopullinenErittelyObservable: Observable<TilinpaatosTaseErittely>
  private _tallentamattomatSubject: BehaviorSubject<TallentamatonTilinpaatosTaseErittely> = new BehaviorSubject({
    tilienKasittelyt: {},
    tilienTekstit: {},
    valitutViennit: {},
    valitutAlkusaldot: {}
  })

  cachedAttachmentsMap: Map<string, TaseErittelyLiite[]> = new Map()

  erittelyUriObservable: Observable<string>
  erittelyEncodedUriObservable: Observable<string>
  kirjanpitajaOnDevaajaObservable: Observable<boolean> = this._kirjautunutKayttajaService.kirjanpitajaOnDevaajaObservable

  form: FormGroup<MainForm>
  minLoppuuDateObservable: Observable<Date>
  maxLoppuuDateObservable: Observable<Date>

  // private _expandedAccountsBehaviorSubject: BehaviorSubject<{ normal: Set<string>, full: Set<string> }> = new BehaviorSubject({ normal: new Set<string>(), full: new Set<string>() })

  private _tilitMapObservable: Observable<Map<string, Kirjanpitotili>>
  // private _hakuvaihtoehdotSubject: BehaviorSubject<RaporttienHakuvaihtoehdot> = new BehaviorSubject({
  //   alkaa: null,
  //   loppuu: null,
  //   projekti: null,
  //   tiliin: null,
  //   tilista: null,
  //   vapaasanahaku: null
  // })

  private _saveSuccessSubject: Subject<number> = new Subject<number>()
  saveSuccessObservable: Observable<boolean> = this._saveSuccessSubject.pipe(switchMap(() => timer(1000).pipe(map(() => false), startWith(true))))

  private _latausVirheSubject: BehaviorSubject<string> = new BehaviorSubject(null)
  latausVirheObservable = this._latausVirheSubject.asObservable()

  constructor(
    private _ngZone: NgZone,
    private _firebaseLemonator: FirebaseLemonator,
    private _dateService: DateService,
    private _currencyService: CurrencyService,
    private _asiakasService: AsiakasService,
    private _kirjanpitoUriService: KirjanpitoUriService,
    private _tilikarttaService: TilikarttaService,
    private _errorHandler: ErrorHandler,
    private _copyService: KopioijaPalvelu,
    private _ladataanService: LadataanService,
    private _snackbar: MatSnackBar,
    private _debugService: DebugService,
    private _kirjautunutKayttajaService: KirjautunutKayttajaService,
    private _timestampService: TimestampService,
    private _tiedostojenLataamisService: TiedostojenLataamisService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _tilikarttaJaettuService: TilikarttaJaettuService,
    private _fileSaverService: FileSaverService
  ) { }

  private annaLoppuuPvm(erittely: TilinpaatosTaseErittely, tilikausi: Tilikausi) {
    if (erittely?.loppuu) {
      if (this._dateService.compareLocalDates(erittely.loppuu, '<', tilikausi.alkaa)) {
        return tilikausi.alkaa
      }
      if (this._dateService.compareLocalDates(tilikausi.loppuu, '<', erittely.loppuu)) {
        return tilikausi.loppuu
      }
      return erittely.loppuu
    }
    return tilikausi.loppuu
  }

  ngOnInit() {

    const loppuuCtrl = new FormControl<Date>(null)
    this.form = new FormGroup<MainForm>({
      loppuu: loppuuCtrl
    })

    this.minLoppuuDateObservable = this.selectedTilikausiObservable.pipe(
      map(tilikausi => {
        return tilikausi ? this._dateService.localDateToDate(tilikausi.alkaa) : null
      })
    )

    this.maxLoppuuDateObservable = this.selectedTilikausiObservable.pipe(
      map(tilikausi => {
        return tilikausi ? this._dateService.localDateToDate(tilikausi.loppuu) : null
      })
    )

    this._tilitMapObservable = this._tilikarttaService.nykyisenAsiakkaanTilikartanJaPaatilikartanTilitObservable.pipe(
      filter(tilit => !!tilit),
      map(tilit => {
        const tiliMap: Map<string, Kirjanpitotili> = new Map()
        for (const tili of tilit) {
          tiliMap.set(tili.numero, tili)
        }
        return tiliMap
      })
    )

    this.erittelyUriObservable = combineLatest([this._asiakasService.nykyinenAsiakasAvainObservable, this.selectedTilikausiObservable]).pipe(
      map(([asiakas, tilikausi]) => {
        if (asiakas && tilikausi) {
          return this._kirjanpitoUriService.annaTilinpaatosTaseErittelyUri(asiakas.avain, tilikausi)
        }
        return ''
      })
    )

    this.erittelyEncodedUriObservable = combineLatest([this._asiakasService.nykyinenAsiakasAvainObservable, this.selectedTilikausiObservable]).pipe(
      map(([asiakas, tilikausi]) => {
        if (asiakas && tilikausi) {
          const uri = this._kirjanpitoUriService.annaTilinpaatosTaseErittelyUri(asiakas.avain, tilikausi)
          return this._debugService.createFirestoreLink(uri)
        }
        return ''
      })
    )

    this._erittelyObservable = combineLatest([this._asiakasService.nykyinenAsiakasAvainObservable, this.selectedTilikausiObservable]).pipe(
      switchMap(([asiakas, tilikausi]) => {
        if (asiakas && tilikausi) {
          const uri = this._kirjanpitoUriService.annaTilinpaatosTaseErittelyUri(asiakas.avain, tilikausi)
          return this._firebaseLemonator.firestoreDoc<TilinpaatosTaseErittely>(uri).listen()
        }
        return of<TilinpaatosTaseErittely>(null)
      })
    )

    this._lopullinenErittelyObservable = combineLatest([this._tallentamattomatSubject, this._erittelyObservable]).pipe(
      map(([tallentamattomat, tietokannasta]) => {
        const lopullinen: TilinpaatosTaseErittely = tietokannasta ? this._copyService.cloneObjectDeep(tietokannasta) : {
          tilienKasittelyt: {},
          tilienTekstit: {},
          valitutViennit: {},
          valitutAlkusaldot: {}
        }

        if (!lopullinen.tilienKasittelyt) { lopullinen.tilienKasittelyt = {} }
        if (!lopullinen.tilienTekstit) { lopullinen.tilienTekstit = {} }
        if (!lopullinen.valitutViennit) { lopullinen.valitutViennit = {} }
        if (!lopullinen.valitutAlkusaldot) { lopullinen.valitutAlkusaldot = {} }

        for (const avain of Object.keys(tallentamattomat.tilienKasittelyt ?? {})) {
          lopullinen.tilienKasittelyt[avain] = tallentamattomat.tilienKasittelyt[avain]
        }

        for (const avain of Object.keys(tallentamattomat.tilienTekstit ?? {})) {
          lopullinen.tilienTekstit[avain] = tallentamattomat.tilienTekstit[avain]
        }

        for (const avain of Object.keys(tallentamattomat.valitutViennit ?? {})) {
          if (tallentamattomat.valitutViennit[avain]) {
            lopullinen.valitutViennit[avain] = 1
          } else {
            delete lopullinen.valitutViennit[avain]
          }
        }

        for (const avain of Object.keys(tallentamattomat.valitutAlkusaldot ?? {})) {
          if (tallentamattomat.valitutAlkusaldot[avain]) {
            lopullinen.valitutAlkusaldot[avain] = 1
          } else {
            delete lopullinen.valitutAlkusaldot[avain]
          }
        }

        if (tallentamattomat.loppuu) {
          lopullinen.loppuu = tallentamattomat.loppuu
        }
        if (tallentamattomat.loppuu === null) {
          delete lopullinen.loppuu
        }

        return lopullinen

      })
    )

    const laskelmanRaakadataObservable = combineLatest([
      this._asiakasService.nykyinenAsiakasAvainObservable,
      this.selectedTilikausiObservable.pipe(
        tap(() => {
          this._setLoadingTrue()
        })
      ),
      this._lopullinenErittelyObservable,
      this.paivitaArvotHiljaisestiObservable
    ]).pipe(
      switchMap(([asiakas, tilikausi, erittely, paivita]) => {

        if (!tilikausi?.alkaa || !tilikausi?.loppuu || !asiakas || !erittely) {
          return of<RaporttiTaselaskelmaDataErittely>(null)
        }

        const loppuu = this.annaLoppuuPvm(erittely, tilikausi)
        const haku: RaporttiRequest = {
          a: asiakas.avain,
          k: 'fi',
          w: RaporttiType.TASE_ERITTELY,
          s: this._dateService.localDateToNumber(tilikausi.alkaa),
          e: this._dateService.localDateToNumber(loppuu),
          ka: 1,
          ta: tilikausi.avain,
          datasource: 'raportointikirjaukset'
        }
        // if (hakuvaihtoehdot.tilista) { haku.b = hakuvaihtoehdot.tilista }
        // if (hakuvaihtoehdot.tiliin) { haku.c = hakuvaihtoehdot.tiliin }
        // if (hakuvaihtoehdot.vapaasanahaku?.trim()) { haku.t = hakuvaihtoehdot.vapaasanahaku.trim() }
        // if (hakuvaihtoehdot.projekti) { haku.p = hakuvaihtoehdot.projekti }

        const laajennetut = Object.entries(erittely.tilienKasittelyt).filter(([key, value]) => value === TilinKasittelyTaseErittelyssa.RAPORTIN_TILIKAUSI || value === TilinKasittelyTaseErittelyssa.VIIMEINEN_KUUKAUSI).map(([key, value]) => key)
        if (laajennetut.length > 0) {
          haku.f = laajennetut
        }

        const aikojenAlustaLaajennetut = Object.entries(erittely.tilienKasittelyt).filter(([key, value]) => value === TilinKasittelyTaseErittelyssa.KAIKKI_TILIKAUDET).map(([key, value]) => key)
        if (aikojenAlustaLaajennetut.length > 0) {
          haku.ff = aikojenAlustaLaajennetut
        }

        return this._firebaseLemonator.functionsCall<RaporttiRequest, RaporttiTaselaskelmaDataResponse>('kirjanpitoRaportitData', haku).then(res => {
          if (res.e) {
            throw new Error(res.e)
          }
          return res.data as RaporttiTaselaskelmaDataErittely
        }).then(data => {
          if (data?.r) {

            // IF ENDS IN LAST DAY, IS 1-last day of the same month.
            // IF ENDS IN SOME OTHER DAY, IS 1st of previous month to the given day
            let viimeisenKuukaudenAlku = this._dateService.localDateToNumber({ year: loppuu.year, month: loppuu.month, day: 1 })
            const viimeisenKuukaudenLoppu = this._dateService.localDateToNumber(loppuu) + 1
            if (this._dateService.kuukaudenViimeinenPaikallinen(loppuu).day !== loppuu.day) {
              const alkuLocal = this._dateService.lisaaKuukausiaPaikallinen({ year: loppuu.year, month: loppuu.month, day: 1 }, -1)
              viimeisenKuukaudenAlku = this._dateService.localDateToNumber(alkuLocal)
            }

            let grandParent: '1' | '2' = '1'
            for (const r of data.r) {

              const kasittely = erittely.tilienKasittelyt ? erittely.tilienKasittelyt[r.a] : null

              if (r.a === '2') {
                grandParent = '2'
              }
              r.grandParent = grandParent

              if (
                kasittely === TilinKasittelyTaseErittelyssa.RAPORTIN_TILIKAUSI ||
                kasittely === TilinKasittelyTaseErittelyssa.VIIMEINEN_KUUKAUSI ||
                kasittely === TilinKasittelyTaseErittelyssa.KAIKKI_TILIKAUDET
              ) {
                r.e = 1
              } else {
                delete r.e
              }

              r.kasittely = kasittely || TilinKasittelyTaseErittelyssa.EI_ERITELLA

              const teksti = erittely.tilienTekstit ? erittely.tilienTekstit[r.a] : ''
              if (teksti) {
                r.teksti = teksti
              } else {
                delete r.teksti
              }

              r.alkusaldoValittu = !!erittely.valitutAlkusaldot && !!erittely.valitutAlkusaldot[r.a]

              if (r.d) {
                if (r.kasittely === TilinKasittelyTaseErittelyssa.VIIMEINEN_KUUKAUSI) {
                  const jaljella: RaporttiDetailRowErittely[] = []
                  for (const row of r.d) {
                    if (row.p < viimeisenKuukaudenAlku) {
                      r.o1 += (row.d || 0) || -(row.k || 0)
                    } else if (row.p < viimeisenKuukaudenLoppu) { // It is on the range, add the sum itself
                      jaljella.push(row)
                    }
                  }
                  r.d = jaljella
                }
                const vientienIndeksit = new Map<string, number>()
                r.valittuSumma = 0
                if (r.alkusaldoValittu) {
                  // if (grandParent === '2') {
                  //   r.valittuSumma -= r.o1
                  // } else {
                  r.valittuSumma += r.o1
                  // }
                }
                for (const d of r.d) {
                  const index = vientienIndeksit.get(d.n) || 0
                  const avain = r.a + '_' + d.ka + '_' + index
                  d.avain = avain
                  const valittu = erittely.valitutViennit ? erittely.valitutViennit[avain] : null
                  if (valittu) {
                    d.valittu = 1
                    r.valittuSumma += (grandParent === '1' ? (d.d || 0) || -(d.k || 0) : (d.k || 0) || -(d.d || 0)) ?? 0
                  }
                  vientienIndeksit.set(d.n, index + 1)
                }
                r.valittuSumma = this._currencyService.roundHalfUp(r.valittuSumma, 2)
              }
            }
          }
          return data

        }).catch(err => {
          console.error('Failed to fetch report data', err)
          this._errorHandler.handleError(err)
        }).finally(() => {
          this._setLoadingFalse()
        })

      }),
      startWith<RaporttiTaselaskelmaDataErittely>({
        c: 'c1',
        r: [],
        c1: null,
        lukittu: false
      })
    )

    const maksutavatMapObservable: Observable<Map<string, AsiakkaanMaksutapa>> = this._asiakasService.nykyisenAsiakkaanKaikkiMaksutavatObservable.pipe(
      map(maksutavat => {
        const mapi = new Map<string, AsiakkaanMaksutapa>()
        for (const m of maksutavat) {
          mapi.set(m.tunniste + '', m)
        }
        return mapi
      })
    )

    this.taseDataObservable = combineLatest([
      this._tilitMapObservable,
      laskelmanRaakadataObservable,
      maksutavatMapObservable,
      this.tilinpaatosStatusObservable
    ]).pipe(
      map(([tiliMap, laskelmanRaakadata, maksutavatMap, status]) => {

        if (!tiliMap || !laskelmanRaakadata || !maksutavatMap) {
          return null
        }

        laskelmanRaakadata.lukittu = !!status?.rekisteroity

        if (laskelmanRaakadata.r?.length > 0) {
          for (const r of laskelmanRaakadata.r) {
            // || ['110', '170', '260', '280', '100', '116', '140', '150', '160'].includes(r.a)
            if (r.s && r.a.length < 4) {
              r.n = this._tilikarttaJaettuService.annaKirjanpitotilinNimi(tiliMap.get(r.a), 'fi') + ' yhteensä' // TODO: Add multi-lang support
            } else if (r.s) {
              r.n = 'YHTEENSÄ' // TODO: Add multi-lang support
            } else if (r.a.length > 3) {
              r.n = r.a + ' ' + this._tilikarttaJaettuService.annaKirjanpitotilinNimi(tiliMap.get(r.a), 'fi') // TODO: Add multi-lang support
            } else {
              r.n = this._tilikarttaJaettuService.annaKirjanpitotilinNimi(tiliMap.get(r.a), 'fi') // TODO: Add multi-lang support
            }
            if (r.d) {
              for (const d of r.d) {
                d.ma = maksutavatMap.get(d.m)?.nimi || ''
              }
            }
          }
        }
        return laskelmanRaakadata
      })
    )

    this.tilinpaatosStatusObservable.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(status => {
      if (!!status?.rekisteroity) {
        loppuuCtrl.disable()
      } else {
        loppuuCtrl.enable()
      }
    })

    this._erittelyObservable.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(erittely => {
      for (const accountNumber of Object.keys(erittely?.liitteet || {})) {
        this.cachedAttachmentsMap.set(accountNumber, erittely.liitteet[accountNumber])
      }
      if (erittely?.loppuu) {
        loppuuCtrl.setValue(this._dateService.localDateToDate(erittely.loppuu), { onlySelf: true })
      } else if (loppuuCtrl.value) {
        loppuuCtrl.setValue(null, { onlySelf: true })
      }
    })

  }

  tallennaLoppuu() {
    if (this.form.get('loppuu').value) {
      this._tallentamattomatSubject.value.loppuu = this._dateService.dateToLocalDate(this.form.get('loppuu').value)
    } else {
      this._tallentamattomatSubject.value.loppuu = null
    }
    this.autosave()
  }

  ngOnDestroy() {
    this._ngUnsubscribe.next()
    this._ngUnsubscribe.complete()
  }

  private _setLoadingTrue() {
    setTimeout(() => {
      this._ngZone.run(() => {
        this.loadingSubject.next(true)
      })
    }, 0)
  }

  private _setLoadingFalse() {
    setTimeout(() => {
      this._ngZone.run(() => {
        this.loadingSubject.next(false)
      })
    }, 0)
  }

  handleClick(event: MouseEvent, data: RaporttiTaselaskelmaDataErittely) {
    const element = event.target as HTMLElement
    if (element.dataset.n) {
      this.kirjaustaKlikattiin.emit({ kirjausnumero: element.dataset.n })
    } else if (element.tagName === 'INPUT') {
      // NOOP
      // } else if (element?.classList?.contains('n')) {
      // const tilinumero = element.parentElement.dataset.tnro
      // if (tilinumero?.length > 3) {
      //   const set = this._expandedAccountsBehaviorSubject.value
      //   if (set.has(tilinumero)) {
      //     set.delete(tilinumero)
      //   } else {
      //     set.add(tilinumero)
      //     this.paivitaArvotHiljaisestiSubject.next(this.paivitaArvotHiljaisestiSubject.value + 1)
      //   }
      //   this._expandedAccountsBehaviorSubject.next(set)
      //   event.preventDefault()
      //   event.stopPropagation()
      // }
      // }
    } else if (data?.r) {
      // console.log('Start')
      const tr = this._findTr(element)
      if (tr?.dataset.i !== undefined) {
        // console.log('I found')
        const closestBody = tr.parentElement as HTMLTableSectionElement
        if (closestBody?.dataset?.a) {
          // console.log('secondTd found', closestBody)
          const tilinumero = closestBody.dataset.a
          const tilinItem = data.r.find(a => a.a === tilinumero)
          if (tilinItem?.d) {
            // console.log('item found', tilinItem)
            const d = tilinItem.d[Number(tr?.dataset.i)]
            if (d.l) {
              delete d.l
            } else {
              d.l = true
            }
          }
        }
      }
    }
  }

  private _findTr(elem: HTMLElement): HTMLTableRowElement {
    if (elem?.tagName === 'TR') {
      return elem as HTMLTableRowElement
    } else if (elem?.parentElement?.tagName === 'TR') {
      return elem.parentElement as HTMLTableRowElement
    } else if (elem?.parentElement?.parentElement?.tagName === 'TR') {
      return elem.parentElement.parentElement as HTMLTableRowElement
    }
    return null
  }

  trackAccountRowByAccountNumberFn(index: number, item: RaporttiTaselaskelmaAccountRowErittely) {
    return item.a
  }

  /**
   * Ilman tätä raporttirivin checkboxia painettaessa ja datan päivittyessä tase-erittely scroll-positio pomppaa alkuun (Chromella).
   */
  trackDetailRowByKirjausNumeroFn(index: number, item: RaporttiDetailRowErittely) {
    return item.avain
  }

  tilinTekstiMuuttui(event: string, row: RaporttiTaselaskelmaAccountRowErittely) {
    this._tallentamattomatSubject.value.tilienTekstit[row.a] = event
    row.teksti = event
  }

  alkusaldonValintaMuuttui(event: boolean, row: RaporttiTaselaskelmaAccountRowErittely) {
    this._tallentamattomatSubject.value.valitutAlkusaldot[row.a] = !!event
    if (event) {
      row.alkusaldoValittu = true
      row.valittuSumma = this._currencyService.roundHalfUp(row.valittuSumma + row.o1, 2)
    } else {
      row.valittuSumma = this._currencyService.roundHalfUp(row.valittuSumma - row.o1, 2)
      row.alkusaldoValittu = false
    }
    this.autosave()
  }

  valitseKaikkiMuuttui(valinta: boolean, row: RaporttiTaselaskelmaAccountRowErittely) {
    this.alkusaldonValintaMuuttui(valinta, row)
    if (row.d) {
      let i = 0
      for (const d of row.d) {
        this.vienninValintaMuuttui(valinta, row, d, i)
        i++
      }
    }
    this.autosave()
  }

  vienninValintaMuuttui(event: boolean, row: RaporttiTaselaskelmaAccountRowErittely, vienti: RaporttiDetailRowErittely, index: number) {

    let count = 0
    for (let i = 0; i < index; i++) {
      if (row.d[i].n === vienti.n) {
        count++
      }
    }

    const avain = row.a + '_' + vienti.ka + '_' + count
    this._tallentamattomatSubject.value.valitutViennit[avain] = !!event
    const summa = (row.grandParent === '1' ? (vienti.d || 0) || -(vienti.k || 0) : (vienti.k || 0) || -(vienti.d || 0)) ?? 0
    if (event) {
      vienti.valittu = 1
      row.valittuSumma = this._currencyService.roundHalfUp(row.valittuSumma + summa, 2)
    } else {
      row.valittuSumma = this._currencyService.roundHalfUp(row.valittuSumma - summa, 2)
      delete vienti.valittu
    }
    this.autosave()
  }

  tilinKasittelyMuuttui(event: TilinKasittelyTaseErittelyssa, row: RaporttiTaselaskelmaAccountRowErittely) {

    this._tallentamattomatSubject.value.tilienKasittelyt[row.a] = event
    row.kasittely = event
    delete row.d

    /** Reset selected rows EVERY time the käsittely type changes.
   * This is needed in order to avoid situtations as in the following example:
   * 1. The users selects KAIKKI_TILIKAUDET and adds checkmarks to two rows from last year
   * 2. The user selects VIIMEINEN_KUUKAUSI (previous month)
   * 3. The rows checkmarked in point 1 remain active.
   * 4. The checkmarked rows get added to the final data.
   */
    this._resetValitutAlkusaldotAndValitutViennit(row.a)

    if (
      event === TilinKasittelyTaseErittelyssa.RAPORTIN_TILIKAUSI ||
      event === TilinKasittelyTaseErittelyssa.VIIMEINEN_KUUKAUSI ||
      event === TilinKasittelyTaseErittelyssa.KAIKKI_TILIKAUDET
    ) {
      row.e = 1
    } else {
      delete row.e
    }

    if (event !== TilinKasittelyTaseErittelyssa.OMA_TEKSTI) {
      this._tallentamattomatSubject.value.tilienTekstit[row.a] = ''
    }

    if (event !== TilinKasittelyTaseErittelyssa.ERILLINEN_LIITE) {
      this._latausVirheSubject.next(null)
    }

    this._tallentamattomatSubject.next(this._tallentamattomatSubject.value)

    if (event !== TilinKasittelyTaseErittelyssa.ERILLINEN_LIITE) {
      this.autosave()
    }

  }

  private _saveInFlight = false
  async tallenna(data: RaporttiTaselaskelmaDataErittely) {

    if (this._saveInFlight) {
      return
    }

    this._latausVirheSubject.next(null)

    this._saveInFlight = true
    this._ladataanService.aloitaLataaminen()

    try {
      await this._tallennaInternal()
      this._snackbar.open('Tase-erittely tallennettiin onnistuneesti.', 'OK', { duration: 5000, verticalPosition: 'bottom' })

    } catch (error) {
      if (error?.message === 'tilille-ei-liitteita') {
        return
      }
      this._errorHandler.handleError(error)
      this._snackbar.open('Tase-erittelyn tallentamisen aikana tapahtui virhe. Ole hyvä ja yritä uudelleen.', 'OK', { duration: 5000, verticalPosition: 'bottom' })

    } finally {
      this._ladataanService.lopetaLataaminen()
      this._saveInFlight = false
    }

  }

  private async _tallennaInternal() {
    const tallennettavaPromise = firstValueFrom(this._lopullinenErittelyObservable)
    const asiakasPromise = firstValueFrom(this._asiakasService.nykyinenAsiakasAvainObservable)
    const kirjanpitajaTiedotPromise = this._kirjautunutKayttajaService.getKirjanpitajanTiedot()
    const tilikausiPromise = firstValueFrom(this.selectedTilikausiObservable)

    const [tallennettava, asiakas, kirjanpitajaTiedot, tilikausi] = await Promise.all([tallennettavaPromise, asiakasPromise, kirjanpitajaTiedotPromise, tilikausiPromise])

    if (!asiakas || !tallennettava) {
      this._ladataanService.lopetaLataaminen()
      this._saveInFlight = false
      return
    }

    /** Get selected attachments from cached data */
    const kasittellytTilit = Object.keys(tallennettava.tilienKasittelyt)

    /** Reset liitteet data at each save */
    tallennettava.liitteet = {}

    for (const tili of kasittellytTilit) {

      if (tallennettava.tilienKasittelyt[tili] !== TilinKasittelyTaseErittelyssa.ERILLINEN_LIITE) {
        continue
      }

      if (!this.cachedAttachmentsMap.get(tili)?.filter(a => !a.poistettu)?.length) {
        /** Display error and stop save */
        this._latausVirheSubject.next('Tilille ' + tili + ' ei ole lisätty liitteitä.')
        throw new Error('tilille-ei-liitteita')
      }

      tallennettava.liitteet[tili] = this.cachedAttachmentsMap.get(tili)
    }

    tallennettava.luotu = this._timestampService.now()
    tallennettava.luoja = kirjanpitajaTiedot.uid

    const uri = this._kirjanpitoUriService.annaTilinpaatosTaseErittelyUri(asiakas.avain, tilikausi)
    const historiaUri = this._kirjanpitoUriService.annaTilinpaatosTaseErittelyHistoriaUri(asiakas.avain, tilikausi, this._firebaseLemonator.firestoreCreateId())

    const batch = this._firebaseLemonator.firestoreBatch()
    batch.set(uri, tallennettava)
    batch.set(historiaUri, tallennettava)
    return batch.commit()
  }

  async downloadPdf() {

    this._ladataanService.aloitaLataaminen()

    const nykyinenAsiakas = await firstValueFrom(this._asiakasService.nykyinenAsiakasObservable)
    const tilikausi = await firstValueFrom(this.selectedTilikausiObservable)
    const erittely = await firstValueFrom(this._lopullinenErittelyObservable)

    if (!nykyinenAsiakas || !tilikausi || !erittely) {
      return
    }

    const loppuu = this.annaLoppuuPvm(erittely, tilikausi)
    const haku: RaporttiRequest = {
      a: nykyinenAsiakas.avain,
      w: RaporttiType.TASE_ERITTELY,
      s: this._dateService.localDateToNumber(tilikausi.alkaa),
      e: this._dateService.localDateToNumber(loppuu),
      l: this._dateService.dateToLocalDateTime(new Date()),
      k: 'fi',
      ta: tilikausi.avain,
      datasource: 'raportointikirjaukset'
    }

    return this._firebaseLemonator.functionsCall<RaporttiRequest, RaporttiPdfResponse>('kirjanpitoRaportitPdf', haku).then(data => {
      if (data.e) {
        switch (data.e) {
          case 'unknown-report': {
            this._snackbar.open('Raporttia ei ole vielä implementoitu.', 'OK')
            break
          }
          case 'view-not-allowed': {
            this._snackbar.open('Käyttäjälla ei ole oikeutta nähdä tätä asiakasta.', 'OK')
            break
          }
          default: {
            this._snackbar.open('Tietojen lataaminen epäonnistui.', 'OK')
          }
        }
      } else {
        if (data.base64File) {
          this._fileSaverService.saveBase64As(data.base64File, data.fileName, 'pdf')
        } else {
          this._snackbar.open('PDF:n lataaminen epäonnistui.')
        }
      }
      this._ladataanService.lopetaLataaminen()
    })
  }

  // Load start when clicked
  public async lataa(event: Event, accountKey: string) {
    const inputTypeFile = event?.target as HTMLInputElement
    if (inputTypeFile?.files) {
      const list: FileList = inputTypeFile.files
      const tiedostot: NgxFileDropEntry[] = this._tiedostojenLataamisService.fileListToNgxFileDropEntries(list)
      try {
        await this._uploadFile(tiedostot, accountKey)
      } catch (err) {
        this._latausVirheSubject.next('Lataamisen aikana tapahtui virhe: ' + err)
        this._errorHandler.handleError(err)
      }
    }
  }

  /** Note! Will only upload the file to storage, metadata is cached until tallenna() is called */
  private async _uploadFile(tiedostot: NgxFileDropEntry[], accountKey: string) {

    this._ladataanService.aloitaLataaminen()

    this._latausVirheSubject.next(null)

    const asiakasPromise = firstValueFrom(this._asiakasService.nykyinenAsiakasAvainObservable)
    const tilikausiPromise = firstValueFrom(this.selectedTilikausiObservable)

    const [asiakas, tilikausi] = await Promise.all([asiakasPromise, tilikausiPromise])

    for (const tiedosto of tiedostot) {

      const fileEnding = this._tiedostojenLataamisService.getFileEndingFromNgxFileDropEntry(tiedosto)

      const allowedFileFormats = ['png', 'jpg', 'jpeg', 'pdf']
      if (!allowedFileFormats.includes(fileEnding.toLowerCase())) {
        this._latausVirheSubject.next(fileEnding + ' tiedoston lisääminen ei ole mahdollista. Sallitut muodot ovat: ' + allowedFileFormats.join(', ') + '.')
        this._ladataanService.lopetaLataaminen()
        return
      }

      const file = await this._tiedostojenLataamisService.getFile(tiedosto.fileEntry as any)

      const avain = this._firebaseLemonator.firestoreCreateId()
      const metadata: TaseErittelyLiite = {
        avain: avain,
        nimi: file.name,
        koko: file.size,
        ladattu: this._timestampService.now(),
        fileType: fileEnding,
        poistettu: false
      }

      const storageUri = this._kirjanpitoUriService.annaTaseErittelyLiiteStorageUri(asiakas.avain, tilikausi.avain, avain, fileEnding)
      await firstValueFrom(this._tiedostojenLataamisService.upload(this._firebaseLemonator, storageUri, file, 'tilinpaatos-liitetiedot-eu').doneObservable)

      if (!this.cachedAttachmentsMap.get(accountKey)) {
        this.cachedAttachmentsMap.set(accountKey, [])
      }
      this.cachedAttachmentsMap.get(accountKey).push(metadata)
    }
    this._ladataanService.lopetaLataaminen()
    this.autosave()

    this._changeDetectorRef.markForCheck()

  }

  async deleteAttachment(selected: TaseErittelyLiite, accountKey: string) {
    const editedAttachment = this.cachedAttachmentsMap.get(accountKey).find(liite => liite.avain === selected.avain)
    editedAttachment.poistettu = true
    this.autosave()

    this._ladataanService.lopetaLataaminen()
  }

  private _resetValitutAlkusaldotAndValitutViennit(accountKey: string) {
    this._tallentamattomatSubject.value.valitutAlkusaldot[accountKey] = null
    /** Null all detail rows of this account */
    const valitutViennitKeys = Object.keys(this._tallentamattomatSubject.value.valitutViennit || {})
    for (const valittuVientiKey of valitutViennitKeys) {
      if (valittuVientiKey.startsWith(accountKey + '_')) {
        this._tallentamattomatSubject.value.valitutViennit[valittuVientiKey] = null
      }
    }
  }

  async autosave() {
    this._tallennaInternal().then(() => {
      this._saveSuccessSubject.next(this._timestampService.now().toMillis())
    }).catch(error => {
      if (error?.message === 'tilille-ei-liitteita') {
        return
      }
      this._errorHandler.handleError(error)
    })
  }
}
