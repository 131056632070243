
import { KopioijaPalvelu } from '../kopioija.service'
import { FirestoreTosite, FirestoreTositteenKuva, FirestoreTositteenAlkuperainenTiedosto, TositteenMaksutiedot } from '../../model/tosite'
import { LocalDate } from '../../../_shared-core/model/common'

export class TositeKopioija {

  constructor(
    private kopioijaPalvelu: KopioijaPalvelu
  ) {

  }

  annaMaksutiedot(tosite: FirestoreTosite): TositteenMaksutiedot {
    if (tosite.maksutiedot) {
      return tosite.maksutiedot
    }
    return {
      amount: tosite.sum ?? null,
      dueDate: tosite.dueDate ?? null,
      iban: tosite.iban ?? null,
      bic: tosite.bic ?? null,
      identifier: tosite.avain ?? null,
      isSalary: false,
      maksunSaajanNimi: tosite.seller ?? null,
      message: null,
      reference: tosite.viitenumero ?? null,
      valuutta: tosite.currency ?? null,
      deliveryDate: tosite.deliveryDate ?? null
    }
  }

  kopioiTosite(tosite: FirestoreTosite): FirestoreTosite {

    const kuvat: { [key: string]: FirestoreTositteenKuva } = {}
    if (tosite.kuvat) {
      for (const kuvanAvain of Object.keys(tosite.kuvat)) {
        const kuva = tosite.kuvat[kuvanAvain]
        const kopio: FirestoreTositteenKuva = {
          avain: this.valueOrNull(kuva.avain),
          jarjestys: this.valueOrNull(kuva.jarjestys),
          poistettu: this.valueOrNull(kuva.poistettu),
          type: this.valueOrNull(kuva.type),
          alkuperaisenAvain: this.valueOrNull(kuva.alkuperaisenAvain)
        }
        if (kuva.lukittu) {
          kopio.lukittu = 1
        }
        kuvat[kuvanAvain] = kopio
      }
    }

    const alkuperaiset: { [key: string]: FirestoreTositteenAlkuperainenTiedosto } = tosite.alkuperaiset ? {} : null
    if (tosite.alkuperaiset) {
      for (const alkuperaisenAvain of Object.keys(tosite.alkuperaiset)) {
        const alkuperainen = tosite.alkuperaiset[alkuperaisenAvain]
        const kopio: FirestoreTositteenAlkuperainenTiedosto = {
          avain: this.valueOrNull(alkuperainen.avain),
          kuvakansio: this.valueOrNull(alkuperainen.kuvakansio),
          nimi: this.valueOrNull(alkuperainen.nimi),
          fileEnding: this.valueOrNull(alkuperainen.fileEnding),
          kasitelty: this.valueOrNull(alkuperainen.kasitelty)
        }
        if (alkuperainen.lukittu) {
          kopio.lukittu = 1
        }
        if (alkuperainen.jarjestys) {
          kopio.jarjestys = alkuperainen.jarjestys
        }
        if (alkuperainen.poistettu) {
          kopio.poistettu = alkuperainen.poistettu
        }
        if (alkuperainen.lahde) {
          kopio.lahde = alkuperainen.lahde
        }
        alkuperaiset[alkuperaisenAvain] = kopio
      }
    }

    const local: LocalDate = tosite.localPvm ? {
      day: this.valueOrNull(tosite.localPvm.day),
      month: this.valueOrNull(tosite.localPvm.month),
      year: this.valueOrNull(tosite.localPvm.year)
    } : null

    const uusiTosite: FirestoreTosite = {
      alvVahennysoikeus: this.valueOrNull(tosite.alvVahennysoikeus),
      avain: this.valueOrNull(tosite.avain),
      type: this.valueOrNull(tosite.type),
      paivitetty: this.valueOrNull(tosite.paivitetty),
      kayttajaUid: this.valueOrNull(tosite.kayttajaUid),
      kuvat: kuvat,
      lahde: this.valueOrNull(tosite.lahde),
      maksutapa: this.valueOrNull(tosite.maksutapa),
      pvm: this.valueOrNull(tosite.pvm),
      localPvm: this.valueOrNull(local),
      p: this.valueOrNull(tosite.p),
      selite: this.valueOrNull(tosite.selite),
      summa: this.valueOrNull(tosite.summa),
      sum: this.valueOrNull(tosite.sum),
      tallennettuKannykassa: this.valueOrNull(tosite.tallennettuKannykassa),
      vastaanotettuPalvelimelle: this.valueOrNull(tosite.vastaanotettuPalvelimelle),
      poistettu: this.valueOrNull(tosite.poistettu),
      alkuperaiset: this.valueOrNull(alkuperaiset),
      paivitykset: this.valueOrNull(tosite.paivitykset),
      kuvakansio: this.valueOrNull(tosite.kuvakansio)
    }

    // if (tosite.y) {
    //   uusiTosite.y = this.kopioijaPalvelu.cloneArrayDeep(tosite.y)
    // }

    if (tosite.l) {
      uusiTosite.l = true
    }

    if (tosite.x) {
      uusiTosite.x = true
    }

    if (tosite.z) {
      uusiTosite.z = true
    }

    if (tosite.c) {
      uusiTosite.c = tosite.c
    }

    if (tosite.m) {
      uusiTosite.m = tosite.m
    }

    if (tosite.w) {
      uusiTosite.w = tosite.w
    }

    if (tosite.b) {
      uusiTosite.b = this.kopioijaPalvelu.cloneObjectDeep(tosite.b)
    }

    if (tosite.tagTilitapahtumat) {
      uusiTosite.tagTilitapahtumat = this.kopioijaPalvelu.cloneArrayDeep(tosite.tagTilitapahtumat)
    }

    if (tosite.el) {
      uusiTosite.el = tosite.el
    }
    if (tosite.es) {
      uusiTosite.es = tosite.es
    }
    if (tosite.ev) {
      uusiTosite.ev = tosite.ev
    }
    if (tosite.einvoiceApprovalStatus) {
      uusiTosite.einvoiceApprovalStatus = tosite.einvoiceApprovalStatus
    }

    if (tosite.einvoiceApprovals) {
      uusiTosite.einvoiceApprovals = tosite.einvoiceApprovals
    }
    if (tosite.einvoiceCreatedFromOurInvoiceAsiakasAvain) {
      uusiTosite.einvoiceCreatedFromOurInvoiceAsiakasAvain = tosite.einvoiceCreatedFromOurInvoiceAsiakasAvain
    }
    if (tosite.einvoiceCreatedFromOurInvoiceAsiakasId) {
      uusiTosite.einvoiceCreatedFromOurInvoiceAsiakasId = tosite.einvoiceCreatedFromOurInvoiceAsiakasId
    }
    if (tosite.einvoiceCreatedFromOurInvoiceJuurilaskuAvain) {
      uusiTosite.einvoiceCreatedFromOurInvoiceJuurilaskuAvain = tosite.einvoiceCreatedFromOurInvoiceJuurilaskuAvain
    }
    if (tosite.einvoiceCreatedFromOurInvoiceKasiteltavaAvain) {
      uusiTosite.einvoiceCreatedFromOurInvoiceKasiteltavaAvain = tosite.einvoiceCreatedFromOurInvoiceKasiteltavaAvain
    }

    if (tosite.einvoiceForwardingStatus) {
      uusiTosite.einvoiceForwardingStatus = tosite.einvoiceForwardingStatus
    }
    if (tosite.viitenumero) {
      uusiTosite.viitenumero = tosite.viitenumero
    }
    if (tosite.seller) {
      uusiTosite.seller = tosite.seller
    }
    if (tosite.sellerId) {
      uusiTosite.sellerId = tosite.sellerId
    }
    if (tosite.dueDate) {
      uusiTosite.dueDate = tosite.dueDate
    }
    if (tosite.paymentDate) {
      uusiTosite.paymentDate = tosite.paymentDate
    }
    if (tosite.deliveryDate) {
      uusiTosite.deliveryDate = tosite.deliveryDate
    }
    if (tosite.invoiceDate) {
      uusiTosite.invoiceDate = tosite.invoiceDate
    }

    if (tosite.userApiMessage) {
      uusiTosite.userApiMessage = tosite.userApiMessage
    }

    if (tosite.paymentStatus) {
      uusiTosite.paymentStatus = tosite.paymentStatus
    }
    if (tosite.paymentRejection) {
      uusiTosite.paymentRejection = tosite.paymentRejection
    }
    if (tosite.paymentSentToGateway) {
      uusiTosite.paymentSentToGateway = tosite.paymentSentToGateway
    }
    if (tosite.iban) {
      uusiTosite.iban = tosite.iban
    }
    if (tosite.bic) {
      uusiTosite.bic = tosite.bic
    }
    if (tosite.paymentStrongAuthSessionKey) {
      uusiTosite.paymentStrongAuthSessionKey = tosite.paymentStrongAuthSessionKey
    }
    if (tosite.paymentStrongAuthUid) {
      uusiTosite.paymentStrongAuthUid = tosite.paymentStrongAuthUid
    }
    if (tosite.currency) {
      uusiTosite.currency = tosite.currency
    }
    if (tosite.paymentMaksutapa) {
      uusiTosite.paymentMaksutapa = tosite.paymentMaksutapa
    }
    if (tosite.maksutiedot) {
      uusiTosite.maksutiedot = this.kopioijaPalvelu.cloneObjectDeep(tosite.maksutiedot)
    }

    if (tosite.ulkoinenTunniste) {
      uusiTosite.ulkoinenTunniste = tosite.ulkoinenTunniste
    }

    return uusiTosite

  }

  private valueOrNull(value: any): any {
    return (value || value === 0 || value === false) ? value : null
  }

}
