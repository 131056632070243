
import { Injectable } from '@angular/core'
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router'

import { KirjautunutKayttajaService } from '../service/kirjautunut-kayttaja.service'

import { KirjanpitajanRooli } from '../../_jaettu/lemonator/model/kirjanpitaja'

import { Observable } from 'rxjs'
import { map, tap } from 'rxjs/operators'

@Injectable()
export class YberYllapitoGuard {

  constructor(
    private router: Router,
    private kirjautunutKayttajaService: KirjautunutKayttajaService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.kirjautunutKayttajaService.kirjanpitajanTiedotObservable.pipe(
      map(kirjanpitaja => {
        return (
          kirjanpitaja &&
          (
            kirjanpitaja.uid === 'AHiu6xZimwXgxmd3d5vqzbC8ce42' || // Jon
            kirjanpitaja.uid === 'dqUiLNmLRaMNbyNgMhWG1AUmVGI2' || // Valtteri
            kirjanpitaja.uid === '4sNRp7LvWTeZ9WTomoKOA9jD42y1' || // Ville
            kirjanpitaja.uid === 'nwGu99bXkWfX40Hk5nLVMKDn3lP2' || // Veera
            kirjanpitaja.uid === 'IbKDXwWiLLNbV4e0ZxtqrcmGPik2' || // Lauri
            kirjanpitaja.uid === 'H6KU6K9S39QaqDnpe1apaUV3dsz1' || // Elina P.
            kirjanpitaja.uid === '3d4UG33dpERg5CqyF1TmafusXNa2' // Ville H.
          )
        )
      }),
      tap(authenticated => {
        if (!authenticated) {
          this.router.navigate(['/kirjauduttupois'])
        }
      })
    )
  }
}

