<mat-dialog-content>
  <lemon-dialog-header>
    LogEntry
  </lemon-dialog-header>

  <div class="log-table">
    <div class="log-row">
      <div class="log-cell"><strong>Kirjanpitäjä:</strong></div>
      <div class="log-cell">{{ data?.kirjanpitajaNimi }}</div>
    </div>
    <div class="log-row">
      <div class="log-cell"><strong>Katsottu asiakas:</strong></div>
      <div class="log-cell"><a (click)="openCustomerLink(data?.asiakasAvain, $event)">
          {{ data?.asiakasNimi }}
        </a></div>
    </div>
    <div class="log-row">
      <div class="log-cell"><strong>Tyyppi:</strong></div>
      <div class="log-cell">{{ data?.eventType }}</div>
    </div>
    <div class="log-row">
      <div class="log-cell"><strong>Tehty toiminto:</strong></div>
      <div class="log-cell">{{ data?.eventName }}</div>
    </div>
    <div class="log-row">
      <div class="log-cell"><strong>Navigointityyli:</strong></div>
      <div class="log-cell">{{ data?.navigationMethod }}</div>
    </div>
    <div class="log-row">
      <div class="log-cell"><strong>Katsottu sisältö:</strong></div>
      <div class="log-cell">{{ data?.seen }}</div>
    </div>
    <div class="log-row">
      <div class="log-cell"><strong>URL:</strong></div>
      <div class="log-cell"><a (click)="openUrl(data.url, $event)" class="blue-link">
          {{ data?.url }}
        </a></div>
    </div>
    <div class="log-row">
      <div class="log-cell"><strong>Log id:</strong></div>
      <div class="log-cell"><a [href]="data?.logLink" target="_blank">{{ data?.insertId || '' }}</a></div>
    </div>
    <div class="log-row">
      <div class="log-cell"><strong>Aika:</strong></div>
      <div class="log-cell">{{ data?.timestamp | date:'medium' }}</div>
    </div>
  </div>
</mat-dialog-content>