import { Component, Inject, ErrorHandler, ViewChild, HostListener, OnDestroy, OnInit, signal } from '@angular/core'
import { AbstractControl, FormControl, FormGroup } from '@angular/forms'

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { MatInput } from '@angular/material/input'

import { Kirjanpitotili, AlvMaaritys, PaatilikartanProfiilinOsa, PaatilikartanProfiili } from 'app/_jaettu-lemonator/model/kirjanpito'
import { TilikarttaService } from 'app/_angular/service/tilikartta.service'
import { LadataanService } from 'app/_jaettu-angular/service/ladataan.service'
import { FormValidationService } from 'app/_jaettu-angular/service/form-validation.service'

import { firstValueFrom, Subject } from 'rxjs'
import { TiliForm } from './kirjanpitotili-muokkaa.dialog'
import { KirjautunutKayttajaService } from 'app/_angular/service/kirjautunut-kayttaja.service'

export interface KirjanpitotiliProfiiliMuokkaaDialogData {
  profiili: PaatilikartanProfiili
  profiilinOsa: PaatilikartanProfiilinOsa
  tili: Kirjanpitotili
}

interface ProfiiliForm {
  piilotettu: FormControl<boolean>
  nimiFi: FormControl<string>
  nimiEn: FormControl<string>
}

@Component({
  templateUrl: './profiili-kirjanpitotili-muokkaa.dialog.html'
})
export class KirjanpitotiliProfiiliMuokkaaDialog implements OnInit, OnDestroy {

  @ViewChild('nimiFiInput', { read: MatInput, static: true }) nimiFiInput: MatInput

  commonErrorSignal = signal<string>('')
  tiliSignal = signal<Kirjanpitotili>(null)
  form: FormGroup<TiliForm>
  profiiliForm: FormGroup<ProfiiliForm>
  name = '' + Math.random()

  private _ngUnsubscribe: Subject<void> = new Subject<void>()

  vertaaAlvMaarityksia: (a: AlvMaaritys, b: AlvMaaritys) => boolean = (a: AlvMaaritys, b: AlvMaaritys): boolean => {
    return a && b && a.tunniste === b.tunniste
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) private _data: KirjanpitotiliProfiiliMuokkaaDialogData,
    private _dialogRef: MatDialogRef<KirjanpitotiliProfiiliMuokkaaDialog>,
    private _errorHandler: ErrorHandler,
    private _tilikarttaService: TilikarttaService,
    private _ladataanService: LadataanService,
    private _validationService: FormValidationService,
    private _kirjautunutKayttajaService: KirjautunutKayttajaService
  ) { }

  ngOnInit() {

    this.tiliSignal.set(this._data.tili)

    // Create form
    this.profiiliForm = new FormGroup({
      nimiFi: new FormControl<string>({ value: this._data.profiilinOsa?.nimi?.fi ?? null, disabled: false }, []),
      nimiEn: new FormControl<string>({ value: this._data.profiilinOsa?.nimi?.en ?? null, disabled: false }, []),
      piilotettu: new FormControl<boolean>({ value: !!this._data.profiilinOsa?.piilota, disabled: false }, [])
    })

    this.nimiFiInput.focus()

  }

  get profiiliNimiFi(): AbstractControl<string> {
    return this.profiiliForm.get('nimiFi')
  }

  get profiiliNimiEn(): AbstractControl<string> {
    return this.profiiliForm.get('nimiEn')
  }

  @HostListener('document:keydown.esc')
  peruuta() {
    this._dialogRef.close()
  }

  @HostListener('document:keydown.enter')
  async tallenna() {

    if (this.profiiliForm.invalid || !this.profiiliForm.valid) {
      this._validationService.merkitseKokoLomakeKosketuksi(this.form)
      return
    }

    this._ladataanService.aloitaLataaminen()

    const kirjanpitajanTiedot = await firstValueFrom(this._kirjautunutKayttajaService.kirjanpitajanTiedotObservable)

    try {

      const piilota = !!this.profiiliForm.get('piilotettu').value
      const nimiFi = this.profiiliNimiFi.value?.trim()
      const nimiEn = this.profiiliNimiEn.value?.trim()

      const osa: PaatilikartanProfiilinOsa = {}
      if (nimiFi || nimiEn) {
        osa.nimi = {
          'fi': nimiFi ?? null,
          'en': nimiEn ?? null
        }
      }
      if (piilota) {
        osa.piilota = 1
      }

      // null poistaa osan
      const tallennettava: PaatilikartanProfiilinOsa = Object.keys(osa).length > 0 ? osa : null
      await this._tilikarttaService.tallennaPaatilikartanProfiilinOsa(this._data.profiili, this._data.tili, tallennettava, kirjanpitajanTiedot.uid)

    } catch (error) {
      this.commonErrorSignal.set('Tallentamisen aikana tapahtui virhe. Ole hyvä ja ilmoita tästä ylläpitäjälle.')
      this._errorHandler.handleError(error)
    } finally {
      this._ladataanService.lopetaLataaminen()
      this._dialogRef.close()
    }
  }

  ngOnDestroy() {
    this._ngUnsubscribe.next()
    this._ngUnsubscribe.complete()
  }

}
