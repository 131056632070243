import { Component, Inject, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'

export interface KirjanpitoValmisDialogData {
  year: number
}

@Component({
  templateUrl: './kirjanpito-valmis.dialog.html'
})
export class KirjanpitoValmisDialog implements OnInit {

  year: number

  constructor(
    @Inject(MAT_DIALOG_DATA) private _data: KirjanpitoValmisDialogData
  ) { }

  ngOnInit() {
    this.year = this._data.year
  }
}

