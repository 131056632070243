import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'

@Component({
  templateUrl: './logging-data.dialog.html',
  styleUrls: ['./logging-data.dialog.css'],
})
export class LoggingDataDialog {

  link: string

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }

  openUrl(link: string, event: Event) {
    event.preventDefault()
    event.stopPropagation()
    window.open('https://lemonator.lemontree.fi/' + link)
  }

  openCustomerLink(asiakasAvain: string, event: Event) {
    event.preventDefault()
    event.stopPropagation()
    if (asiakasAvain) {
      window.open('https://lemonator.lemontree.fi/asiakkaat/' + asiakasAvain + '/asiakastiedot', '_blank')
    }
  }

}