<app-confetti [show]="true"></app-confetti>
<div style="text-align: center; max-width: 630px;">
  <div mat-dialog-content>

    <lemon-dialog-header>
      Tilikausi paketissa
    </lemon-dialog-header>

    <div style="padding: 0px 8%;">
      <!-- <div>
        {{'reports-lemonaid.poytakirja-allekirjoitettu-tilikausi-paketissa' | translate: { year: year } | async }}
      </div> -->

      <img id="tilikausi-box" [src]="'assets/tilikausi-'+ year + '-box.png'" style="width: 75%;" onload="this.style.opacity=1;" alt="" />
    </div>

  </div>
  <div mat-dialog-actions style="padding-left: 0; padding-right: 0;">
    <button class="primarybutton" mat-button mat-dialog-close>{{'yleiset.jatka' | translate | async }}</button>
  </div>
</div>