import { Component, ErrorHandler, HostListener, OnDestroy, OnInit } from '@angular/core'
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms'
import { MatDialog } from '@angular/material/dialog'
import { AsiakasService, LokalisoituYritysmuoto } from 'app/_angular/service/asiakas/asiakas.service'
import { FirebaseLemonator } from 'app/_angular/service/firebase-lemonator.service'
import { KirjanpitajaService } from 'app/_angular/service/kirjanpitaja/kirjanpitaja.service'
import { FormValidationService } from 'app/_jaettu-angular/service/form-validation.service'
import { LadataanService } from 'app/_jaettu-angular/service/ladataan.service'
import { AnnaAsiakkaidenNimitiedotPyynto, AnnaAsiakkaidenNimitiedotVastaus, AsiakashakuAsiakas, AsiakashakuRequest, AsiakashakuResponse, AsiakkaanNimitiedot, EiYhtiomuotoaYhtiomuoto } from 'app/_jaettu-lemonator/model/asiakas'
import { KirjanpitajanNimitiedot } from 'app/_jaettu-lemonator/model/kirjanpitaja'
import { LogEntry, LoggingListEntriesRequest, LoggingListEntriesResponse, StructForLogs } from 'app/_jaettu-lemonator/model/logging'
import { Yritysmuoto } from 'app/_jaettu/model/kayttaja'
import { DateService } from 'app/_shared-core/service/date.service'
import { BehaviorSubject, Observable, Subject, combineLatest, forkJoin, from, map, switchMap, takeUntil } from 'rxjs'
import { LoggingDataDialog } from './logging-data.dialog'

export enum EventType {
  NAVIGOI = 'navigoi',
  KLIKKASI_TOIMINTOA = 'klikkasi toimintoa'
}
interface HakuForm {
  start: FormControl<Date>
  end: FormControl<Date>
  kirjanpitajaUid?: FormControl<string>
  eventType?: FormControl<string>
  navigationMethod?: FormControl<string>
  eventName?: FormControl<string>
  haku?: FormControl<string>
  yhtiomuoto?: FormControl<string>
  asiakasAvain?: FormControl<string>
}

@Component({
  selector: 'app-logging',
  templateUrl: './logging.component.html',
  styleUrls: ['./logging.component.css']
})
export class LoggingComponent implements OnInit, OnDestroy {

  hakuform: FormGroup<HakuForm>

  private ngUnsubscribe: Subject<void> = new Subject<void>()
  inputname = 'asdfewqrphoki' + Math.random()
  kirjanpitajienNimitiedotObservable: Observable<KirjanpitajanNimitiedot[]>
  asiakkaatMapObservable: Promise<Map<string, AsiakkaanNimitiedot>>

  showNavigationStyle: boolean = false
  showAction: boolean = false

  hakuControl: FormControl<string> = new FormControl('')
  yhtiomuotoControl: FormControl<Yritysmuoto | EiYhtiomuotoaYhtiomuoto> = new FormControl(null)
  paivitaValidaatiot: any = null
  tulokset: BehaviorSubject<AsiakashakuAsiakas[]> = new BehaviorSubject(null)
  yritysmuodot: LokalisoituYritysmuoto[]

  valittuAsiakas: AsiakashakuAsiakas
  selectedRow: any

  commonError: string
  loggingEntries: LogEntry[]
  displayedColumns: string[] = ['kirjanpitajaUid', 'asiakasAvain', 'eventType', 'eventName', 'navigationMethod', 'seen', 'url', 'timestamp', 'insertId']

  constructor(
    private _dateService: DateService,
    private _kirjanpitajaService: KirjanpitajaService,
    private _validationService: FormValidationService,
    private _ladataanService: LadataanService,
    private _errorHandler: ErrorHandler,
    private _firebase: FirebaseLemonator,
    private _asiakasService: AsiakasService,
    private _firebaseLemonator: FirebaseLemonator,
    private _dialog: MatDialog
  ) {
  }

  ngOnInit(): void {

    this.yritysmuodot = this._asiakasService.yritysmuodotJaEiTiedossa

    this.hakuform = new FormGroup<HakuForm>({
      start: new FormControl<Date>((this._dateService.lisaaKuukausia(new Date(), -1)), Validators.required),
      end: new FormControl<Date>(new Date(), Validators.required),
      kirjanpitajaUid: new FormControl<string>('all'),
      eventType: new FormControl<string>('all'),
      navigationMethod: new FormControl<string>('all'),
      eventName: new FormControl<string>('all'),

      /* Asiakkaan haku */
      haku: this.hakuControl,
      yhtiomuoto: this.yhtiomuotoControl,
      asiakasAvain: new FormControl<string>('all')
    })

    this.kirjanpitajienNimitiedotObservable = this._kirjanpitajaService.kirjanpitajienNimitiedotObservable.pipe(
      map(kirjanpitajat => {
        if (kirjanpitajat?.length) {
          return kirjanpitajat.sort((a, b) => {
            if (a.etunimi !== b.etunimi) {
              return a.etunimi.localeCompare(b.etunimi)
            }
            return a.sukunimi.localeCompare(b.sukunimi)
          })
        }
        return []
      })
    )

    this.asiakkaatMapObservable = this._firebaseLemonator.functionsCall<AnnaAsiakkaidenNimitiedotPyynto, AnnaAsiakkaidenNimitiedotVastaus>('annaAsiakkaidenNimitiedot', {}, { timeout: 540 * 1000 }).then(
      asiakkaatVastaus => {
        const m: Map<string, AsiakkaanNimitiedot> = new Map()
        if (asiakkaatVastaus?.nimitiedot) {
          for (const a of asiakkaatVastaus.nimitiedot) {
            m.set(a.avain, a)
          }
        }
        return m
      }
    )
  }

  ngOnDestroy(): void {
  }

  get nimi() {
    return this.get('nimi')
  }

  get asiakasAvain() {
    return this.hakuform.get('asiakasAvain')
  }

  private get(name: string): AbstractControl {
    if (this.hakuform) {
      return this.hakuform.get(name)
    }
    return null
  }

  get haku(): FormControl<string> {
    return this.hakuControl
  }

  onTypeChange(event: any) {
    if (event.value === EventType.NAVIGOI) {
      this.showNavigationStyle = true
      this.showAction = false
    } else if (event.value === EventType.KLIKKASI_TOIMINTOA) {
      this.showNavigationStyle = false
      this.showAction = true
    } else {
      this.showNavigationStyle = false
      this.showAction = false
    }
  }

  /* Hae asiakas */
  async search() {

    if (this.hakuform.invalid) {
      this._validationService.merkitseKokoLomakeKosketuksi(this.hakuform)
      this.paivitaValidaatiot = {}
      return
    }

    this._ladataanService.aloitaLataaminen()

    try {
      const req: AsiakashakuRequest = {}
      if (this.hakuControl.value) { req.teksti = this.hakuControl.value }
      if (this.yhtiomuotoControl.value) { req.yhtiomuoto = this.yhtiomuotoControl.value }
      const resp = await this._firebase.functionsCall<AsiakashakuRequest, AsiakashakuResponse>('asiakasHaeAsiakasta', req, { timeout: 540 * 1000 })
      if (resp.e || !resp.asiakkaat) {
        throw new Error('Error: ' + resp.e)
      }
      resp.asiakkaat.sort((a, b) => {
        const lowera = a.n.toLowerCase()
        const lowerb = b.n.toLowerCase()
        if (lowera !== lowerb) {
          return lowera.localeCompare(lowerb)
        }
        return a.y.localeCompare(b.y)
      })
      this.tulokset.next(resp.asiakkaat)
    } catch (err) {
      this._errorHandler.handleError(err)
    } finally {
      this._ladataanService.lopetaLataaminen()
    }

  }

  chooseCustomerForSearch(asiakas: AsiakashakuAsiakas) {
    const currentSelection = this.hakuform.get('asiakasAvain')?.value

    if (currentSelection === asiakas.k) {
      this.hakuform.get('asiakasAvain')?.setValue(null)
      this.valittuAsiakas = null
    } else {
      this.hakuform.get('asiakasAvain')?.setValue(asiakas.k)
      this.valittuAsiakas = asiakas
    }
  }

  /** Korjaa mat selectin ongelman */
  compareFn(): boolean {
    return true
  }

  /* Hae logit Google Cloud logging bucketista */
  async haeLogit() {

    if (this.hakuform.get('asiakasAvain')?.value === 'search') {
      alert('Valitse asiakas ennen hakua.')
      return
    }

    const start = this.hakuform.get('start').value
    const end = this.hakuform.get('end').value
    const startDate = new Date(start).toISOString()
    const endDate = new Date(end).toISOString()

    const suodatusParametrit: LoggingListEntriesRequest = {
      start: startDate,
      end: endDate
    }

    const optionalFilterFields = ['kirjanpitajaUid', 'asiakasAvain', 'eventType', 'navigationMethod']

    for (const field of optionalFilterFields) {
      const value = this.hakuform.get(field).value

      if (value !== 'all') {
        suodatusParametrit[field] = value
      }
    }

    try {

      this.commonError = ''
      this._ladataanService.aloitaLataaminen()

      const vastaus = await this._firebaseLemonator.functionsCall<LoggingListEntriesRequest, LoggingListEntriesResponse>('getLoggingEntriesCloudLogging', suodatusParametrit)
      this.loggingEntries = vastaus.entries || []

      combineLatest([
        from(this.asiakkaatMapObservable),
        this.kirjanpitajienNimitiedotObservable
      ]).pipe(
        takeUntil(this.ngUnsubscribe),
        switchMap(([asiakkaatMap, kirjanpitajat]) => {
          return from(Promise.all(this.loggingEntries.map(entry =>
            this.formatLogEntry(entry, asiakkaatMap, kirjanpitajat)
          )))
        })
      ).subscribe((loggingEntries) => {
        this.loggingEntries = loggingEntries

        if (this.loggingEntries.length === 0) {
          this.commonError = 'Ei tuloksia annetuilla hakuehdoilla'
        }
        this._ladataanService.lopetaLataaminen()
      })

    } catch (err) {
      this._ladataanService.lopetaLataaminen()
      this.commonError = 'Haussa tapahtui virhe.'
      this._errorHandler.handleError(new Error('Failed to retrieve logs data: ' + (err?.message || '')))
    }
  }

  async formatLogEntry(entry: LogEntry, asiakkaatMap: Map<string, any>, kirjanpitajat: any[]): Promise<any> {

    const jsonPayload = entry.jsonPayload as StructForLogs

    const asiakasAvain = jsonPayload.asiakasAvain?.stringValue
    const kirjanpitajaUid = jsonPayload.kirjanpitajaUid?.stringValue
    const eventType = jsonPayload.navigationEvent?.structValue?.fields?.eventType?.stringValue || ''
    const navigationMethod = jsonPayload.navigationEvent?.structValue?.fields?.navigationMethod?.stringValue || ''
    const seen = jsonPayload.navigationEvent?.structValue?.fields?.seen?.stringValue || ''
    const eventName = jsonPayload.navigationEvent?.structValue?.fields?.eventName?.stringValue || ''
    const url = jsonPayload.url?.stringValue || ''

    const asiakas = asiakkaatMap.get(asiakasAvain)
    const kirjanpitaja = kirjanpitajat.find(k => k.avain === kirjanpitajaUid)

    const logLink = this.getLogLinkCloudLogging(entry.insertId)

    return {
      ...entry,
      asiakasAvain,
      asiakasNimi: asiakas ? `${asiakas.nimi}` : 'Ei asiakasta/Tuntematon',
      kirjanpitajaNimi: kirjanpitaja ? `${kirjanpitaja.etunimi} ${kirjanpitaja.sukunimi}` : 'Tuntematon',
      eventType,
      navigationMethod,
      seen,
      eventName,
      url,
      logLink
    }
  }

  getLogLinkCloudLogging(insertId: string) {
    return 'https://console.cloud.google.com/logs/query;query=insertId%3D%22' + insertId + '%22;storageScope=storage,projects%2Feu-lemonator%2Flocations%2Feurope-west1%2Fbuckets%2Faudit-trail%2Fviews%2F_AllLogs;duration=P30D?project=eu-lemonator' // huom! linkki ei suoraan se mitä GCL tarjoaa "Copy link"
  }

  openCustomerLink(asiakasAvain: string, event: Event) {
    event.preventDefault()
    event.stopPropagation()
    if (asiakasAvain) {
      window.open('https://lemonator.lemontree.fi/asiakkaat/' + asiakasAvain + '/asiakastiedot', '_blank')
    }
  }

  openUrl(link: string, event: Event) {
    event.preventDefault()
    event.stopPropagation()
    window.open('https://lemonator.lemontree.fi/' + link)
  }

  katsoTiedot(rivi: any) {
    this.selectedRow = rivi

    const dialogRef = this._dialog.open(LoggingDataDialog, {
      data: rivi
    })

    dialogRef.afterClosed().subscribe(() => {
      this.selectedRow = null
    })
  }


}
