import { LocalDate, NumberDate, NumberMonth } from '../../_shared-core/model/common'
import { DateService } from '../../_shared-core/service/date.service'
import { KopioijaPalvelu } from '../../_jaettu/service/kopioija.service'
import { KirjanpitajanTiimi, KirjanpitajanTiiminJasen } from '../model/kirjanpitaja'

// { alku: 100101, kirjanpitajaAvain: 'aJhkMbFHHyRuFSdrPOqKxUbUnqZ2', loppu: null }, // Anna Tuominen
// { alku: 100101, kirjanpitajaAvain: 'wZsJcE7KTZO4J2TbyeoSBgPMTFs2', loppu: null }, // Anna-Liisa Ihanamäki
// { alku: 100101, kirjanpitajaAvain: 'yDm2TEBOU9QF4JICxOcr15emtuj1', loppu: null }, // Anne Oksanen
// { alku: 100101, kirjanpitajaAvain: '4NAixSr2HHMw8D9Trf2hdfeV5MC2', loppu: null }, // Anni Helenius
// { alku: 100101, kirjanpitajaAvain: '259AJG2HoJctamvUlUYHIC1uonU2', loppu: null }, // Annika Toivonen
// { alku: 100101, kirjanpitajaAvain: 'nDxw9qjGm5dTmXMsxhz3Zr39o382', loppu: null }, // Anssi Suominen
// { alku: 100101, kirjanpitajaAvain: '5ttuVKghGGQQxzk97xdV16iha5o1', loppu: null }, // Antti Laakso
// { alku: 100101, kirjanpitajaAvain: '9CBQy1BvVLSCv5EsSCmGLGZx0Nj2', loppu: null }, // Dina Rissanen
// { alku: 100101, kirjanpitajaAvain: 'EdFoKOQmOCM2vmDTx2c8rEO9zkG3', loppu: null }, // Eeva Häkkinen
// { alku: 100101, kirjanpitajaAvain: 'v1krwtHAOmQOnKBfGXHrsIP7mhD3', loppu: null }, // Elina Brusila
// { alku: 100101, kirjanpitajaAvain: 'QjLhS5wo1sWpL3fracIQIBwBKuu2', loppu: null }, // Elina Mohell
// { alku: 100101, kirjanpitajaAvain: 'ZHx7TJeK5xc159g2O82VTfa2QRz2', loppu: null }, // Eva Hakola
// { alku: 100101, kirjanpitajaAvain: 'eXjNZdpfC0Y0pSrAX8q35BK3p3l2', loppu: null }, // Eveliina Ansas
// { alku: 100101, kirjanpitajaAvain: 'MYK3y6Y7xfhaYjTIf79hI9M0JwB3', loppu: null }, // Fanni Kurkikangas
// { alku: 100101, kirjanpitajaAvain: 'N920cZxCEHMA9e0C9V3OTGyyK9n2', loppu: null }, // Hanna Lahtinen
// { alku: 100101, kirjanpitajaAvain: 'tst8k0sa81QUu2OrOtHiBPAbPZh1', loppu: null }, // Hanna Räsänen
// { alku: 100101, kirjanpitajaAvain: 'V8MDsQQnSIMrgcOL0QwjEjekfP02', loppu: null }, // Ida Alavire
// { alku: 100101, kirjanpitajaAvain: 'yDO4mvcfPGbKXvxWPpQcYwS5ezk2', loppu: null }, // Ilari Heinonen
// { alku: 100101, kirjanpitajaAvain: 'COPVwjwcmiMwabLOJH4JjH57amA3', loppu: null }, // Jenna Nordling
// { alku: 100101, kirjanpitajaAvain: 'JqbumyyUcphERklcjAIEVMnnV3r2', loppu: null }, // Jenni Lampila
// { alku: 100101, kirjanpitajaAvain: '29Fxm3m4KIO0qvIxbsgJSZdAJUp2', loppu: null }, // Jenny Mattila
// { alku: 100101, kirjanpitajaAvain: 'MpY7LLRDq9XuHp90NskKgJIcpOi2', loppu: null }, // Johanna Hämäläinen
// { alku: 100101, kirjanpitajaAvain: 'h279Xe3JnVMBLmtBuOSMeOQvu2K3', loppu: null }, // Johanna Kristo
// { alku: 100101, kirjanpitajaAvain: 'AHiu6xZimwXgxmd3d5vqzbC8ce42', loppu: null }, // Jon Grönholm
// { alku: 100101, kirjanpitajaAvain: 'xmjSCbTkURP5SmqyEXceXnD16383', loppu: null }, // Jussi Långström
// { alku: 100101, kirjanpitajaAvain: 'dUXXSpkqOjbiY1VsTiPsMNBrBmM2', loppu: null }, // Katja Kaipainen
// { alku: 100101, kirjanpitajaAvain: 'hjjoCAaDCOegrLJHQyJdZvquMgu2', loppu: null }, // Kirsi Ahvenainen
// { alku: 100101, kirjanpitajaAvain: 'YWtOHDtbKrMo0S3lhGcpIHMagmU2', loppu: null }, // Kirsi Lahti
// { alku: 100101, kirjanpitajaAvain: 'mup7lN7Cb8hXntHyaEmrNHCf3413', loppu: null }, // Kirsi Rönkä
// { alku: 100101, kirjanpitajaAvain: 'kopIBXtRr6c1A71FhxazvpKRLdA3', loppu: null }, // Kristiina Jutila
// { alku: 100101, kirjanpitajaAvain: 'UwpmuMpJXubiD5B67BjIxf3MDP33', loppu: null }, // Ksenia Kulikova
// { alku: 100101, kirjanpitajaAvain: 'e0Ravrw1L3cBTKfYSG1D6U8sU2o1', loppu: null }, // Laura Pajunen
// { alku: 100101, kirjanpitajaAvain: 'IbKDXwWiLLNbV4e0ZxtqrcmGPik2', loppu: null }, // Lauri Teder
// { alku: 100101, kirjanpitajaAvain: 't1BauO6YTbf2cQoN1y8eREEmGlJ3', loppu: null }, // Leena Nurmi
// { alku: 100101, kirjanpitajaAvain: 'lemonator_system', loppu: null }, // Lemonator System
// { alku: 100101, kirjanpitajaAvain: 'nwEYCXNoWBcfLoFl9jTRcX7i9583', loppu: null }, // Maija Minkkinen
// { alku: 100101, kirjanpitajaAvain: 't8gVl4VCqaWCDTKEVjQx9NXa5m22', loppu: null }, // Maija Vuorimaa
// { alku: 100101, kirjanpitajaAvain: 'bw6BssZhEaZyyrKb0z1VB9jNdAD2', loppu: null }, // Maria Aaltonen
// { alku: 100101, kirjanpitajaAvain: '3J8SOb4AYubt6Lmf3xBIibdXqC43', loppu: null }, // Maria Laakso
// { alku: 100101, kirjanpitajaAvain: 'ihaEWAUd48O5MysXskvBtz0xzKl1', loppu: null }, // Marika Väänänen
// { alku: 100101, kirjanpitajaAvain: 'IrVDS3rh1kN05KC5K3GOnAvgNUH3', loppu: null }, // Milla Nyman
// { alku: 100101, kirjanpitajaAvain: 'ndCFrkL2QROXGAkPxqMTiE0QxHf1', loppu: null }, // Milla Vaajanen
// { alku: 100101, kirjanpitajaAvain: 'URU3mqXHMcXRXEKnLmSGxmgPgW62', loppu: null }, // Mirka Helanen
// { alku: 100101, kirjanpitajaAvain: '1kVFV9vcYGfGJydhVWSchf7CshG3', loppu: null }, // Natalja Telkonen
// { alku: 100101, kirjanpitajaAvain: '5YQcFTlDU0TdfPB2jrFccmDn1Qb2', loppu: null }, // Nina Nieminen
// { alku: 100101, kirjanpitajaAvain: 'PFM9yAgmavgjVktzTH0lKVi5X513', loppu: null }, // Otto Jakobsson
// { alku: 100101, kirjanpitajaAvain: '6ZGrFsEXGDc8ZRgbO3V7foheest2', loppu: null }, // Palkat Lemon Tree
// { alku: 100101, kirjanpitajaAvain: '9d85Vjpw20f3L67xMeOZprqwlmx1', loppu: null }, // Paula Dillingham-Edwards
// { alku: 100101, kirjanpitajaAvain: 'hZo5I0KWkrfs7eoIpAhx4foqXCC2', loppu: null }, // Petra Pasula
// { alku: 100101, kirjanpitajaAvain: 't86aMdfTkicMvOiKGGjKm4m3Rwm1', loppu: null }, // Sales Lemon Tree
// { alku: 100101, kirjanpitajaAvain: 'QL4CBlJTLdQLtbb4k8AURFYeg3I3', loppu: null }, // Sanna Vihersaari
// { alku: 100101, kirjanpitajaAvain: 'AESoWnwA2WQwu3z6J59x5og2NqP2', loppu: null }, // Sara Lindberg
// { alku: 100101, kirjanpitajaAvain: '3jHPVDkeSuW8maXrBhwqIGJc9oW2', loppu: null }, // Satu Kanerva
// { alku: 100101, kirjanpitajaAvain: 'S6gz1Xd4LmcHSovg3VoyjBMwAg53', loppu: null }, // Satu Sunikka
// { alku: 100101, kirjanpitajaAvain: 'k66SakVVn7NiZ62viBAXRatt77s1', loppu: null }, // Sirpa Front
// { alku: 100101, kirjanpitajaAvain: 'igeUmwRVwyLYQPWQXlLXai2927K2', loppu: null }, // Susanna Taipalus
// { alku: 100101, kirjanpitajaAvain: 'TJh1u0pjY8YqGa1KAF2chNWbX7h2', loppu: null }, // Susanna Vaarala
// { alku: 100101, kirjanpitajaAvain: 'c6qle3w3cJaz6Q3Yxz9x0d8d0h02', loppu: null }, // Suvi Piippo
// { alku: 100101, kirjanpitajaAvain: 'FkWECO8otlf2H1275jzUzMR9xSf1', loppu: null }, // Taina Rautio
// { alku: 100101, kirjanpitajaAvain: 'uekRGWgeLqecXk0Gh8el0s85SEt1', loppu: null }, // Taneli Testinen
// { alku: 100101, kirjanpitajaAvain: 'cVYOWEOwXxcTRBAxdChEK6IPM3u2', loppu: null }, // Tarja Nieminen
// { alku: 100101, kirjanpitajaAvain: 'clvzNT47oFZ7UjHN0I8agRhc6DR2', loppu: null } // Jenni Siekkinen
// { alku: 100101, kirjanpitajaAvain: 'L5JPBR5hIYZMHNvBw4ban8KV3nv2', loppu: null }, // Tarmo Sundström
// { alku: 100101, kirjanpitajaAvain: 'l9GGjlUMnIdSHTLIrZQyOv4ldt23', loppu: null }, // Taru Pirttiaho
// { alku: 100101, kirjanpitajaAvain: 'N2uyODovqCfvCoIDzRCtWZe7HKn1', loppu: null }, // Taru Puupponen
// { alku: 100101, kirjanpitajaAvain: 'J2KnvMmNNPRcD5APwa63tpZvlPi1', loppu: null }, // Terhi Soukka
// { alku: 100101, kirjanpitajaAvain: '1Fxvdnvu81b4huduThSRXtOYSOi2', loppu: null }, // Testi Testi
// { alku: 100101, kirjanpitajaAvain: 'dqUiLNmLRaMNbyNgMhWG1AUmVGI2', loppu: null }, // Valtteri Virtanen
// { alku: 100101, kirjanpitajaAvain: '4sNRp7LvWTeZ9WTomoKOA9jD42y1', loppu: null }, // Ville Virtanen
// { alku: 100101, kirjanpitajaAvain: 'njaaiWR8pXOMo5HUajKOnUXOLi52', loppu: null }, // Virpi Kujala-Kainulainen
// { alku: 100101, kirjanpitajaAvain: '4FYKDMCWZfcMU4WqmuVwJ9H9HYe2', loppu: null }, // Wenyang Xu
// { alku: 100101, kirjanpitajaAvain: 'QgPvtcCjoOdf6Zg7lgMwqLWp2BG2', loppu: null }, // Zen Holvi



export class KirjanpitajanTiimiService {

  isSpeccer(avain: string): boolean {
    return avain === 'dqUiLNmLRaMNbyNgMhWG1AUmVGI2' || // Valtteri
      avain === '4sNRp7LvWTeZ9WTomoKOA9jD42y1' || // Ville
      avain === 'AHiu6xZimwXgxmd3d5vqzbC8ce42' // Jon
  }

  constructor(private _dateService: DateService, private _copyService: KopioijaPalvelu) {

  }

  async annaTiimitJotaKirjanpitajaVetaa(kirjanpitajaAvain: string, date: NumberDate): Promise<KirjanpitajanTiimi[]> {
    return this._kaikki.filter(tiimi => {
      for (const esihenkilo of tiimi.esihenkilot) {
        if (esihenkilo.kirjanpitajaAvain === kirjanpitajaAvain && this.onkoAktiivinenPaivamaarana(esihenkilo, date)) {
          return true
        }
      }
      return false
    })
  }

  annaTiimi(tiimiAvain: KirjanpitajanTiimi['avain']): Promise<KirjanpitajanTiimi> {
    const tiimi = this._kaikki.find(t => t.avain === tiimiAvain)
    if (tiimi) {
      return Promise.resolve(tiimi)
    }
    throw new Error('Tiimiä ei löytynyt avaimella ' + tiimiAvain)
  }

  annaTiiminKirjanpitajatPaivamaaraValilleSet(tiimi: KirjanpitajanTiimi, start: NumberDate, end: NumberDate): Set<string> {
    const set: Set<string> = new Set()
    for (const kp of tiimi.esihenkilot) {
      if (this.onkoAktiivinenPaivamaaraValilla(kp, start, end)) {
        set.add(kp.kirjanpitajaAvain)
      }
    }
    for (const kp of tiimi.jasenet) {
      if (this.onkoAktiivinenPaivamaaraValilla(kp, start, end)) {
        set.add(kp.kirjanpitajaAvain)
      }
    }
    return set
  }

  annaTiiminKirjanpitajatPaivalleSet(tiimi: KirjanpitajanTiimi, date: NumberDate): Set<string> {
    const set: Set<string> = new Set()
    for (const kp of tiimi.esihenkilot) {
      if (this.onkoAktiivinenPaivamaarana(kp, date)) {
        set.add(kp.kirjanpitajaAvain)
      }
    }
    for (const kp of tiimi.jasenet) {
      if (this.onkoAktiivinenPaivamaarana(kp, date)) {
        set.add(kp.kirjanpitajaAvain)
      }
    }
    return set
  }

  kuuluukoKirjanpitajaTiimiin(tiimi: KirjanpitajanTiimi, kirjanpitajaAvain: string, date: NumberDate): boolean {
    for (const kp of tiimi.esihenkilot) {
      if (kp.kirjanpitajaAvain === kirjanpitajaAvain && this.onkoAktiivinenPaivamaarana(kp, date)) {
        return true
      }
    }
    for (const kp of tiimi.jasenet) {
      if (kp.kirjanpitajaAvain === kirjanpitajaAvain && this.onkoAktiivinenPaivamaarana(kp, date)) {
        return true
      }
    }
    return false
  }

  async annaKirjanpitajanTiimi(kirjanpitajaAvain: string, date: NumberDate): Promise<KirjanpitajanTiimi> {
    const tiimit = await this.annaTiimitPaivalle(date)
    for (const tiimi of tiimit) {
      for (const kp of tiimi.esihenkilot) {
        if (kp.kirjanpitajaAvain === kirjanpitajaAvain && this.onkoAktiivinenPaivamaarana(kp, date)) {
          return tiimi
        }
      }
      for (const kp of tiimi.jasenet) {
        if (kp.kirjanpitajaAvain === kirjanpitajaAvain && this.onkoAktiivinenPaivamaarana(kp, date)) {
          return tiimi
        }
      }
    }
    return null
  }

  onkoAktiivinenKuukautena(tiimi: KirjanpitajanTiimi | KirjanpitajanTiiminJasen, month: NumberMonth): boolean {
    const asLocal = this._dateService.numberToLocalMonth(month)
    const kuunEnsimmainenLocal: LocalDate = { year: asLocal.year, month: asLocal.month, day: 1 }
    const start = this._dateService.localDateToNumber(kuunEnsimmainenLocal)
    const end = this._dateService.localDateToNumber(this._dateService.kuukaudenViimeinenPaikallinen(kuunEnsimmainenLocal))
    return this.onkoAktiivinenPaivamaaraValilla(tiimi, start, end)
  }

  onkoAktiivinenPaivamaarana(tiimi: KirjanpitajanTiimi | KirjanpitajanTiiminJasen, date: NumberDate): boolean {
    if (tiimi.loppu) {
      return this._dateService.compareNumberDates(date, '>=', tiimi.alku) && this._dateService.compareNumberDates(date, '<=', tiimi.loppu)
    }
    return this._dateService.compareNumberDates(date, '>=', tiimi.alku)
  }

  onkoAktiivinenPaivamaaraValilla(tiimi: KirjanpitajanTiimi | KirjanpitajanTiiminJasen, start: NumberDate, end: NumberDate): boolean {
    if (tiimi.loppu) {
      return this._dateService.compareNumberDates(tiimi.alku, '<=', end) && this._dateService.compareNumberDates(start, '<=', tiimi.loppu)
    }
    return this._dateService.compareNumberDates(tiimi.alku, '<=', end)
  }

  annaTiimitPaivamaaraValille(start: NumberDate, end: NumberDate): Promise<KirjanpitajanTiimi[]> {
    const all = this._kaikki.filter(tiimi => this.onkoAktiivinenPaivamaaraValilla(tiimi, start, end))
      .map(tiimi => {
        const copy: KirjanpitajanTiimi = this._copyService.cloneObjectDeep(tiimi)
        copy.esihenkilot = copy.esihenkilot.filter(henkilo => this.onkoAktiivinenPaivamaaraValilla(henkilo, start, end))
        copy.jasenet = copy.jasenet.filter(henkilo => this.onkoAktiivinenPaivamaaraValilla(henkilo, start, end))
        return copy
      })
    return Promise.resolve(all)
  }

  annaTiimitPaivalle(date: NumberDate): Promise<KirjanpitajanTiimi[]> {
    const all = this._kaikki.filter(tiimi => this.onkoAktiivinenPaivamaarana(tiimi, date))
      .map(tiimi => {
        const copy: KirjanpitajanTiimi = this._copyService.cloneObjectDeep(tiimi)
        copy.esihenkilot = copy.esihenkilot.filter(henkilo => this.onkoAktiivinenPaivamaarana(henkilo, date))
        copy.jasenet = copy.jasenet.filter(henkilo => this.onkoAktiivinenPaivamaarana(henkilo, date))
        return copy
      })
    return Promise.resolve(all)
  }

  annaKaikkiTiimit(): Promise<KirjanpitajanTiimi[]> {
    return Promise.resolve([...this._kaikki])
  }

  annaKaikkiEsihenkilot(): Promise<KirjanpitajanTiiminJasen[]> {
    const esihenkilot: KirjanpitajanTiiminJasen[] = []
    for (const tiimi of this._kaikki) {
      esihenkilot.push(...tiimi.esihenkilot)
    }
    return Promise.resolve(esihenkilot)
  }

  /**
    "CHELLO" (Dinan tiimi) 🍋
      Elina Brusila
      Jenny Mattila
      Kirsi Lahti
      Kirsi Rönkä
      Kristiina Jutila
      Ksenia Kulikova
      Nina Nieminen
      Satu Sunikka
      Susanna Taipalus
      Susanna Vaarala
    */
  private _monachello: KirjanpitajanTiimi = {
    avain: 'MONACHELLO',
    nimi: 'Chello',
    esihenkilot: [
      { alku: 100101, kirjanpitajaAvain: '9CBQy1BvVLSCv5EsSCmGLGZx0Nj2', loppu: null }, // Dina Rissanen
      { alku: 240601, kirjanpitajaAvain: 'cVYOWEOwXxcTRBAxdChEK6IPM3u2', loppu: 241130 } // Tarja Nieminen
    ],
    jasenet: [
      { alku: 100101, kirjanpitajaAvain: 'v1krwtHAOmQOnKBfGXHrsIP7mhD3', loppu: 230131 }, // Elina Brusila
      { alku: 100101, kirjanpitajaAvain: '29Fxm3m4KIO0qvIxbsgJSZdAJUp2', loppu: null }, // Jenny Mattila
      { alku: 100101, kirjanpitajaAvain: 'YWtOHDtbKrMo0S3lhGcpIHMagmU2', loppu: null }, // Kirsi Lahti
      { alku: 100101, kirjanpitajaAvain: 'mup7lN7Cb8hXntHyaEmrNHCf3413', loppu: null }, // Kirsi Rönkä
      { alku: 100101, kirjanpitajaAvain: 'kopIBXtRr6c1A71FhxazvpKRLdA3', loppu: null }, // Kristiina Jutila
      { alku: 100101, kirjanpitajaAvain: 'UwpmuMpJXubiD5B67BjIxf3MDP33', loppu: null }, // Ksenia Kulikova
      { alku: 100101, kirjanpitajaAvain: '5YQcFTlDU0TdfPB2jrFccmDn1Qb2', loppu: null }, // Nina Nieminen
      { alku: 241007, kirjanpitajaAvain: 'EtuYQLIFv6NFM7K3kgPyn5yfocJ2', loppu: null }, // Minna Kambur
      { alku: 100101, kirjanpitajaAvain: 'S6gz1Xd4LmcHSovg3VoyjBMwAg53', loppu: null }, // Satu Sunikka
      { alku: 100101, kirjanpitajaAvain: 'igeUmwRVwyLYQPWQXlLXai2927K2', loppu: null }, // Susanna Taipalus
      { alku: 240101, kirjanpitajaAvain: 'TJh1u0pjY8YqGa1KAF2chNWbX7h2', loppu: null }, // Susanna Vaarala
    ],
    alku: 100101,
    loppu: null
  }

  /**
    VERNA (Eevan tiimi) 🍋
      Hanna Räsänen
      Jenni Lampila
      Jussi Långström
      Katja Kaipainen
      Maija Vuorimaa
      Maria Laakso
      Milla Vaajanen
      Paula Dillingham-Edwards
      Satu Kanerva
      Taina Rautio
      Taru Pirttiaho
      Sanna Vihersaari
    */
  private _verna: KirjanpitajanTiimi = {
    avain: 'VERNA',
    nimi: 'Verna',
    esihenkilot: [
      { alku: 100101, kirjanpitajaAvain: 'EdFoKOQmOCM2vmDTx2c8rEO9zkG3', loppu: null } // Eeva Häkkinen
    ],
    jasenet: [
      { alku: 100101, kirjanpitajaAvain: 'tst8k0sa81QUu2OrOtHiBPAbPZh1', loppu: null }, // Hanna Räsänen
      { alku: 100101, kirjanpitajaAvain: 'JqbumyyUcphERklcjAIEVMnnV3r2', loppu: null }, // Jenni Lampila
      { alku: 100101, kirjanpitajaAvain: 'h279Xe3JnVMBLmtBuOSMeOQvu2K3', loppu: 231231 }, // Johanna Kristo
      { alku: 100101, kirjanpitajaAvain: 'xmjSCbTkURP5SmqyEXceXnD16383', loppu: null }, // Jussi Långström
      { alku: 100101, kirjanpitajaAvain: 'dUXXSpkqOjbiY1VsTiPsMNBrBmM2', loppu: null }, // Katja Kaipainen
      { alku: 100101, kirjanpitajaAvain: 't8gVl4VCqaWCDTKEVjQx9NXa5m22', loppu: null }, // Maija Vuorimaa
      { alku: 100101, kirjanpitajaAvain: '3J8SOb4AYubt6Lmf3xBIibdXqC43', loppu: null }, // Maria Laakso
      { alku: 100101, kirjanpitajaAvain: 'ndCFrkL2QROXGAkPxqMTiE0QxHf1', loppu: null }, // Milla Vaajanen
      { alku: 100101, kirjanpitajaAvain: '9d85Vjpw20f3L67xMeOZprqwlmx1', loppu: null }, // Paula Dillingham-Edwards
      { alku: 100101, kirjanpitajaAvain: 'QL4CBlJTLdQLtbb4k8AURFYeg3I3', loppu: null }, // Sanna Vihersaari
      { alku: 100101, kirjanpitajaAvain: '3jHPVDkeSuW8maXrBhwqIGJc9oW2', loppu: null }, // Satu Kanerva
      { alku: 100101, kirjanpitajaAvain: 'FkWECO8otlf2H1275jzUzMR9xSf1', loppu: null }, // Taina Rautio
      { alku: 100101, kirjanpitajaAvain: 'l9GGjlUMnIdSHTLIrZQyOv4ldt23', loppu: null } // Taru Pirttiaho
    ],
    alku: 100101,
    loppu: null
  }

  /**
    FINO (Maijan tiimi) 🍋
      Anne Oksanen
      Antti Laakso
      Eveliina Ansas
      Hanna Lahtinen
      Ilari Heinonen
      Kirsi Ahvenainen
      Petra Pasula
      Sara Lindberg
      Virpi Kujala-Kainulainen
      Laura Pajunen
    */
  private _fino: KirjanpitajanTiimi = {
    avain: 'FINO',
    nimi: 'Fino',
    esihenkilot: [
      { alku: 100101, kirjanpitajaAvain: 'nwEYCXNoWBcfLoFl9jTRcX7i9583', loppu: null } // Maija Minkkinen
    ],
    jasenet: [
      { alku: 100101, kirjanpitajaAvain: 'yDm2TEBOU9QF4JICxOcr15emtuj1', loppu: null }, // Anne Oksanen
      { alku: 100101, kirjanpitajaAvain: '5ttuVKghGGQQxzk97xdV16iha5o1', loppu: null }, // Antti Laakso
      { alku: 100101, kirjanpitajaAvain: 'eXjNZdpfC0Y0pSrAX8q35BK3p3l2', loppu: null }, // Eveliina Ansas
      { alku: 100101, kirjanpitajaAvain: 'N920cZxCEHMA9e0C9V3OTGyyK9n2', loppu: 230918 }, // Hanna Lahtinen
      { alku: 100101, kirjanpitajaAvain: 'yDO4mvcfPGbKXvxWPpQcYwS5ezk2', loppu: null }, // Ilari Heinonen
      { alku: 100101, kirjanpitajaAvain: 'hjjoCAaDCOegrLJHQyJdZvquMgu2', loppu: null }, // Kirsi Ahvenainen
      { alku: 100101, kirjanpitajaAvain: 'e0Ravrw1L3cBTKfYSG1D6U8sU2o1', loppu: null }, // Laura Pajunen
      { alku: 100101, kirjanpitajaAvain: 'hZo5I0KWkrfs7eoIpAhx4foqXCC2', loppu: null }, // Petra Pasula
      { alku: 100101, kirjanpitajaAvain: 'AESoWnwA2WQwu3z6J59x5og2NqP2', loppu: null }, // Sara Lindberg
      { alku: 100101, kirjanpitajaAvain: 'TJh1u0pjY8YqGa1KAF2chNWbX7h2', loppu: 231231 }, // Susanna Vaarala
      { alku: 100101, kirjanpitajaAvain: 'N2uyODovqCfvCoIDzRCtWZe7HKn1', loppu: 231231 }, // Taru Puupponen (lopettamispäivä ei tarkka)
      { alku: 100101, kirjanpitajaAvain: 'njaaiWR8pXOMo5HUajKOnUXOLi52', loppu: null }, // Virpi Kujala-Kainulainen
      { alku: 230201, kirjanpitajaAvain: 'v1krwtHAOmQOnKBfGXHrsIP7mhD3', loppu: null } // Elina Brusila
    ],
    alku: 100101,
    loppu: null
  }

  /**
    EUREKA (Mirkan tiimi) 🍋
      Anna-Liisa Ihanamäki
      Elina Mohell
      Fanni Kurkikangas
      Jenna Nordling
      Natalja Telkonen
      Tarja Nieminen
    */
  private _eureka: KirjanpitajanTiimi = {
    avain: 'EUREKA',
    nimi: 'Eureka',
    esihenkilot: [
      { alku: 100101, kirjanpitajaAvain: 'URU3mqXHMcXRXEKnLmSGxmgPgW62', loppu: null } // Mirka Helanen
    ],
    jasenet: [
      { alku: 100101, kirjanpitajaAvain: 'wZsJcE7KTZO4J2TbyeoSBgPMTFs2', loppu: null }, // Anna-Liisa Ihanamäki
      { alku: 100101, kirjanpitajaAvain: 'QjLhS5wo1sWpL3fracIQIBwBKuu2', loppu: null }, // Elina Mohell
      { alku: 100101, kirjanpitajaAvain: 'MYK3y6Y7xfhaYjTIf79hI9M0JwB3', loppu: null }, // Fanni Kurkikangas
      { alku: 100101, kirjanpitajaAvain: 'COPVwjwcmiMwabLOJH4JjH57amA3', loppu: null }, // Jenna Nordling
      { alku: 100101, kirjanpitajaAvain: '1kVFV9vcYGfGJydhVWSchf7CshG3', loppu: null }, // Natalja Telkonen
      { alku: 100101, kirjanpitajaAvain: 'cVYOWEOwXxcTRBAxdChEK6IPM3u2', loppu: 240531 }, // Tarja Nieminen loppui
      { alku: 241201, kirjanpitajaAvain: 'cVYOWEOwXxcTRBAxdChEK6IPM3u2', loppu: null }, // Tarja Nieminen jatkuu taas
      { alku: 230506, kirjanpitajaAvain: 'clvzNT47oFZ7UjHN0I8agRhc6DR2', loppu: null }, // Jenni Siekkinen
      { alku: 230919, kirjanpitajaAvain: 'N920cZxCEHMA9e0C9V3OTGyyK9n2', loppu: null }, // Hanna Lahtinen
      { alku: 250323, kirjanpitajaAvain: '0yBN6AoU0YUfIUTi4A3y7GEoWJp2', loppu: null } // Katja Hanhisalo
    ],
    alku: 100101,
    loppu: null
  }

  /**
    GENOVA (Hallinto, Annin tiimi) 🍋
      Anna Tuominen
      Eva Hakola
      Emma Laatikainen
    */
  private _genova: KirjanpitajanTiimi = {
    avain: 'GENOVA',
    nimi: 'Genova',
    esihenkilot: [
      { alku: 100101, kirjanpitajaAvain: '4NAixSr2HHMw8D9Trf2hdfeV5MC2', loppu: 240930 }, // Anni Helenius
      { alku: 241001, kirjanpitajaAvain: 'ZHx7TJeK5xc159g2O82VTfa2QRz2', loppu: null }, // Eva Hakola
    ],
    jasenet: [

      { alku: 100101, kirjanpitajaAvain: 'ZHx7TJeK5xc159g2O82VTfa2QRz2', loppu: 240930 }, // Eva Hakola -> Siirtyi johtamaan tätä tiimiä
      { alku: 240819, kirjanpitajaAvain: 'txS663D7RCdi3HY6Y06KLHmWQOm2', loppu: null }, // Emma Laatikainen
      { alku: 100101, kirjanpitajaAvain: '6ZGrFsEXGDc8ZRgbO3V7foheest2', loppu: null }, // Lemon Tree Palkat

      { alku: 100101, kirjanpitajaAvain: 't1BauO6YTbf2cQoN1y8eREEmGlJ3', loppu: 240424 }, // Leena Nurmi -> Diamante
      { alku: 100101, kirjanpitajaAvain: 'aJhkMbFHHyRuFSdrPOqKxUbUnqZ2', loppu: 231231 }, // Anna Tuominen
      { alku: 100101, kirjanpitajaAvain: '259AJG2HoJctamvUlUYHIC1uonU2', loppu: 240424 }, // Annika Toivonen (nyk. Majasto) -> Diamante
      { alku: 100101, kirjanpitajaAvain: 'omaVAYFBwEhTA0GWbpNo3sZu3uh1', loppu: 240229 }, // Sandra Stenros
      { alku: 240618, kirjanpitajaAvain: 'V8MDsQQnSIMrgcOL0QwjEjekfP02', loppu: 240803 }, // Ida Alavire
      { alku: 240101, kirjanpitajaAvain: 'h279Xe3JnVMBLmtBuOSMeOQvu2K3', loppu: 240424 }, // Johanna Kristo -> Diamante
      { alku: 240212, kirjanpitajaAvain: 'xm5x1jlOjaNoOnOOkHyKdmoU2VG3', loppu: 240424 }, // Isabel Rikander -> Diamante
      { alku: 240101, kirjanpitajaAvain: 'ykHKU22K3POKUzHdW9apmzmU6f02', loppu: 240424 }, // Heidi Lehtonen -> Diamante

    ],
    alku: 100101,
    loppu: null
  }

  /**
    DIAMANTE (Palkat / ZEN / Minduu, Leenan tiimi) 🍋
      Annika Majasto
      Heidi Lehtonen
      Heidi Kuisma
      Isabel Rikander
      Johanna Kristo
      (Taina Rautio, 20% Palkat, muute Vernassa)
    */
  private _diamante: KirjanpitajanTiimi = {
    avain: 'DIAMANTE',
    nimi: 'Diamante',
    esihenkilot: [
      { alku: 240425, kirjanpitajaAvain: 't1BauO6YTbf2cQoN1y8eREEmGlJ3', loppu: null }, // Leena Nurmi
    ],
    jasenet: [
      { alku: 240425, kirjanpitajaAvain: 'ykHKU22K3POKUzHdW9apmzmU6f02', loppu: null }, // Heidi Lehtonen
      { alku: 240425, kirjanpitajaAvain: '259AJG2HoJctamvUlUYHIC1uonU2', loppu: null }, // Annika Majasto
      { alku: 240425, kirjanpitajaAvain: 'h279Xe3JnVMBLmtBuOSMeOQvu2K3', loppu: null }, // Johanna Kristo
      { alku: 240425, kirjanpitajaAvain: 'gAV0KTVarYg8GauChfePM3VCFfO2', loppu: null }, // Heidi Kuisma
      { alku: 240425, kirjanpitajaAvain: 'xm5x1jlOjaNoOnOOkHyKdmoU2VG3', loppu: null }, // Isabel Rikander
    ],
    alku: 240425,
    loppu: null
  }

  /**
    IT tiimi (Villen & Veran tiimi) 🍋
      Lauri Teder
      Ville Hartikainen
      Elina Partinen
    */
  private _it: KirjanpitajanTiimi = {
    avain: 'IT',
    nimi: 'IT',
    esihenkilot: [
      { alku: 100101, kirjanpitajaAvain: '4sNRp7LvWTeZ9WTomoKOA9jD42y1', loppu: null }, // Ville Virtanen
      { alku: 240610, kirjanpitajaAvain: 'nwGu99bXkWfX40Hk5nLVMKDn3lP2', loppu: null } // Veera Lietkari
    ],
    jasenet: [
      { alku: 100101, kirjanpitajaAvain: 'V8MDsQQnSIMrgcOL0QwjEjekfP02', loppu: 240618 }, // Ida Alavire
      { alku: 100101, kirjanpitajaAvain: 'IbKDXwWiLLNbV4e0ZxtqrcmGPik2', loppu: null }, // Lauri Teder
      { alku: 100101, kirjanpitajaAvain: 'L5JPBR5hIYZMHNvBw4ban8KV3nv2', loppu: 231006 }, // Tarmo Sundström
      { alku: 100101, kirjanpitajaAvain: '3d4UG33dpERg5CqyF1TmafusXNa2', loppu: null }, // Ville Hartikainen
      { alku: 100101, kirjanpitajaAvain: 'H6KU6K9S39QaqDnpe1apaUV3dsz1', loppu: null } // Elina Partinen
    ],
    alku: 100101,
    loppu: null
  }

  /**
    Myynti 🍋
      Laura Pajunen
      Sanna Vihersaari
    */
  private _sales: KirjanpitajanTiimi = {
    avain: 'SALES',
    nimi: 'Sales',
    esihenkilot: [],
    jasenet: [
      { alku: 100101, kirjanpitajaAvain: 'e0Ravrw1L3cBTKfYSG1D6U8sU2o1', loppu: null }, // Laura Pajunen
      { alku: 100101, kirjanpitajaAvain: 'QL4CBlJTLdQLtbb4k8AURFYeg3I3', loppu: null } // Sanna Vihersaari
    ],
    alku: 100101,
    loppu: null
  }

  /**
    Muut 🍋
      It
      Genova
      Sales
      Valtteri
      jon
    */
  private _muut: KirjanpitajanTiimi = {
    avain: 'MUUT',
    nimi: 'Muut',
    esihenkilot: [],
    jasenet: [
      { alku: 100101, kirjanpitajaAvain: 'dqUiLNmLRaMNbyNgMhWG1AUmVGI2', loppu: null }, // Valtteri
      { alku: 100101, kirjanpitajaAvain: 'AHiu6xZimwXgxmd3d5vqzbC8ce42', loppu: null }, // Jon
      { alku: 100101, kirjanpitajaAvain: 't86aMdfTkicMvOiKGGjKm4m3Rwm1', loppu: null }, // Sales
      ...this._genova.jasenet,
      ...this._diamante.jasenet,
      ...this._it.jasenet
    ],
    alku: 100101,
    loppu: null
  }

  private _kaikki: KirjanpitajanTiimi[] = [
    this._monachello,
    this._eureka,
    this._fino,
    this._genova,
    this._diamante,
    this._it,
    this._sales,
    this._verna,
    this._muut
  ]

}
