import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'
import { NumberDate } from 'app/_shared-core/model/common'

type ValilehdenTunnus = 'tositteet' | 'raportit-uusi' | 'tiedot' | 'tilikartta' | 'projektit' | 'tilinpaatos' | 'reskontra'

@Injectable()
export class KirjanpitoNavigationService {

  private _defaultValilehti: ValilehdenTunnus = 'tositteet'
  private _currentMainTabSubject = new BehaviorSubject<ValilehdenTunnus>(this._defaultValilehti)
  currentMainTabObservable = this._currentMainTabSubject.asObservable()

  private _kirjanpidonTiliSubject = new BehaviorSubject<string>(null)
  kirjanpidonTiliObservable = this._kirjanpidonTiliSubject.asObservable()

  private _raporttiValinPaattymispaivaSubject = new BehaviorSubject<NumberDate>(null)
  raporttiValinPaattymispaivaObservable = this._raporttiValinPaattymispaivaSubject.asObservable()

  constructor() { }

  vaihdaPaaTasonValilehti(valilehti: ValilehdenTunnus) {
    this._currentMainTabSubject.next(valilehti)
  }

  valittuKirjanpidonTili(tili: string) {
    this._kirjanpidonTiliSubject.next(tili)
  }

  valittuRaporttiValinPaattymispaiva(paattymispaiva: NumberDate) {
    this._raporttiValinPaattymispaivaSubject.next(paattymispaiva)
  }

  resetNavigation() {
    this._currentMainTabSubject.next(this._defaultValilehti)
    this._kirjanpidonTiliSubject.next(null)
    this._raporttiValinPaattymispaivaSubject.next(null)
  }

}