import { Component, OnInit, OnDestroy, ErrorHandler, Input, ViewChild, Output, EventEmitter, AfterViewInit, ElementRef, ViewEncapsulation } from '@angular/core'
import { Validators, ValidationErrors, FormControl, FormGroup } from '@angular/forms'
import { MatSnackBar } from '@angular/material/snack-bar'

import { TilikarttaService } from '../_angular/service/tilikartta.service'
import { DateService } from '../_shared-core/service/date.service'
import { AsiakasService } from '../_angular/service/asiakas/asiakas.service'
import { LadataanService } from '../_jaettu-angular/service/ladataan.service'
import { KirjanpitoUriService } from '../_jaettu-lemonator/service/kirjanpito-uri.service'

import { KirjanpitajanAsetukset, KirjanpitoRaporttiEnsinNaytettava } from '../_jaettu-lemonator/model/kirjanpitaja'
import { KirjanpidonProjekti, Kirjanpitotili, RaporttiRequest, RaporttiPdfResponse, ReportDatasource, RaporttiCsvResponse } from '../_jaettu-lemonator/model/kirjanpito'
import { RaporttiType } from 'app/_jaettu/model/reports-shared'
import { LocalDate, LocalMonth, Timestamp, TuettuKieli } from '../_shared-core/model/common'

import { Observable, combineLatest, Subject, of as observableOf, BehaviorSubject, firstValueFrom } from 'rxjs'
import { map, takeUntil, switchMap, filter } from 'rxjs/operators'

import { FormValidationService } from 'app/_jaettu-angular/service/form-validation.service'
import { Tilikausi } from 'app/_jaettu-lemonator/model/asiakas'
import { FormValidators } from 'app/_jaettu-angular/_validators/FormValidators'

import { KirjanpitoRaporttiValilehti } from '../_jaettu-lemonator/model/kirjanpitaja'
import { KirjanpitajaService } from 'app/_angular/service/kirjanpitaja/kirjanpitaja.service'
import { KirjautunutKayttajaService } from 'app/_angular/service/kirjautunut-kayttaja.service'
import { FirebaseLemonator } from 'app/_angular/service/firebase-lemonator.service'
import { lemonShare } from 'app/_jaettu-angular/_rxjs/lemon-share.operator'
import { FileSaverService } from 'app/_jaettu-angular/service/file-saver'
import { MatSelect } from '@angular/material/select'
import { KirjanpitoRaportitVertailuTiedotService } from '../_angular/service/kirjanpito/kirjanpito-raportit-vertailutiedot.service'

interface NaytettavaTilikausi {
  label: string
  labelHover: string
  year: number
  start: LocalDate
  end: LocalDate
  quarters: NaytettavanTilikaudenKvartaali[]
  months: NaytettavanTilikaudenKuukausi[]
}

interface NaytettavanTilikaudenKvartaali {
  quarter: string
  label: '1 - 3' | '4 - 6' | '7 - 9' | '10 - 12'
  start: LocalDate // Clamped to tilikausi
  end: LocalDate // Clamped to tilikausi
}

interface NaytettavanTilikaudenKuukausi {
  month: string
  label: number
  start: LocalDate // Clamped to tilikausi
  end: LocalDate // Clamped to tilikausi
}

interface ExportOption {
  tag: string
  type: RaporttiType
  kieli: TuettuKieli
}

interface ReportForm {
  alkaa: FormControl<Date>
  loppuu: FormControl<Date>
  searchString: FormControl<string>
  tilista: FormControl<number>
  tiliin: FormControl<number>
  source: FormControl<ReportDatasource>
}

export interface RaporttienHakuvaihtoehdot {
  alkaa: number
  loppuu: number
  vapaasanahaku: string
  tilista: string
  tiliin: string
  projekti: string
  datasource: ReportDatasource
}

export interface KlikattuKirjaus {
  kirjausnumero: string
}

@Component({
  selector: '[app-kirjanpito-raportit]',
  templateUrl: './raportit.component.html',
  styleUrls: ['./raportit.component.css'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class KirjanpitoRaportitComponent implements OnInit, AfterViewInit, OnDestroy {

  // @ViewChild('alkaaPicker', { static: true, read: MatDatepicker }) alkaaPicker: MatDatepicker<Date>
  // @ViewChild('loppuuPicker', { static: true, read: MatDatepicker }) loppuuPicker: MatDatepicker<Date>

  @ViewChild('tilistaInput', { static: false, read: ElementRef }) tilistaInput: ElementRef<HTMLInputElement>
  @ViewChild('tiliinInput', { static: false, read: ElementRef }) tiliinInput: ElementRef<HTMLInputElement>
  @ViewChild('alkaaInput', { static: false, read: ElementRef }) alkaaInput: ElementRef<HTMLInputElement>
  @ViewChild('pdfSelect') pdfSelect: MatSelect
  @ViewChild('csvSelect') csvSelect: MatSelect

  @Input() valittuKuukausiObservable: Observable<LocalMonth>
  @Input() valittuValilehti: KirjanpitoRaporttiValilehti = null
  @Output() selectedReportTypeChanged: EventEmitter<KirjanpitoRaporttiValilehti> = new EventEmitter()

  @Output() kirjaustaKlikattiin: EventEmitter<KlikattuKirjaus> = new EventEmitter()

  private _hakuvaihtoehdotSubject: BehaviorSubject<RaporttienHakuvaihtoehdot> = new BehaviorSubject({ alkaa: null, loppuu: null, vapaasanahaku: null, tiliin: null, tilista: null, projekti: null, datasource: 'raportointikirjaukset' })
  hakuvaihtoehdotObservable = this._hakuvaihtoehdotSubject.asObservable()
  paivitaArvotHiljaisestiSubject: BehaviorSubject<number> = new BehaviorSubject(0)
  tilitMapObservable: Observable<Map<string, Kirjanpitotili>>

  kaikkiKirjanpitoprojektitObservable: Observable<KirjanpidonProjekti[]>
  naytaProjektitObservable: Observable<boolean>

  name: string = 'asdrtyitosp82mf' + Math.random()
  naytettavaTilikausi: NaytettavaTilikausi

  pdfList: ExportOption[]
  isPdfSelectOpen: boolean = false

  csvList: ExportOption[]
  isCsvSelectOpen: boolean = false

  naytettavatTilikaudetObservable: Observable<NaytettavaTilikausi[]>

  taseOrderObservable: Observable<'1' | '2'>
  tulosOrderObservable: Observable<'1' | '2'>

  dateBtnsSelectedQuarter: string
  dateBtnsSelectedMonth: string
  naytaOss: boolean = false

  kirjanpitajaOnLuottoObservable: Observable<boolean>

  laskelmatDateAndSearchForm: FormGroup<ReportForm>
  alkaa: FormControl<Date>
  loppuu: FormControl<Date>
  searchString: FormControl<string>
  tilista: FormControl<number>
  tiliin: FormControl<number>
  source: FormControl<ReportDatasource>


  private _ngUnsubscribe = new Subject<void>()

  constructor(
    private _errorHandler: ErrorHandler,
    private _tilikarttaService: TilikarttaService,
    private _dateService: DateService,
    private _asiakasService: AsiakasService,
    private _firebase: FirebaseLemonator,
    private _snackbar: MatSnackBar,
    private _ladataanService: LadataanService,
    private _kirjanpitoUriService: KirjanpitoUriService,
    private _kirjanpitajaService: KirjanpitajaService,
    private _validationService: FormValidationService,
    private _kirjautunutKayttajaService: KirjautunutKayttajaService,
    private _fileSaverService: FileSaverService,
    private _kirjanpitoRaportitVertailuTiedotService: KirjanpitoRaportitVertailuTiedotService
  ) { }

  private _minDate = new Date(2010, 11, 31, 23, 59, 59, 999)

  alkaaEnnenLoppuaValidator = (control: FormControl<Date>): ValidationErrors | null => {
    if (this.loppuu?.value && this.alkaa?.value && this.alkaa.value > this._minDate && this.loppuu.value > this._minDate) {
      if (this.alkaa.value > this.loppuu.value) {
        return { 'loppuEnnenAlkua': true }
      }
    }
    return null
  }

  ngOnInit() {

    this.kirjanpitajaOnLuottoObservable = this._kirjautunutKayttajaService.kirjanpitajaOnLuottoObservable

    this._kirjanpitajaService.kirjautuneenKayttajanAsetuksetObservable.pipe(takeUntil(this._ngUnsubscribe)).subscribe(asetukset => {
      if (!this.valittuValilehti && asetukset?.kirjanpidonOletusraportti) {
        this.setValittuValilehti(asetukset.kirjanpidonOletusraportti)
      }
    })

    this.taseOrderObservable = this._kirjanpitajaService.kirjautuneenKayttajanAsetuksetObservable.pipe(
      map(asetukset => {
        if (asetukset?.kirjanpidonEnsinNaytettevaRaportti) {
          return asetukset.kirjanpidonEnsinNaytettevaRaportti === 'tase' ? '1' : '2'
        }
        return '1'
      })
    )

    this.tulosOrderObservable = this.taseOrderObservable.pipe(
      map(taseOrder => {
        return taseOrder === '1' ? '2' : '1'
      })
    )

    this.alkaa = new FormControl<Date>(null, [Validators.required, FormValidators.minDateValidator(this._minDate), this.alkaaEnnenLoppuaValidator])
    this.loppuu = new FormControl<Date>(null, [Validators.required, FormValidators.minDateValidator(this._minDate), this.alkaaEnnenLoppuaValidator])
    this.searchString = new FormControl<string>(null)
    this.tilista = new FormControl<number>(null, { updateOn: 'blur' })
    this.tiliin = new FormControl<number>(null, { updateOn: 'blur' })
    this.source = new FormControl<ReportDatasource>('raportointikirjaukset')

    this.laskelmatDateAndSearchForm = new FormGroup<ReportForm>({
      alkaa: this.alkaa,
      loppuu: this.loppuu,
      searchString: this.searchString,
      tilista: this.tilista,
      tiliin: this.tiliin,
      source: this.source
    })

    this.source.valueChanges.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(source => {
      if (source !== this._hakuvaihtoehdotSubject.value.datasource) {
        this._hakuvaihtoehdotSubject.value.datasource = source
        this._hakuvaihtoehdotSubject.next(this._hakuvaihtoehdotSubject.value)
      }
    })

    this.searchString.valueChanges.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(a => {
      const value = a?.trim() || null
      if (value !== this._hakuvaihtoehdotSubject.value.vapaasanahaku) {
        this._hakuvaihtoehdotSubject.value.vapaasanahaku = value
        this._hakuvaihtoehdotSubject.next(this._hakuvaihtoehdotSubject.value)
      }
    })

    this.tilista.valueChanges.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(a => {
      let value = a + ''
      value = value.length > 3 ? value : null
      if (value !== this._hakuvaihtoehdotSubject.value.tilista) {
        this._hakuvaihtoehdotSubject.value.tilista = value
        this._hakuvaihtoehdotSubject.next(this._hakuvaihtoehdotSubject.value)
      }
    })

    this.tiliin.valueChanges.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(a => {
      let value = a + ''
      value = value.length > 3 ? value : null
      if (value !== this._hakuvaihtoehdotSubject.value.tiliin) {
        this._hakuvaihtoehdotSubject.value.tiliin = value
        this._hakuvaihtoehdotSubject.next(this._hakuvaihtoehdotSubject.value)
      }
    })

    this.alkaa.valueChanges.pipe(
      filter(() => this.alkaa.valid), // Don't emit at all if not valid date
      takeUntil(this._ngUnsubscribe)
    ).subscribe(a => {
      if (a) {
        const asNumber = this._dateService.dateToNumber(a)
        if (asNumber !== this._hakuvaihtoehdotSubject.value.alkaa) {
          this._hakuvaihtoehdotSubject.value.alkaa = asNumber
          this._hakuvaihtoehdotSubject.next(this._hakuvaihtoehdotSubject.value)
        }
      }
      this.loppuu.updateValueAndValidity({ onlySelf: true, emitEvent: false })
    })

    this.loppuu.valueChanges.pipe(
      filter(() => this.loppuu.valid), // Don't emit at all if not valid date
      takeUntil(this._ngUnsubscribe)
    ).subscribe(a => {
      if (a) {
        const asNumber = this._dateService.dateToNumber(a)
        if (asNumber !== this._hakuvaihtoehdotSubject.value.loppuu) {
          this._hakuvaihtoehdotSubject.value.loppuu = asNumber
          this._hakuvaihtoehdotSubject.next(this._hakuvaihtoehdotSubject.value)
        }
      }
      this.alkaa.updateValueAndValidity({ onlySelf: true, emitEvent: false })
    })

    this._hakuvaihtoehdotSubject.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(haku => {
      if (!haku) { return }
      // Activate quarter or month btn if start and end dates match (for currently selected tilikausi), otherwise deactivate
      const alkaaLocal = this._dateService.numberToLocalDate(haku.alkaa)
      const loppuuLocal = this._dateService.numberToLocalDate(haku.loppuu)
      this._setActiveKvartaaliOrKuukausi(alkaaLocal, loppuuLocal)
    })

    this.kaikkiKirjanpitoprojektitObservable = this._asiakasService.nykyinenAsiakasAvainObservable.pipe(
      switchMap(asiakas => {
        if (!asiakas) {
          return observableOf<KirjanpidonProjekti[]>([])
        }
        const uri = this._kirjanpitoUriService.annaKirjanpitoProjektitCollection(asiakas.avain)
        return this._firebase.firestoreCollection<KirjanpidonProjekti>(uri).listen()
      })
    )

    this.naytaProjektitObservable = this.kaikkiKirjanpitoprojektitObservable.pipe(
      map(projektit => {
        return !!projektit?.filter(projekti => projekti.viewableInLemonator).length
      })
    )

    this.naytettavatTilikaudetObservable = this._asiakasService.nykyisenAsiakkaanTilikaudetObservable.pipe(
      map(tilikaudet => {
        console.log('Map tilikaudet')
        if (!tilikaudet?.length) {
          return []
        }
        return tilikaudet.sort((a, b) => this._dateService.paiviaValissaPaikallinen(a.alkaa, b.alkaa)).map(tilikausi => this._calcNaytettavaTilikausi(tilikausi))
      })
    )

    this._asiakasService.nykyinenAsiakasAvainObservable.pipe(
      switchMap(asiakas => {
        if (!asiakas) {
          return observableOf<{ u: Timestamp }>(null)
        }
        const raporttienUri = this._kirjanpitoUriService.annaRaportointikirjauksetPaivitettyUri(asiakas.avain)
        return this._firebase.firestoreDoc<{ u: Timestamp }>(raporttienUri).listen()
      }),
      takeUntil(this._ngUnsubscribe)
    ).subscribe({
      next: update => {
        this.paivitaArvotHiljaisestiSubject.next(update?.u?.toMillis() || this.paivitaArvotHiljaisestiSubject.value + 1)
      },
      error: err => {
        this._errorHandler.handleError(err)
      }
    })

    this._asiakasService.nykyinenAsiakasObservable.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(asiakas => {
      this.naytaOss = !!asiakas?.ossRekisterissa
    })

    this.tilitMapObservable = this._tilikarttaService.nykyisenAsiakkaanTilikartanJaPaatilikartanTilitObservable.pipe(
      filter(tilit => !!tilit),
      map(tilit => {
        const tiliMap: Map<string, Kirjanpitotili> = new Map()
        for (const tili of tilit) {
          tiliMap.set(tili.numero, tili)
        }
        return tiliMap
      }),
      lemonShare()
    )

  }

  async setTulosTaseEnsin(tulosTaiTase: 'tulos' | 'tase') {
    const kirjanpitajanTiedot = await firstValueFrom(this._kirjautunutKayttajaService.kirjanpitajanTiedotObservable)
    if (kirjanpitajanTiedot) {
      const uusiTieto: KirjanpitoRaporttiEnsinNaytettava = tulosTaiTase
      const uri = '/kirjanpitajat/' + kirjanpitajanTiedot.uid + '/kirjanpitajan-asetukset/' + kirjanpitajanTiedot.uid
      await this._firebase.firestoreSetData<Partial<KirjanpitajanAsetukset>>(uri, { kirjanpidonEnsinNaytettevaRaportti: uusiTieto }, { merge: true })
    }
  }

  ngAfterViewInit() {

    combineLatest([this.valittuKuukausiObservable, this.naytettavatTilikaudetObservable]).pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(([valittuKuukausi, naytettavatTilikaudet]) => {

      if (
        !valittuKuukausi ||
        ( // Don't set if we already have both dates.
          this._hakuvaihtoehdotSubject.value.alkaa &&
          this._hakuvaihtoehdotSubject.value.loppuu
        )
      ) {
        return
      }

      const valitunKuukaudenAlku: LocalDate = { year: valittuKuukausi.year, month: valittuKuukausi.month, day: 1 }
      const valitunKuukaudenLoppu = this._dateService.kuukaudenViimeinenPaikallinen(valitunKuukaudenAlku)

      const aktiivinenNaytettava = naytettavatTilikaudet?.find(ntk => {
        return this._dateService.onkoLocalDateKahdenValissa(valitunKuukaudenLoppu, ntk.start, ntk.end)
      })

      if (aktiivinenNaytettava) {
        this.valitseTilikausi(aktiivinenNaytettava)
      } else {
        this.naytettavaTilikausi = null
        this.dateBtnsSelectedMonth = null
        this.dateBtnsSelectedQuarter = null
        this._setDates(valitunKuukaudenAlku, valitunKuukaudenLoppu)
      }

    })

  }

  setValittuValilehti(report: KirjanpitoRaporttiValilehti) {

    this.valittuValilehti = report
    this.pdfList = this._getPdfList(this.valittuValilehti)
    this.csvList = this._getCsvList(this.valittuValilehti)

    this.selectedReportTypeChanged.next(report)
    // if (this.selectedReport === 'tase' || this.selectedReport === 'tase-virallinen') {
    //   this.loppuu.setValidators([Validators.required, FormValidators.minDateValidator(this._minDate)])
    // } else {
    this.loppuu.setValidators([Validators.required, FormValidators.minDateValidator(this._minDate), this.alkaaEnnenLoppuaValidator])
    // }
  }

  async downloadPdf(type: RaporttiType, kieli: TuettuKieli) {

    if (!this.laskelmatDateAndSearchForm.valid) {
      this._validationService.merkitseKokoLomakeKosketuksi(this.laskelmatDateAndSearchForm)
      return
    }

    this._ladataanService.aloitaLataaminen()

    const nykyinenAsiakas = await firstValueFrom(this._asiakasService.nykyinenAsiakasObservable)
    const hakuvaihtoehdot = await firstValueFrom(this.hakuvaihtoehdotObservable)
    const vertailutiedotAjanjakso = await firstValueFrom(this._kirjanpitoRaportitVertailuTiedotService.vertailuTiedotAjanJaksoObservable)

    const haku: RaporttiRequest = {
      a: nykyinenAsiakas.avain,
      w: type,
      s: hakuvaihtoehdot.alkaa,
      e: hakuvaihtoehdot.loppuu,
      l: this._dateService.dateToLocalDateTime(new Date()),
      k: kieli,
      datasource: hakuvaihtoehdot.datasource
    }

    if (vertailutiedotAjanjakso === 'koko-tilikausi') {
      haku.fw = 1
    }

    if (type === RaporttiType.PAAKIRJA) {
      if (hakuvaihtoehdot.tilista) { haku.b = hakuvaihtoehdot.tilista }
      if (hakuvaihtoehdot.tiliin) { haku.c = hakuvaihtoehdot.tiliin }
      if (hakuvaihtoehdot.vapaasanahaku?.trim()) { haku.t = hakuvaihtoehdot.vapaasanahaku.trim() }
    }
    if (hakuvaihtoehdot.projekti) { haku.p = hakuvaihtoehdot.projekti }

    return this._firebase.functionsCall<RaporttiRequest, RaporttiPdfResponse>('kirjanpitoRaportitPdf', haku).then(data => {
      if (data.e) {
        switch (data.e) {
          case 'unknown-report': {
            this._snackbar.open('Raporttia ei ole vielä implementoitu.', 'OK')
            break
          }
          case 'view-not-allowed': {
            this._snackbar.open('Käyttäjälla ei ole oikeutta nähdä tätä asiakasta.', 'OK')
            break
          }
          default: {
            this._snackbar.open('Tietojen lataaminen epäonnistui.', 'OK')
          }
        }
      } else {
        if (data.base64File) {
          const fileName = data.fileName || 'raportti.pdf'
          this._fileSaverService.saveBase64As(data.base64File, fileName, 'pdf')
        } else {
          this._snackbar.open('PDF:n lataaminen epäonnistui.')
        }
      }
    }).finally(() => {
      this._ladataanService.lopetaLataaminen()
    })
  }

  async downloadCsv(type: RaporttiType, kieli: TuettuKieli) {

    if (!this.laskelmatDateAndSearchForm.valid) {
      this._validationService.merkitseKokoLomakeKosketuksi(this.laskelmatDateAndSearchForm)
      return
    }

    this._ladataanService.aloitaLataaminen()

    const nykyinenAsiakas = await firstValueFrom(this._asiakasService.nykyinenAsiakasObservable)
    const hakuvaihtoehdot = await firstValueFrom(this.hakuvaihtoehdotObservable)

    const haku: RaporttiRequest = {
      a: nykyinenAsiakas.avain,
      w: type,
      s: hakuvaihtoehdot.alkaa,
      e: hakuvaihtoehdot.loppuu,
      l: this._dateService.dateToLocalDateTime(new Date()),
      k: kieli,
      datasource: hakuvaihtoehdot.datasource
    }

    if (type === RaporttiType.PAAKIRJA) {
      if (hakuvaihtoehdot.tilista) { haku.b = hakuvaihtoehdot.tilista }
      if (hakuvaihtoehdot.tiliin) { haku.c = hakuvaihtoehdot.tiliin }
      if (hakuvaihtoehdot.vapaasanahaku?.trim()) { haku.t = hakuvaihtoehdot.vapaasanahaku.trim() }
    }
    if (hakuvaihtoehdot.projekti) { haku.p = hakuvaihtoehdot.projekti }

    return this._firebase.functionsCall<RaporttiRequest, RaporttiCsvResponse>('kirjanpitoRaportitCsv', haku).then(data => {
      if (data.e) {
        switch (data.e) {
          case 'unknown-report': {
            this._snackbar.open('Raporttia ei ole vielä implementoitu.', 'OK')
            break
          }
          case 'view-not-allowed': {
            this._snackbar.open('Käyttäjälla ei ole oikeutta nähdä tätä asiakasta.', 'OK')
            break
          }
          default: {
            this._snackbar.open('Tietojen lataaminen epäonnistoi.', 'OK')
          }
        }
      } else {
        if (data.base64File) {
          const fileName = data.fileName || 'raportti.csv'
          this._fileSaverService.saveBase64As(data.base64File, fileName, 'csv')
        } else {
          this._snackbar.open('Excelin lataaminen epäonnistui.')
        }
      }
    }).finally(() => {
      this._ladataanService.lopetaLataaminen()
    })
  }

  // Blur will happen while navigating to the next item.
  enterPressedTilista(event: Event) { this.tiliinInput.nativeElement.focus(); event.preventDefault(); event.stopPropagation() }
  enterPressedTiliin(event: Event) { this.alkaaInput.nativeElement.focus(); event.preventDefault(); event.stopPropagation() }

  ngOnDestroy() {
    this._ngUnsubscribe.next()
    this._ngUnsubscribe.complete()
  }

  valitseTilikausi(uusiNaytettava: NaytettavaTilikausi) {
    this.naytettavaTilikausi = uusiNaytettava
    this.dateBtnsSelectedMonth = null
    this.dateBtnsSelectedQuarter = null
    this._setDates(uusiNaytettava.start, uusiNaytettava.end)
  }

  valitseKvartaali(kvartaali: NaytettavanTilikaudenKvartaali) {
    this.dateBtnsSelectedQuarter = kvartaali.quarter
    this.dateBtnsSelectedMonth = null
    this._setDates(kvartaali.start, kvartaali.end)
  }

  valitseKuukausi(kuukausi: NaytettavanTilikaudenKuukausi) {
    this.dateBtnsSelectedMonth = kuukausi.month
    this.dateBtnsSelectedQuarter = null
    this._setDates(kuukausi.start, kuukausi.end)
  }

  private _setDates(start: LocalDate, end: LocalDate) {
    const alkaaDate = this._dateService.localDateToDate(start)
    const loppuuDate = this._dateService.localDateToDate(end)

    this.alkaa.setValue(alkaaDate, { emitEvent: false })
    this.loppuu.setValue(loppuuDate, { emitEvent: false })

    const alkaaNumber = this._dateService.dateToNumber(alkaaDate)
    const loppuuNumber = this._dateService.dateToNumber(loppuuDate)
    if (
      this._hakuvaihtoehdotSubject.value.alkaa !== alkaaNumber ||
      this._hakuvaihtoehdotSubject.value.loppuu !== loppuuNumber
    ) {
      this._hakuvaihtoehdotSubject.value.alkaa = alkaaNumber
      this._hakuvaihtoehdotSubject.value.loppuu = loppuuNumber
      this._hakuvaihtoehdotSubject.next(this._hakuvaihtoehdotSubject.value)
    }

  }

  private _calcNaytettavaTilikausi(asiakkaanTilikausi: Tilikausi): NaytettavaTilikausi {
    const naytettava: NaytettavaTilikausi = {
      label: '' + asiakkaanTilikausi.loppuu.year,
      labelHover: this._formatSelectableYearBtnText(asiakkaanTilikausi),
      year: asiakkaanTilikausi.loppuu.year,
      start: asiakkaanTilikausi.alkaa,
      end: asiakkaanTilikausi.loppuu,
      months: this._calcNaytettavanTilikaudenKuukaudet(asiakkaanTilikausi),
      quarters: this._calcNaytettavanTilikaudenKvartaalit(asiakkaanTilikausi)
    }
    return naytettava
  }

  private _calcNaytettavanTilikaudenKuukaudet(asiakkaanTilikausi: Tilikausi) {
    const naytettavatKuukaudet: NaytettavanTilikaudenKuukausi[] = []
    const kuukausiaValissa = this._dateService.kuukausiaValissaPaikallinen(asiakkaanTilikausi.loppuu, asiakkaanTilikausi.alkaa)
    for (let i = 0; i <= kuukausiaValissa; i++) {
      const kuukausi: LocalDate = this._dateService.lisaaKuukausiaPaikallinen(asiakkaanTilikausi.alkaa, i)
      const tilikaudenKuukausi: NaytettavanTilikaudenKuukausi = {
        label: kuukausi.month,
        month: (kuukausi.month > 9 ? kuukausi.month : ('0' + kuukausi.month)) + '.' + kuukausi.year,
        start: i === 0 ? asiakkaanTilikausi.alkaa : this._dateService.kuukaudenEnsimmainenPaikallinen(kuukausi), // Use tilikausi start date for first month
        end: i === kuukausiaValissa ? asiakkaanTilikausi.loppuu : this._dateService.kuukaudenViimeinenPaikallinen(kuukausi) // Use tilikausi end date for last month
      }
      naytettavatKuukaudet.push(tilikaudenKuukausi)
    }
    return naytettavatKuukaudet
  }

  private _calcNaytettavanTilikaudenKvartaalit(asiakkaanTilikausi: Tilikausi) {
    const naytettavatKvartaalit: NaytettavanTilikaudenKvartaali[] = []
    const kvartaalejaValissa = this._dateService.kvartaalejaValissaPaikallinen(asiakkaanTilikausi.loppuu, asiakkaanTilikausi.alkaa)

    for (let i = 0; i <= kvartaalejaValissa; i++) {
      const kvartaali: LocalDate = this._dateService.lisaaKuukausiaPaikallinen(asiakkaanTilikausi.alkaa, i * 3)
      let quarterString: '1 - 3' | '4 - 6' | '7 - 9' | '10 - 12' = '10 - 12'
      if (kvartaali.month >= 1 && kvartaali.month <= 3) { quarterString = '1 - 3' }
      if (kvartaali.month >= 4 && kvartaali.month <= 6) { quarterString = '4 - 6' }
      if (kvartaali.month >= 7 && kvartaali.month <= 9) { quarterString = '7 - 9' }
      const tilikaudenKuukausi: NaytettavanTilikaudenKvartaali = {
        quarter: quarterString.replace(/ /g, '') + '/' + kvartaali.year,
        label: quarterString,
        start: i === 0 ? asiakkaanTilikausi.alkaa : this._dateService.kvartaalinEnsimmainenPaikallinen(kvartaali), // Use tilikausi start date for first quarter
        end: i === kvartaalejaValissa ? asiakkaanTilikausi.loppuu : this._dateService.kvartaalinViimeinenPaikallinen(kvartaali) // Use tilikausi end date for last quarter
      }
      naytettavatKvartaalit.push(tilikaudenKuukausi)
    }
    return naytettavatKvartaalit
  }

  private _formatSelectableYearBtnText(tilikausi: Tilikausi): string {
    if (tilikausi.alkaa.day === 1 && tilikausi.alkaa.month === 1 &&
      tilikausi.loppuu.day === 31 && tilikausi.loppuu.month === 12) {
      return '' + tilikausi.loppuu.year
    }
    return this._dateService.muotoile(this._dateService.localDateToDate(tilikausi.alkaa), 'dd.MM.yy') + ' - ' + this._dateService.muotoile(this._dateService.localDateToDate(tilikausi.loppuu), 'dd.MM.yy')
  }

  emitKirjausData(klikattu: KlikattuKirjaus) {
    if (klikattu) {
      // console.log('RAPORTIT CLICKED', klikattu)
      this.kirjaustaKlikattiin.emit(klikattu)
    }
  }

  filterByProject(projektiAvain: string) {
    this._hakuvaihtoehdotSubject.value.projekti = projektiAvain
    this._hakuvaihtoehdotSubject.next(this._hakuvaihtoehdotSubject.value)
  }

  private _setActiveKvartaaliOrKuukausi(start: LocalDate, end: LocalDate) {
    const matchingQt: NaytettavanTilikaudenKvartaali = (this.naytettavaTilikausi?.quarters || []).find(qt => this._dateService.compareLocalDates(qt.start, '==', start) && this._dateService.compareLocalDates(qt.end, '==', end))
    if (matchingQt) {
      this.dateBtnsSelectedQuarter = matchingQt.quarter
      this.dateBtnsSelectedMonth = null
      return
    }
    const matchingMm: NaytettavanTilikaudenKuukausi = (this.naytettavaTilikausi?.months || []).find(mm => this._dateService.compareLocalDates(mm.start, '==', start) && this._dateService.compareLocalDates(mm.end, '==', end))
    if (matchingMm) {
      this.dateBtnsSelectedMonth = matchingMm.month
      this.dateBtnsSelectedQuarter = null
      return
    }
    this.dateBtnsSelectedMonth = null
    this.dateBtnsSelectedQuarter = null
  }

  openPdfSelect() {
    this.isPdfSelectOpen = true
    this.pdfSelect.open()
  }

  openCsvSelect() {
    this.isCsvSelectOpen = true
    this.csvSelect.open()
  }


  private _getPdfList(valilehti: KirjanpitoRaporttiValilehti): ExportOption[] {
    const TUETUT_KIELET: TuettuKieli[] = ['fi', 'en']
    const output: ExportOption[] = []

    if (valilehti === 'tase-ja-tulos') {
      for (const kieli of TUETUT_KIELET) {
        output.push({ tag: 'Tulos ' + '(' + kieli.toUpperCase() + ')', type: RaporttiType.TULOS, kieli: kieli })
        output.push({ tag: 'Tase ' + '(' + kieli.toUpperCase() + ')', type: RaporttiType.TASE, kieli: kieli })
        output.push({ tag: 'Tase ja Tulos ' + '(' + kieli.toUpperCase() + ')', type: RaporttiType.TASE_JA_TULOS, kieli: kieli })
      }
    }

    if (valilehti === 'viralliset') {
      for (const kieli of TUETUT_KIELET) {
        output.push({ tag: 'Tulos ' + '(' + kieli.toUpperCase() + ')', type: RaporttiType.TULOS_VIRALLINEN, kieli: kieli })
        output.push({ tag: 'Tase ' + '(' + kieli.toUpperCase() + ')', type: RaporttiType.TASE_VIRALLINEN, kieli: kieli })
        output.push({ tag: 'Tase ja Tulos ' + '(' + kieli.toUpperCase() + ')', type: RaporttiType.TASE_JA_TULOS_VIRALLINEN, kieli: kieli })
      }
    }

    if (valilehti === 'paakirja') {
      for (const kieli of TUETUT_KIELET) {
        output.push({ tag: 'Pääkirja ' + '(' + kieli.toUpperCase() + ')', type: RaporttiType.PAAKIRJA, kieli: kieli })
        output.push({ tag: 'Päiväkirja ' + '(' + kieli.toUpperCase() + ')', type: RaporttiType.PAIVAKIRJA, kieli: kieli })
      }
    }

    if (valilehti === 'bruttolaskelma') {
      for (const kieli of TUETUT_KIELET) {
        output.push({ tag: 'Bruttolaskelma ' + '(' + kieli.toUpperCase() + ')', type: RaporttiType.TULOS_BRUTTO, kieli: kieli })
      }
    }

    if (valilehti === 'alv-laskelma') {
      for (const kieli of TUETUT_KIELET) {
        output.push({ tag: 'Alv-laskelma ' + '(' + kieli.toUpperCase() + ')', type: RaporttiType.ALV_LASKELMA, kieli: kieli })
      }
    }

    return output
  }

  private _getCsvList(valilehti: KirjanpitoRaporttiValilehti): ExportOption[] {
    const TUETUT_KIELET: TuettuKieli[] = ['fi', 'en']
    const output: ExportOption[] = []

    if (valilehti === 'tase-ja-tulos') {
      for (const kieli of TUETUT_KIELET) {
        output.push({ tag: 'Tulos ' + '(' + kieli.toUpperCase() + ')', type: RaporttiType.TULOS, kieli: kieli })
        output.push({ tag: 'Tase ' + '(' + kieli.toUpperCase() + ')', type: RaporttiType.TASE, kieli: kieli })
      }
    }

    // if (valilehti === 'viralliset') {
    //   for (const kieli of TUETUT_KIELET) {
    //     output.push({ tag: 'Tulos ' + '(' + kieli.toUpperCase() + ')', type: RaporttiType.TULOS_VIRALLINEN, kieli: kieli })
    //     output.push({ tag: 'Tase ' + '(' + kieli.toUpperCase() + ')', type: RaporttiType.TASE_VIRALLINEN, kieli: kieli })
    //     output.push({ tag: 'Tase ja Tulos ' + '(' + kieli.toUpperCase() + ')', type: RaporttiType.TASE_JA_TULOS_VIRALLINEN, kieli: kieli })
    //   }
    // }

    if (valilehti === 'paakirja') {
      for (const kieli of TUETUT_KIELET) {
        output.push({ tag: 'Pääkirja ' + '(' + kieli.toUpperCase() + ')', type: RaporttiType.PAAKIRJA, kieli: kieli })
        output.push({ tag: 'Päiväkirja ' + '(' + kieli.toUpperCase() + ')', type: RaporttiType.PAIVAKIRJA, kieli: kieli })
      }
    }

    // if (valilehti === 'bruttolaskelma') {
    //   for (const kieli of TUETUT_KIELET) {
    //     output.push({ tag: 'Bruttolaskelma ' + '(' + kieli.toUpperCase() + ')', type: RaporttiType.TULOS_BRUTTO, kieli: kieli })
    //   }
    // }

    return output
  }

}
