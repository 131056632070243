import { Component, OnInit, Inject } from '@angular/core'
import { Validators, FormGroup, FormControl } from '@angular/forms'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'

import { NaytettavaKirjaus } from '../kirjanpito.component'

import { DateService } from 'app/_shared-core/service/date.service'
import { FormValidationService } from 'app/_jaettu-angular/service/form-validation.service'
import { Asiakas } from 'app/_jaettu-lemonator/model/asiakas'
import { Kirjanpitotili } from 'app/_jaettu-lemonator/model/kirjanpito'
import { AsiakasJaettuService } from 'app/_jaettu-lemonator/service/asiakas-jaettu.service'
import { map, Observable, startWith } from 'rxjs'
import { CurrencyService } from 'app/_shared-core/service/currency.service'
import { PoistotService } from 'app/_jaettu-lemonator/service/poistot.service'
import { TilikarttaJaettuService } from 'app/_jaettu-lemonator/service/tilikartta-jaettu.service'

export interface KirjausPoistotDialogData {
  naytettavaKirjaus: NaytettavaKirjaus
  automaattisenPoistonTili: Kirjanpitotili
  poistettavaMaara: number
  asiakas: Asiakas
}

export interface KirjausPoistotDialogResult {
  luoPoistokirjaukset: boolean
  suhteutaPoisto: boolean
  kokoPoistoViimeiselleKuukaudelle: boolean
}

interface PoistonTiedot {
  tili: string
  lisays: number
  yhteensa: string
  kuukausia: number
  poistoPerKuukausi: string
}

@Component({
  templateUrl: './kirjaus.poistot.dialog.html'
})
export class KirjausPoistotDialog implements OnInit {

  form: FormGroup<{
    luoPoistokirjaukset: FormControl<boolean>
    suhteutaPoisto: FormControl<boolean>
    kokoPoistoViimeiselleKuukaudelle: FormControl<boolean>
  }>
  poistonTiedotObservable: Observable<PoistonTiedot | null>


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: KirjausPoistotDialogData,
    private _dialogRef: MatDialogRef<KirjausPoistotDialog, KirjausPoistotDialogResult>,
    private _dateService: DateService,
    private _formValidationService: FormValidationService,
    private _asiakasJaettuService: AsiakasJaettuService,
    private _currencyService: CurrencyService,
    private _poistotService: PoistotService,
    private _tilikarttaJaettuService: TilikarttaJaettuService
  ) {
    this.form = new FormGroup({
      'luoPoistokirjaukset': new FormControl(true, [Validators.required]),
      'suhteutaPoisto': new FormControl(false, [Validators.required]),
      'kokoPoistoViimeiselleKuukaudelle': new FormControl(false, [Validators.required]),
    })

    const kirjauksenPaivamaara = this.data.naytettavaKirjaus.uusiPaivamaara || this.data.naytettavaKirjaus.kirjaus.p

    const tilikausi = this._asiakasJaettuService.annaTilikausiPaivalleNumber(data.asiakas, kirjauksenPaivamaara)

    this.poistonTiedotObservable = this.form.valueChanges.pipe(
      startWith(this.form.value),
      map((value) => {
        if (!value.luoPoistokirjaukset) {
          return null
        }

        const automaattisenPoistonTili = this.data.automaattisenPoistonTili
        // Luetaan raaka nimi (ei tarvita täällä kirjanpito.component.ts:n lisäämiä reskontra / poistomerkintöjä)
        const tilinNimi = this._tilikarttaJaettuService.annaKirjanpitotilinNimi(automaattisenPoistonTili, 'fi')

        const poistettavaMaara = this.data.poistettavaMaara
        const poistot = this._poistotService.laskePoistot(automaattisenPoistonTili.poistotiedot.poistoprosentti, this._dateService.localDateToNumber(tilikausi.loppuu), kirjauksenPaivamaara, poistettavaMaara, value.suhteutaPoisto, value.kokoPoistoViimeiselleKuukaudelle)
        const poistoYhteensa = poistot.reduce((sum, d) => sum += d.maara, 0)

        return {
          tili: `${automaattisenPoistonTili.numero} ${tilinNimi} (${automaattisenPoistonTili.poistotiedot.poistoprosentti}%)`,
          lisays: poistettavaMaara,
          yhteensa: this._currencyService.formatoiRahaIlmanValuuttaSymbolia(poistoYhteensa, 'fi'),
          kuukausia: poistot.length,
          poistoPerKuukausi: this._currencyService.formatoiRahaIlmanValuuttaSymbolia(poistot[0].maara, 'fi')
        }
      }))
  }

  ngOnInit() { }

  save() {
    this.form.updateValueAndValidity()
    if (this.form.invalid || !this.form.valid) {
      this._formValidationService.merkitseKokoLomakeKosketuksi(this.form)
      return
    }

    const { luoPoistokirjaukset, suhteutaPoisto, kokoPoistoViimeiselleKuukaudelle } = this.form.value
    this._dialogRef.close({
      luoPoistokirjaukset: luoPoistokirjaukset,
      suhteutaPoisto: suhteutaPoisto,
      kokoPoistoViimeiselleKuukaudelle: kokoPoistoViimeiselleKuukaudelle
    })
  }

}
