import { Component, OnInit, OnDestroy, ErrorHandler } from '@angular/core'
import { MatDatepicker } from '@angular/material/datepicker'
import { Validators, FormGroup, FormControl } from '@angular/forms'

import { Subject, takeUntil } from 'rxjs'
import { DateService } from '../../_shared-core/service/date.service'

import { GetSalesReportDataRequest, GetSalesReportDataResponse, SalesReportRow } from 'app/_jaettu-lemonator/model/tilastot'
import { SelvitettavienTilanneraporttiPyynto, SelvitettavienTilanneraporttiVastaus, YllapitoTilastonTyyppi, YllapitoTilastotPyynto, YllapitoTilastotVastaus } from 'app/_jaettu-lemonator/model/raportit'
import { LadataanService } from 'app/_jaettu-angular/service/ladataan.service'
import { FirebaseLemonator } from 'app/_angular/service/firebase-lemonator.service'

import * as xlsx from 'xlsx'
import * as jszip from 'jszip'
import { FileSaverService } from 'app/_jaettu-angular/service/file-saver'

@Component({
  templateUrl: './reports.component.html',
  styleUrls: []
})
export class ReportsComponent implements OnInit, OnDestroy {

  private ngUnsubscribe: Subject<void> = new Subject<void>()

  month: Date = new Date()
  form: FormGroup<{ month: FormControl<Date> }>

  paattyneetSopimuksetForm: FormGroup<{ alkaa: FormControl<Date>, loppuu: FormControl<Date> }>
  hinnanmuutoksetForm: FormGroup<{ alkaa: FormControl<Date>, loppuu: FormControl<Date> }>
  sopimustyyppienMuutoksetForm: FormGroup<{ alkaa: FormControl<Date>, loppuu: FormControl<Date> }>
  poytalaatikkoJaTaukosopimustenKestotForm: FormGroup<{ date: FormControl<Date> }>
  saamisetOsakkailtaJaOmaisiltaForm: FormGroup<{ date: FormControl<Date> }>

  commonError: string
  commonErrorSelvitettavat: string
  commonErrorPaattyneetSopimukset: string
  commonErrorHinnanmuutokset: string
  commonErrorSopimustyyppienMuutokset: string
  commonErrorPoytalaatikkoJaTaukosopimustenKestot: string
  commonErrorSaamisetOsakkailtaJaOmaisilta: string

  constructor(
    private _errorHandler: ErrorHandler,
    private _dateService: DateService,
    private _firebaseLemonator: FirebaseLemonator,
    private _ladataanService: LadataanService,
    private _fileSaverService: FileSaverService
  ) {
    this.month = this._dateService.lisaaKuukausia(new Date(), -1)
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next()
    this.ngUnsubscribe.complete()
  }

  ngOnInit() {
    this.form = new FormGroup({
      'month': new FormControl<Date>(this.month, [Validators.required]),
    })
    this.form.get('month').valueChanges.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe((value: Date) => {
      this.commonError = null
      this.month = value
    })

    this.paattyneetSopimuksetForm = new FormGroup({
      'alkaa': new FormControl<Date>(null, [Validators.required]),
      'loppuu': new FormControl<Date>(null, [Validators.required]),
    })
    this.paattyneetSopimuksetForm.valueChanges.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(() => {
      this.commonErrorPaattyneetSopimukset = null
    })

    this.hinnanmuutoksetForm = new FormGroup({
      'alkaa': new FormControl<Date>(null, [Validators.required]),
      'loppuu': new FormControl<Date>(null, [Validators.required]),
    })
    this.hinnanmuutoksetForm.valueChanges.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(() => {
      this.commonErrorHinnanmuutokset = null
    })

    this.sopimustyyppienMuutoksetForm = new FormGroup({
      'alkaa': new FormControl<Date>(null, [Validators.required]),
      'loppuu': new FormControl<Date>(null, [Validators.required]),
    })
    this.sopimustyyppienMuutoksetForm.valueChanges.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(() => {
      this.commonErrorSopimustyyppienMuutokset = null
    })

    this.poytalaatikkoJaTaukosopimustenKestotForm = new FormGroup({
      'date': new FormControl<Date>(null, [Validators.required]),
    })
    this.poytalaatikkoJaTaukosopimustenKestotForm.valueChanges.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(() => {
      this.commonErrorPoytalaatikkoJaTaukosopimustenKestot = null
    })

    this.saamisetOsakkailtaJaOmaisiltaForm = new FormGroup({
      'date': new FormControl<Date>(null, [Validators.required]),
    })
    this.saamisetOsakkailtaJaOmaisiltaForm.valueChanges.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(() => {
      this.commonErrorSaamisetOsakkailtaJaOmaisilta = null
    })
  }

  chosenMonthHandler(normalizedMonth: Date, datepicker: MatDatepicker<Date>) {
    this.form.get('month').setValue(normalizedMonth)
    datepicker.close()
  }

  async lataaSelvitettavatRaportti() {
    this._ladataanService.aloitaLataaminen()

    const requestData: SelvitettavienTilanneraporttiPyynto = {}

    try {
      const resp = await this._firebaseLemonator.functionsCall<SelvitettavienTilanneraporttiPyynto, SelvitettavienTilanneraporttiVastaus>('raportitLataaSelvitettavatTiimeittainCsvt', requestData)

      if (!resp) {
        this.commonErrorSelvitettavat = 'Raportin lataus epäonnistui. Ole hyvä ja yritä uudelleen.'
        return
      }

      if (!resp.tiedostot || Object.keys(resp.tiedostot).length < 1) {
        this.commonErrorSelvitettavat = 'Raportille ei palautunut tietoja. Ole hyvä ja yritä uudelleen.'
        return
      }

      const zip = new jszip()
      for (const entry of Object.entries(resp.tiedostot)) {
        zip.file(entry[0], entry[1], { date: new Date() })
      }

      const content = await zip.generateAsync({ type: 'blob' })

      const filename = 'Selvitettävien tilanne ' + this._dateService.muotoilePaikallinenPaiva(this._dateService.currentLocalDate(), 'fi') + '.zip'
      this._fileSaverService.saveAs(content, filename)

    } catch (err) {
      this._errorHandler.handleError(err)
      this.commonErrorSelvitettavat = 'Tapahtui tuntematon virhe. Ole hyvä ja yritä uudelleen.'
    } finally {
      this._ladataanService.lopetaLataaminen()
    }

  }

  async lataaMyyntiraportti() {

    if (!this.form.valid) {
      return
    }

    this._ladataanService.aloitaLataaminen()

    const selectedMonth = this.form.get('month').value as Date
    const selectedLocalMonth = this._dateService.dateToLocalMonth(selectedMonth)
    const requestData: GetSalesReportDataRequest = {
      month: this._dateService.localMonthToNumber(selectedLocalMonth)
    }

    try {
      const resp = await this._firebaseLemonator.functionsCall<GetSalesReportDataRequest, GetSalesReportDataResponse>('getSalesReportData', requestData)

      if (!resp || resp.e) {
        this.commonError = 'Myyntiraportin lataus epäonnistui! Ole hyvä ja yritä uudelleen.'
        return
      }

      if (!resp.data?.length) {
        this.commonError = 'Kuukaudelle ei löydy tietoja.'
        return
      }

      const dataAsArrayOfArrays = this._createExcelDataAsArrayOfArrays(resp.data)
      /* generate worksheet */
      const ws: xlsx.WorkSheet = xlsx.utils.aoa_to_sheet(dataAsArrayOfArrays)

      /* generate workbook and add the worksheet */
      const wb: xlsx.WorkBook = xlsx.utils.book_new()
      xlsx.utils.book_append_sheet(wb, ws, 'Myynnit')

      /* save to file */
      xlsx.writeFile(wb, 'Myyntiraportti_' + selectedLocalMonth.month + '/' + selectedLocalMonth.year + '.xlsx')

    } catch (err) {
      this._errorHandler.handleError(err)
      this.commonError = 'Tapahtui tuntematon virhe. Ole hyvä ja yritä uudelleen.'
    } finally {
      this._ladataanService.lopetaLataaminen()
    }

  }

  async lataaAlkaneetSopimuksetCsv() {
    if (!this.paattyneetSopimuksetForm.valid) {
      return
    }

    const alku = this.paattyneetSopimuksetForm.value.alkaa
    const loppu = this._dateService.kuukaudenViimeinen(this.paattyneetSopimuksetForm.value.loppuu)
    const requestData: YllapitoTilastotPyynto = {
      alku: this._dateService.dateToNumber(alku),
      loppu: this._dateService.dateToNumber(loppu),
      tyyppi: YllapitoTilastonTyyppi.ALOITTAVAT
    }

    this.commonErrorPaattyneetSopimukset = null
    this._ladataanService.aloitaLataaminen()

    try {
      const resp = await this._firebaseLemonator.functionsCall<YllapitoTilastotPyynto, YllapitoTilastotVastaus>('raportitTilastoCsv', requestData)

      if (!resp || resp.e) {
        this.commonErrorPaattyneetSopimukset = 'Raportin lataus epäonnistui. Ole hyvä ja yritä uudelleen.'
        return
      }

      if (!resp.csv) {
        this.commonErrorPaattyneetSopimukset = 'Raportille ei palautunut tietoja. Ole hyvä ja yritä uudelleen.'
        return
      }

      this._fileSaverService.saveStringAs(resp.csv, resp.filename, 'csv')

    } catch (err) {
      this._errorHandler.handleError(err)
      this.commonErrorPaattyneetSopimukset = 'Tapahtui tuntematon virhe. Ole hyvä ja yritä uudelleen.'
    } finally {
      this._ladataanService.lopetaLataaminen()
    }

  }

  async lataaPaattyneetSopimuksetCsv() {
    if (!this.paattyneetSopimuksetForm.valid) {
      return
    }

    // Lopettavat: kerätään valitut kuukaudet - 1kk
    // Eli jos esim. valitaan tammi-helmikuu, kerätään sopimukset, jotka loppuneet joulu-tammikuu
    const alku = this._dateService.lisaaKuukausia(this.paattyneetSopimuksetForm.value.alkaa, -1)
    const loppu = this._dateService.kuukaudenViimeinen(this._dateService.lisaaKuukausia(this.paattyneetSopimuksetForm.value.loppuu, -1))
    const requestData: YllapitoTilastotPyynto = {
      alku: this._dateService.dateToNumber(alku),
      loppu: this._dateService.dateToNumber(loppu),
      tyyppi: YllapitoTilastonTyyppi.LOPETTAVAT
    }

    this.commonErrorPaattyneetSopimukset = null
    this._ladataanService.aloitaLataaminen()

    try {
      const resp = await this._firebaseLemonator.functionsCall<YllapitoTilastotPyynto, YllapitoTilastotVastaus>('raportitTilastoCsv', requestData)

      if (!resp || resp.e) {
        this.commonErrorPaattyneetSopimukset = 'Raportin lataus epäonnistui. Ole hyvä ja yritä uudelleen.'
        return
      }

      if (!resp.csv) {
        this.commonErrorPaattyneetSopimukset = 'Raportille ei palautunut tietoja. Ole hyvä ja yritä uudelleen.'
        return
      }

      this._fileSaverService.saveStringAs(resp.csv, resp.filename, 'csv')

    } catch (err) {
      this._errorHandler.handleError(err)
      this.commonErrorPaattyneetSopimukset = 'Tapahtui tuntematon virhe. Ole hyvä ja yritä uudelleen.'
    } finally {
      this._ladataanService.lopetaLataaminen()
    }

  }

  async lataaHinnanmuutoksetCsv() {
    if (!this.hinnanmuutoksetForm.valid) {
      return
    }

    const alku = this._dateService.dateToNumber(this.hinnanmuutoksetForm.value.alkaa)
    const loppu = this._dateService.dateToNumber(this._dateService.kuukaudenViimeinen(this.hinnanmuutoksetForm.value.loppuu))
    const requestData: YllapitoTilastotPyynto = { alku, loppu, tyyppi: YllapitoTilastonTyyppi.HINNANMUUTOKSET }

    this.commonErrorHinnanmuutokset = null
    this._ladataanService.aloitaLataaminen()

    try {
      const resp = await this._firebaseLemonator.functionsCall<YllapitoTilastotPyynto, YllapitoTilastotVastaus>('raportitTilastoCsv', requestData)

      if (!resp || resp.e) {
        this.commonErrorHinnanmuutokset = 'Raportin lataus epäonnistui. Ole hyvä ja yritä uudelleen.'
        return
      }

      if (!resp.csv) {
        this.commonErrorHinnanmuutokset = 'Raportille ei palautunut tietoja. Ole hyvä ja yritä uudelleen.'
        return
      }

      this._fileSaverService.saveStringAs(resp.csv, resp.filename, 'csv')

    } catch (err) {
      this._errorHandler.handleError(err)
      this.commonErrorHinnanmuutokset = 'Tapahtui tuntematon virhe. Ole hyvä ja yritä uudelleen.'
    } finally {
      this._ladataanService.lopetaLataaminen()
    }

  }

  async lataaSopimustyyppienMuutoksetCsv() {
    if (!this.sopimustyyppienMuutoksetForm.valid) {
      return
    }

    const alku = this._dateService.dateToNumber(this.sopimustyyppienMuutoksetForm.value.alkaa)
    const loppu = this._dateService.dateToNumber(this._dateService.kuukaudenViimeinen(this.sopimustyyppienMuutoksetForm.value.loppuu))
    const requestData: YllapitoTilastotPyynto = { alku, loppu, tyyppi: YllapitoTilastonTyyppi.SOPIMUSTYYPPIEN_MUUTOKSET }

    this.commonErrorSopimustyyppienMuutokset = null
    this._ladataanService.aloitaLataaminen()

    try {
      const resp = await this._firebaseLemonator.functionsCall<YllapitoTilastotPyynto, YllapitoTilastotVastaus>('raportitTilastoCsv', requestData)

      if (!resp || resp.e) {
        this.commonErrorSopimustyyppienMuutokset = 'Raportin lataus epäonnistui. Ole hyvä ja yritä uudelleen.'
        return
      }

      if (!resp.csv) {
        this.commonErrorSopimustyyppienMuutokset = 'Raportille ei palautunut tietoja. Ole hyvä ja yritä uudelleen.'
        return
      }

      this._fileSaverService.saveStringAs(resp.csv, resp.filename, 'csv')

    } catch (err) {
      this._errorHandler.handleError(err)
      this.commonErrorSopimustyyppienMuutokset = 'Tapahtui tuntematon virhe. Ole hyvä ja yritä uudelleen.'
    } finally {
      this._ladataanService.lopetaLataaminen()
    }

  }

  async lataaPoytalaatikkoJaTaukosopimustenKestotCsv() {
    if (!this.poytalaatikkoJaTaukosopimustenKestotForm.valid) {
      return
    }

    const date = this._dateService.dateToNumber(this.poytalaatikkoJaTaukosopimustenKestotForm.value.date)
    const requestData: YllapitoTilastotPyynto = { alku: date, loppu: null, tyyppi: YllapitoTilastonTyyppi.POYTALAATIKKO_JA_TAUKOSOPIMUSTEN_KESTOT }

    this.commonErrorPoytalaatikkoJaTaukosopimustenKestot = null
    this._ladataanService.aloitaLataaminen()

    try {
      const resp = await this._firebaseLemonator.functionsCall<YllapitoTilastotPyynto, YllapitoTilastotVastaus>('raportitTilastoCsv', requestData)

      if (!resp || resp.e) {
        this.commonErrorPoytalaatikkoJaTaukosopimustenKestot = 'Raportin lataus epäonnistui. Ole hyvä ja yritä uudelleen.'
        return
      }

      if (!resp.csv) {
        this.commonErrorPoytalaatikkoJaTaukosopimustenKestot = 'Raportille ei palautunut tietoja. Ole hyvä ja yritä uudelleen.'
        return
      }

      this._fileSaverService.saveStringAs(resp.csv, resp.filename, 'csv')

    } catch (err) {
      this._errorHandler.handleError(err)
      this.commonErrorPoytalaatikkoJaTaukosopimustenKestot = 'Tapahtui tuntematon virhe. Ole hyvä ja yritä uudelleen.'
    } finally {
      this._ladataanService.lopetaLataaminen()
    }

  }

  async lataaSaamisetOsakkailtaJaOmaisiltaCsv() {
    if (!this.saamisetOsakkailtaJaOmaisiltaForm.valid) {
      return
    }

    const date = this._dateService.dateToNumber(this.saamisetOsakkailtaJaOmaisiltaForm.value.date)
    const requestData: YllapitoTilastotPyynto = { alku: date, loppu: null, tyyppi: YllapitoTilastonTyyppi.SAAMISET_OSAKKAILTA_JA_OMAISILTA }

    this.commonErrorSaamisetOsakkailtaJaOmaisilta = null
    this._ladataanService.aloitaLataaminen()

    try {
      const resp = await this._firebaseLemonator.functionsCall<YllapitoTilastotPyynto, YllapitoTilastotVastaus>('raportitTilastoCsv', requestData)

      if (!resp || resp.e) {
        this.commonErrorSaamisetOsakkailtaJaOmaisilta = 'Raportin lataus epäonnistui. Ole hyvä ja yritä uudelleen.'
        return
      }

      if (!resp.csv) {
        this.commonErrorSaamisetOsakkailtaJaOmaisilta = 'Raportille ei palautunut tietoja. Ole hyvä ja yritä uudelleen.'
        return
      }

      this._fileSaverService.saveStringAs(resp.csv, resp.filename, 'csv')

    } catch (err) {
      this._errorHandler.handleError(err)
      this.commonErrorSaamisetOsakkailtaJaOmaisilta = 'Tapahtui tuntematon virhe. Ole hyvä ja yritä uudelleen.'
    } finally {
      this._ladataanService.lopetaLataaminen()
    }
  }

  private _createPerSalesPersonExcelFormulas(data: SalesReportRow[]): string[][] {
    const salesPeople = Array.from(new Set(data.map(row => row.salesPerson)))
    const salesPeopleLength = salesPeople.length

    const output: string[][] = []

    const startRowIndex = salesPeopleLength + 3 // All the sales people rows + empty row + header row + jump to correct row
    const endRowIndex = startRowIndex + data.length

    for (const salesPerson of salesPeople) {
      const excelFormula = `=SUMIF(C${startRowIndex}:C${endRowIndex};"${salesPerson}"; B${startRowIndex}:B${endRowIndex})`

      output.push([salesPerson, excelFormula])
    }

    return output
  }

  private _createExcelDataAsArrayOfArrays(data: SalesReportRow[]): any[][] {
    const dataAsArrayOfArrays: any[][] = []

    const perPersonNumbersAoA = this._createPerSalesPersonExcelFormulas(data)

    for (const perPersonNumbers of perPersonNumbersAoA) {
      dataAsArrayOfArrays.push(perPersonNumbers)
    }

    /** Empty line in XLSX.
     *
     * Note! If this is removed or any new lines are added, also update the indexes in _createPerSalesPersonExcelFormulas
    **/
    dataAsArrayOfArrays.push([])

    xlsx.utils.aoa_to_sheet(perPersonNumbersAoA)

    dataAsArrayOfArrays.push(['Asiakkaan nimi', 'Hinta', 'Myyjän nimi'])

    for (const row of data || []) {
      dataAsArrayOfArrays.push([row.asiakkaanNimi, row.hinta, row.salesPerson])
    }

    return dataAsArrayOfArrays

  }


}
