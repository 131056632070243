<mat-dialog-content>

  <lemon-dialog-header>
    Kuukausittaiset poistokirjaukset
  </lemon-dialog-header>

  <div>

    <!-- Poistolomake -->
    <form [formGroup]="form" novalidate style="margin: 0; display: flex; flex-direction: column; align-items: center;">
      <mat-slide-toggle aria-label="Luo poistokirjaukset" style="transform: scale(1.2); font-size: 12px; margin: 0 60px 50px; text-align: left;" formControlName="luoPoistokirjaukset">Luo poistokirjaukset
      </mat-slide-toggle>

      <div *ngIf="form.get('luoPoistokirjaukset').value">
        <mat-radio-group formControlName="suhteutaPoisto" style="display: flex; flex-direction: column;">
          <mat-radio-button style="margin: 5px;" [value]="false">Käytä vuosittaista maksimipoistoa</mat-radio-button>
          <mat-radio-button style="margin: 5px;" [value]="true">Suhteuta poisto jäljellä oleviin kuukausiin</mat-radio-button>
        </mat-radio-group>

        <mat-checkbox formControlName="kokoPoistoViimeiselleKuukaudelle" style="margin-left: 6px; margin-top: 5px;">
          Koko poisto tilikauden viimeiselle kuukaudelle
        </mat-checkbox>
      </div>
    </form>

    <!-- Poiston esikatselu -->
    <table *ngIf="poistonTiedotObservable | async; let poistonTiedot" class="poiston-esikatselu" style="margin-left: 25px; margin-top: 40px;">
      <tr>
        <td style="padding-right: 30px;">Tili</td>
        <td>{{ poistonTiedot.tili }}</td>
      </tr>
      <tr>
        <td style="padding-right: 30px;">Lisäys</td>
        <td>{{ poistonTiedot.lisays }}</td>
      </tr>
      <tr>
        <td style="padding-right: 30px;">Poisto yhteensä</td>
        <td>{{ poistonTiedot.yhteensa }}</td>
      </tr>
      <tr>
        <td style="padding-right: 30px;">Kuukausia yhteensä</td>
        <td>{{ poistonTiedot.kuukausia }}</td>
      </tr>
      <tr>
        <td style="padding-right: 30px;">Poistot per kuukausi</td>
        <td>{{ poistonTiedot.poistoPerKuukausi }}</td>
      </tr>
    </table>
  </div>

</mat-dialog-content>

<mat-dialog-actions style=" padding-left: 0; padding-right: 0; justify-content: space-around; justify-content: space-evenly;">
  <button class="secondarybutton" mat-button mat-dialog-close>Peruuta</button>
  <button class="primarybutton" mat-button cdkFocusRegionstart (click)="save()">Tallenna</button>
</mat-dialog-actions>