import { Component, ErrorHandler } from '@angular/core'

import { CdkDragDrop } from '@angular/cdk/drag-drop'
import { MatDialog } from '@angular/material/dialog'

import { Observable, combineLatest } from 'rxjs'
import { map, take, tap } from 'rxjs/operators'

import { TilikarttaService } from 'app/_angular/service/tilikartta.service'
import { Kirjanpitotili } from 'app/_jaettu-lemonator/model/kirjanpito'
import { KirjanpitotiliMuokkaaDialog, KirjanpitotiliMuokkaaDialogData } from './kirjanpitotili-muokkaa.dialog'
import { HierarkiaKirjanpitotili } from 'app/_jaettu-lemonator/service/tilikartta-jaettu.service'
import { KirjautunutKayttajaService } from 'app/_angular/service/kirjautunut-kayttaja.service'
import { DebugService } from 'app/_angular/service/debug.service'

/** Flat node with expandable and level information */
interface FlatKirjanpitotili {
  expandable: boolean
  name: string
  level: number
  kirjanpitotili: Kirjanpitotili
}

@Component({
  templateUrl: './paatilikartta.component.html',
  styleUrls: ['./paatilikartta.component.css']
})
export class PaatilikarttaComponent {

  loading = true
  commonError = ''
  flatatyt: Observable<FlatKirjanpitotili[]>

  // Debug thingies
  kirjanpitajaOnDevaajaObservable: Observable<boolean>
  tilikarttaUriObservable: Observable<string>
  tilikarttaEncodedUriObservable: Observable<string>

  constructor(
    private errorHandler: ErrorHandler,
    private dialog: MatDialog,
    private tilikarttaService: TilikarttaService,
    private _kirjautunutKayttajaService: KirjautunutKayttajaService,
    private _debugService: DebugService
  ) {

    // Debug thingies
    this.kirjanpitajaOnDevaajaObservable = this._kirjautunutKayttajaService.kirjanpitajaOnDevaajaObservable
    this.tilikarttaUriObservable = this.kirjanpitajaOnDevaajaObservable.pipe(
      map(devaaja => {
        return 'admin/paatilikartta'
      })
    )
    this.tilikarttaEncodedUriObservable = this.tilikarttaUriObservable.pipe(
      map(uri => {
        return this._debugService.createFirestoreLink(uri)
      })
    )

    this.flatatyt = this.tilikarttaService.paatilikartanTilihierarkiaObservable.pipe(
      map(hierarkia => {
        const kaikki: FlatKirjanpitotili[] = []
        for (const juuri of hierarkia) {
          this.flattaa(juuri, 1, kaikki)
        }
        return kaikki
      }),
      tap(() => {
        this.loading = false
      })
    )

  }

  private flattaa(hierarkia: HierarkiaKirjanpitotili, level: number, kaikki: FlatKirjanpitotili[]) {
    kaikki.push(this.makeFlat(hierarkia, level))
    if (hierarkia.lapset) {
      for (const lapsi of hierarkia.lapset) {
        this.flattaa(lapsi, level + 1, kaikki)
      }
    }
  }

  private makeFlat(node: HierarkiaKirjanpitotili, level: number): FlatKirjanpitotili {
    return {
      expandable: !!node.lapset && node.lapset.length > 0,
      name: node.kirjanpitotili.numero + ' ' + node.kirjanpitotili.nimi,
      level: level,
      kirjanpitotili: node.kirjanpitotili
    }
  }

  muokkaa(tili: Kirjanpitotili) {
    const data: KirjanpitotiliMuokkaaDialogData = {
      tili: tili,
      lisaa: false
    }
    this.dialog.open(KirjanpitotiliMuokkaaDialog, { data: data, autoFocus: false })
  }

  lisaa() {
    const data: KirjanpitotiliMuokkaaDialogData = {
      tili: {
        vanhempi: null,
        aktiivinen: true,
        nimi: null,
        numero: null,
        oletusAlvKasittely: null,
        alvTyyppi: null,
        localisedName: {} as any
      },
      lisaa: true
    }
    this.dialog.open(KirjanpitotiliMuokkaaDialog, { data: data, autoFocus: false })
  }

  drop(event: CdkDragDrop<FlatKirjanpitotili>) {

    if (event.currentIndex === event.previousIndex) {
      return
    }
    combineLatest([this.flatatyt, this.tilikarttaService.paatilikartanTilihierarkiaObservable, this._kirjautunutKayttajaService.kirjanpitajanTiedotObservable]).pipe(
      take(1)
    ).subscribe(([flatatyt, hierarkia, kirjanpitaja]) => {
      const raahattu = flatatyt[event.previousIndex]
      const uusiIsanta = flatatyt[event.currentIndex]

      const raahattuHierarkiassa = this.tilikarttaService.etsiTiliHierarkiasta(raahattu.kirjanpitotili.numero, hierarkia)
      const uusiIsantaHierarkiassa = this.tilikarttaService.etsiTiliHierarkiasta(uusiIsanta.kirjanpitotili.numero, [raahattuHierarkiassa])

      if (uusiIsantaHierarkiassa) {
        return
      }

      const uusiVanhempi = event.currentIndex - event.previousIndex > 0 ? uusiIsanta.kirjanpitotili.numero : uusiIsanta.kirjanpitotili.vanhempi

      raahattu.kirjanpitotili.vanhempi = uusiVanhempi || null
      this.tilikarttaService.tallennaPaatilikartanTili(raahattu.kirjanpitotili, kirjanpitaja.uid).catch(error => {
        this.commonError = 'Tallentamisen aikana tapahtui virhe. Ole hyvä ja ilmoita tästä ylläpitäjälle.'
        this.errorHandler.handleError(error)
      })
    })
  }

}
